.my-closet-banner-con {
  /* width: 100%; */
  margin: 0 0.75rem;
  padding: 0;
  position: relative;
  max-width: 100%;
  padding: 0 30px;
}

.my-closet-banner-wrapper-con {
  padding: 2rem;
  padding-top: 3rem;
  background: #f7e4e1;
  padding-bottom: 0;
  min-height: 350px;
  position: relative;
  border-radius: 5px;
}

.my-closet-banner-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  flex-wrap: wrap;
  /* min-height: 360px; */
  /* position: relative; */
}

.my-closet-banner-wrapper .banner-details {
  align-items: flex-start;
  margin: 0 1rem;
}

.my-closet-banner-wrapper .banner-img-con {
  width: 175px;
  height: 175px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
}

.my-closet-banner-wrapper .banner-img-con img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.banner-saved-carbon-emission {
  display: flex;
  align-items: center;
  text-align: center;
  /* line-height: 1.25; */
}

.banner-saved-carbon-emission div {
  margin: 0 0.5rem;
}

.banner-saved-carbon-emission img {
  width: 175px;
  height: 175px;
  border-radius: 50%;
  object-fit: cover;
}

.my-closet-banner-wrapper .ver-divider-1,
.my-closet-banner-wrapper .ver-divider-2 {
  height: 200px;
  width: 2px;
  border-radius: 10px;
  border: 2px solid var(--background-color);
}

.banner-details-2 {
  margin: 0 1rem;
}

.banner-details-2 h4 {
  margin: 0.75rem 0;
}

.banner-saved-carbon-emission .text-content {
  max-width: 200px;
  text-align: center;
  line-height: 1.5;
}

.gamification-col {
  background: transparent;
  /* border: 1px solid var(--primary-color); */
  /* display: flex; */
  /* flex: 1/3; */
  height: 100%;
  position: relative;
  margin: 1rem 0;
}

.gamification-col .gami-title {
  padding: 1rem 1.5rem;
  background: var(--primary-color);
  color: var(--white-color);
  border-radius: 30px;
  margin: 0.5rem 0;
  position: absolute;
  top: -2rem;
  margin-bottom: 1rem;
  width: 100%;
}

.gamification-col .info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.gamification-col .info-row .icon {
  width: 40px;
  margin: auto;
  display: flex;
  justify-content: center;
}

.gamification-col .info-row .content {
  width: calc(100% - 40px);
  padding: 0 0.5rem;
  /* min-width: 250px; */
}

.gamification-col .info-row .content h3 {
  margin: 0 !important;
}

.my-closet-banner-wrapper .banner-img-con.banner-img-con2 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  text-align: center;
}

.my-closet-banner-wrapper .banner-img-con.banner-img-con2 img {
  width: 150px;
  height: 150px;
  margin-bottom: 0.5rem;
}

.eco-slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-bottom: 1rem;
  /* position: relative; */
  /* top: -2.5rem; */
}

.eco-slider {
  position: absolute;
  top: -0.5rem;
  /* margin: 0 2rem; */
  width: 90%;
  height: 50px;
  background-color: #6dbf98;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.1);
  transition: all 0.5s ease-in-out;
  z-index: 1;
}

.eco-slider-filled {
  height: 100%;
  line-height: 50px;
  background-color: #01877c;
  transition: width 0.2s ease;
  transform: translateX("-25px");
  position: relative;
}

.eco-slider-empty {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 10px;
}

.eco-score {
  position: absolute;
  top: -30px;
  right: 0;
  transform: translateX(50%);
  font-size: 18px;
  font-weight: bold;
  color: var(--primary-color);
  display: flex;
  align-items: center;
}

.eco-label {
  margin-left: 10px;
  line-height: 57px;
  cursor: pointer;
  position: relative;
}

.eco-label .tooltip-text-ecolabel {
  display: none;
  opacity: 0;
}

.eco-label:hover .tooltip-text-ecolabel {
  top: -5px;
  left: 0.5rem;
  opacity: 1;
  display: unset;
  /* visibility: visible; */
  transition: all 0.5s ease;
  background: var(--white-color);
  /* height: 80%; */
  position: relative;
  padding: 0.4rem;
  border-radius: 30px;
  color: var(--background-color);
  z-index: 999;
}

.update-info-msg {
  float: right;
  display: flex;
  align-items: center;
  position: absolute;
  right: 4rem;
  bottom: 2rem;
}

.tooltipCustom {
  border: 1px solid var(--primary-color) !important;
  background-color: var(--peel-orange-color) !important;
  color: var(--white-color) !important;
  z-index: 999999;
}

/* HTML: <div class="loader"></div> */
.loader-banner {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat
    radial-gradient(circle closest-side, var(--primary-color) 90%, #ffffff00);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 2s infinite linear;
}

.copy-link-btn {
  background: transparent;
  border-radius: 30px;
  margin-top: .5rem;
  padding: 6px 12px;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  opacity: .8;
  transition: all .3s ease-in-out;
  cursor: pointer;
  margin-bottom: 1rem;
}

.copy-link-btn:hover {
  opacity: 1;
  padding: 6px 24px;
}

@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

@media screen and (max-width: 1160px) {
  .my-closet-banner-con {
    margin: 0;
  }
  .ver-divider-2 {
    display: none;
  }
  .update-info-msg {
    right: 1rem;
    bottom: 1rem;
  }
}

@media screen and(max-width: 1085px) {
  .my-closet-banner-wrapper-con {
    min-height: 360px;
  }
}

@media screen and (max-width: 734px) {
  .ver-divider-1 {
    display: none;
  }
  .my-closet-banner-wrapper {
    justify-content: center;
  }
  .my-closet-banner-wrapper div {
    margin: 0.5 0;
    flex-grow: 1;
  }
  .my-closet-banner-wrapper .banner-details {
    margin-left: 2rem;
  }
  .my-closet-banner-wrapper .banner-details-2 {
    text-align: center;
  }
  .banner-saved-carbon-emission {
    display: none;
  }
  .eco-slider {
    width: 75%;
  }
  .my-closet-banner-wrapper .banner-img-con.banner-img-con2 {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 485px) {
  .my-closet-banner-wrapper .banner-details {
    margin-left: 0;
    text-align: center;
    margin: 0;
  }
}
