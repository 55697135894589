/* .chat-engine-wrap {
    padding: 0 6rem !important;
    
} */

.chat-engine-wrap > div > div {
  background-color: var(--white-color) !important;
}
.ce-chat-list {
  background-color: #fff7f7 !important;
}
.ce-chat-list-column {
  max-width: 100% !important;
  width: 100% !important;
  flex: 0 0 25% !important;
}

.ce-chat-settings-column {
  display: none !important;
}
.ce-settings-column {
  display: none !important;
}

.ce-chat-feed-column {
  width: 75% !important;
  max-width: 75% !important;
  flex: 0 0 75% !important;
}

.ce-chat-card-subtitle-html p {
  text-align: left;
}

.ce-their-message-body,
.ce-their-message-body p {
  font-family: "Lato" !important;
  color: var(--black-color) !important;
  text-align: left !important;
}

.ce-my-message-body,
.ce-my-message-body p {
  font-family: "Lato" !important;
  background: var(--primary-color) !important;
  color: var(--white-color) !important;
  text-align: right !important;
}

.ce-their-message-sender-username {
  display: none !important;
}

.ce-message-date-text {
  font-family: "Lato" !important;
  margin: 1rem auto !important;
  font-size: 12px !important;
}

.ce-my-message-timestamp,
.ce-their-message-timestamp {
  font-family: "Lato" !important;
}

.ce-avatar {
  font-family: "Lato" !important;
}

.ce-message-form-input {
  top: 10px;
}

#ce-send-message-button {
  top: -6px !important;
}

.ce-mobile-chat-list-button {
  top: 28px !important;
  color: var(--primary-color) !important;
  border: none !important;
  font-size: 20px !important;
}

.ce-their-message div .ce-avatar {
  display: none !important;
}

.ce-their-message .ce-their-message-body {
  margin-left: 0 !important;
}

.ce-their-reads-row {
  margin-left: 0 !important;
}

.ce-mobile-chat-settings-button {
  display: none !important;
}

.ce-chat-form {
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
}

.ce-spinner {
  display: none;
}

@media screen and (max-width: 1000px) {
  .chat-engine-wrap {
    padding: 0 !important;
  }
  .ce-chat-list-column {
    max-width: 100% !important;
    width: 100% !important;
    flex: 0 0 35% !important;
  }
  .ce-chat-feed-column {
    width: 65% !important;
    max-width: 65% !important;
    flex: 0 0 65% !important;
  }
}

@media screen and (max-width: 576px) {
  .ce-chat-list-column {
    display: none !important;
  }
  .ce-chat-feed-column {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .ce-default-button .ce-mobile-chat-list-button {
    top: 28px !important;
    color: var(--primary-color) !important;
    border: none !important;
    font-size: 20px !important;
  }
}
