.privacy_policy_container{
    width: 90%;
    margin: auto;
    margin-top: 40px;
}

.privacy_policy_container div, .privacy_policy_container div p span{
    font-family:"LATO";
    font-size:18px;
    color:#333;
    line-height:30px;
}

.privacy_policy_container > div{
    margin-top:35px;
}

.privacy_policy_container .other_container > div{
    margin-top:25px
}

.privacy_policy_container .other_container > div .policy_title{
    font-weight:bold;
}

@media screen and (max-width: 750px) {
    .privacy_policy_container{
        margin-top: 70px;
    }
}