.testimonials-con {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* align-items: center; */
    margin: 1rem 2rem;
}
.testimonial-card {
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* flex: 1/4; */
    background: var(--primary-color);
    color: var(--white-color);
    margin: 1rem;
    border-radius: 5px;
    margin-top: 2rem;
}
.testimonial-card .img-con {
    width: 350px;
    /* height: 350px; */
    border-radius: 5px 5px 0px 0px ;
    padding: 1rem 5rem ;
}
.testimonial-card .img-con img {
    width: 100%;
    max-height: 350px;
    object-fit: cover;
    border-radius: 5px 5px 0px 0px ;
}
.testimonial-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    padding-top: 0rem;
    min-height: 350px;
}
.testimonial-logo{
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 1rem;
    border-radius: 50%;
    background: var(--white-color);
    margin-top: -2.5rem;
}
.testimonial-logo i {
    font-size: 22px;
    width: 100%;
    height: 100%;
    margin: auto;
    color: var(--primary-color);
}
.testimonial-content .heading {
    font-size: 24px;
    color: var(--white-color);
    text-transform: uppercase;
    margin-bottom: 0rem;
    text-align: center;
}
.testimonial-content h5 {
    margin: .5rem;
}
.testimonial-content p {
    /* line-height: 1.4; */
    color: var(--white-color);
    text-align: justify;
    padding: 0rem 1rem;
    text-align: center;
}

/* .testimonials-con .splide__list .splide__slide {
    width: inherit !important;
} */

/* NEW TESTIMONIAL CONTENT STYLING */

.snip1533 {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    color: #9e9e9e;
    display: inline-block;
    margin: 35px 10px 10px;
    max-width: 310px;
    min-width: 250px;
    position: relative;
    text-align: center;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    border-top: 5px solid var(--peel-orange-color);
  }
  
  .snip1533 *,
  .snip1533 *:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
  }
  
  .snip1533 figcaption {
    padding: 13% 10% 12%;
  }
  
  .snip1533 figcaption:before {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    color: var(--primary-color);
    content: "\f10e";
    font-family: 'FontAwesome';
    font-size: 32px;
    font-style: normal;
    left: 50%;
    line-height: 60px;
    position: absolute;
    top: -30px;
    width: 60px;
  }
  
  .snip1533 h3 {
    color: var(--grey-color);   
    font-weight: 500;
    margin: 10px 0 5px;
  }
  
  .snip1533 h4 {
    margin: 0;
    opacity: 0.5;
  }

  
  .snip1533 figcaption blockquote {
    font-style: italic;
    font-weight: 300;
    margin: 0 0 20px;
    min-height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }