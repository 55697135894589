.pagination {
    display: flex;
    justify-content: center;
    align-items: center;   
    margin: 2rem auto;
    margin-left: 20%;
    /* border: 1px solid var(--primary-color); */
}

.pagination-no-margin-left {
    display: flex;
    justify-content: center;
    align-items: center;   
    margin: 2rem auto;
    /* margin-left: 20%; */
    /* border: 1px solid var(--primary-color); */
}

.page-item {
    border: 1px solid var(--primary-color);
    /* width: 25px;
    height: 25px; */
    margin: auto;
    text-align: center;
    margin: .2rem;
    cursor: pointer;
    border-radius: 2px;
    /* width: 60px; */
    padding: 8px;
}

.page-item:hover {
    background: var(--peel-orange-color);
    color: var(--white-color);
}

.page-link {
    padding: .5rem;
    margin: .5rem;
}

.active {
    background: var(--primary-color) !important;
    color: var(--white-color);
    transition: .2s ease;
    font-weight: 700;
    transform: scale(1.05);
    transition: .5s ease;
}

.page-item-next, .page-item-prev {
    background-color: var(--peel-orange-color)!important;
    font-size: 14px;
    color: var(--white-color)!important;
    width: 80px;
    height: 37px;
    border-radius: 2px 0 0 2px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.page-link-next, .page-link-prev {
    font-weight: 700;
    /* margin: 1rem; */
    /* padding: 0.5rem; */
    width: 80px;
    height: 38px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1200px){
    .page-link {
        padding: .3rem;
        margin: .3rem;
    }
}

@media screen and (max-width: 900px){
    .pagination {
        margin: 2rem auto;
        justify-content: center;
    }
}

@media screen and (max-width: 640px){
    .page-item {
        border: none;
        width: 40px;
    }
    .page-link {
        margin: .2rem;
        padding: .2rem;
        font-size: 12px;
    }
    .page-item-next,.page-item-prev {
        width: min-content;
    }
    .page-link-next, .page-link-prev {
        font-size: 12px;
        padding: 0px 10px;
        margin: auto;
        line-height: 1.2;
        width: min-content;
        display: flex;
        line-height: 1.7;
    } 
    
}

@media screen and (max-width: 480px){
    .page-link {
        margin: .1rem;
        padding: .1rem;
        font-size: 12px;
    }
    .page-link-next, .page-link-prev {
        font-size: 12px;
    }
}