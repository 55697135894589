.future-of-fashion-con {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 2rem auto; */
  /* min-height: 100vh; */
}

.future-of-fashion-con .heading {
  margin: 1rem;
  color: var(--background-color);
}

.future-of-fashion-con .desc {
  margin: 0;
  margin-bottom: 2rem;
  padding: 0 1rem;
  text-align: center;
}

.future-of-fashion-con .fashion-card-con {
  display: flex;
  margin-bottom: 2rem;
  justify-content: center;
  align-items: center;
}

.fashion-card-con .card {
  display: flex;
  /* flex: 1/4; */
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
  max-width: 350px;
}

.repeat-icon {
  text-align: center;
}

.fashion-card-con .card .img-con {
  /* width: 100%; */
  width: 250px;
  height: 180px;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fashion-card-con .card .img-con img {
  max-width: 100%;
  max-height: 200px;
  max-width: 200px;
}

@media screen and (max-width: 1150px) {
  .fashion-card-con {
    flex-direction: column;
  }
  .fashion-card-con .card .img-con img {
    height: 150px;
  }
  .fashion-card-con .card {
    padding: 0.5rem;
  }
  .repeat-icon {
    display: none;
  }
}

@media screen and (max-width: 1080px) {
  .fashion-card-con .card {
    padding: 0rem;
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .fashion-card-con .card .img-con {
    height: auto;
    margin-bottom: 1rem;
  }
  .future-of-fashion-con {
    margin-top: 2rem;
  }
}
