.partners_container{
    margin:40px 0px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.partners_container .partner i{
    font-size: 50px;
}

.partners_container .partner i{
    color:rgb(217,218,218);
    transition: 0.5s ease;
    cursor: pointer;
}

.partners_container .partner i:hover{
    color:rgb(142,141,141);
}

@media screen and (max-width: 750px) {
    .partners_container .partner i{
        font-size: 30px;
    }
}