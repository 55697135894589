.product_tabs_container {
  margin-top: 30px;
}

.tab {
  overflow: hidden;
  display: flex;
  justify-content: space-evenly;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 16px;
  color: #506385;
  width: 100%;
  font-family: inherit !important;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #a7a7a7;
  color: #fff;
  font-family: inherit !important;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 20px 30px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.no_comment {
  font-weight: bold;
  margin-bottom: 5px;
}

.comment_textarea {
  width: 100%;
  margin-bottom: 10px;
}

.tabcontent .button_container {
  text-align: right;
}

.comment_container {
  margin-top: 20px;
}

.comment {
  margin-bottom: 15px;
  /* border-bottom: 1px solid rgba(43, 43, 43,0.5); */
  padding: 5px;
  padding-bottom: 10px;
  display: flex;
  gap: 1rem;
  font-family: "LATO";
  position: relative;
}

.comment .comment_user_image img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.comment .content {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  width: 100%;
}

.comment .content .comment_user_name {
  /* font-weight: bold; */
  /* font-size:18px; */
}

.comment .delete_comment {
  font-size: 20px;
  color: #f26654;
  cursor: pointer;
  position: absolute;
  right: 5%;
}

.cmt-edit-input {
  border: none;
  border-bottom: 1px solid var(--background-color);
  transition: all 0.5s ease;
  width: 97%;
}

.cs .container-cs {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.cs .container-cs .item {
  flex: 0 0 19%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  cursor: pointer;
  transition: all 0.5s ease;
}

.cs .container-cs .item:hover .closet-card-text {
  background: var(--peel-orange-color);
  color: var(--white-color);
  transition: all 0.5s ease;
  min-height: 100px;
}

.cs .container-cs .item:hover img {
  color: #fff !important;
}

.cs .container-cs .item img {
  width: 100%;
  max-width: 220px;
  min-width: 220px;
  height: 100%;

  /* margin-bottom: 10px; */
}

.cs .container-cs .item .closet-card-text {
  /* flex-grow: 1; */
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-end;
  text-align: center;
  background-color: transparent;
  /* min-height: 80px; */
  padding: 10px;
  min-height: 100px;
  border-radius: 0 0 5px 5px;
  color: var(--dark-grey-text);
}

.cs .container-cs .item .closet-card-text div:first-child {
  width: 70%;
  /* padding: 5px; */
  text-align: left;
}

.cs .container-cs .item .closet-card-text hr {
  padding: 1rem 0;
}

.cs .container-cs .item .closet-card-text div:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
}

.other-closet-items-con {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 2rem auto;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .cs .container-cs .item {
    flex-basis: 50%;
  }
}

@media (max-width: 480px) {
  .cs .container-cs .item {
    flex-basis: 100%;
  }
}

@media screen and (max-width: 600px) {
  .tab {
    flex-wrap: wrap;
  }
}
