/* @import url('https://fonts.googleapis.com/css2?family=Nunito Sans:wght@400;700&display=swap'); */
/* @import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;700;900&display=swap");

* {
  transition: ease 0.1s;
  margin: 0;
  outline: none;
  /* scrollbar-color: #333 #999;
  scrollbar-width: thin !important;
  scroll-behavior: smooth; */
}
/* 
*::-webkit-scrollbar {
  width: 5px;
  border-radius: 50px;
}
*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
*::-webkit-scrollbar-thumb {
  background-color: rgb(136, 136, 136);
  border-radius: 10px;
} */

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

*::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--grey-color);
  border-radius: 10px;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: "Lato", sans-serif !important; */
  font-family: "Lato", sans-serif !important;
  line-height: normal;
  /* overflow-x: hidden; */
  letter-spacing: 0.7px;
}

/* Color variables */
:root {
  --primary-color: #f26654;
  --secondary-color: #28607c;
  --background-color: #506385;
  --peel-orange-color: #f47f72;
  --white-color: #fff;
  --black-color: #000;
  --grey-color: #757575;
  --dark-grey-color: #444;
}

/* If JS is disabled */
noscript {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--white-color);
  z-index: 10101;
  padding: 20px;
}

/* For bold text */
.bold-text {
  font-weight: 900 !important; /* Uncomment this if you want to apply bold on default font */
  font-family: "Lato", sans-serif !important;
}

.primary-text {
  color: var(--primary-color) !important;
}

.secondary-text {
  color: var(--secondary-color) !important;
}

.grey-text {
  color: var(--grey-color) !important;
}

.dark-grey-text {
  color: var(--dark-grey-color) !important;
}

.black-text {
  color: var(--black-color) !important;
}

.white-text {
  color: var(--white-color) !important;
}

.background-text {
  color: var(--background-color) !important;
}

.primary-background {
  background: var(--primary-color);
}

.secondary-background {
  background: var(--secondary-color);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

a {
  text-decoration: none;
}
/* 
a::after {
  content: '';
  display: block;
  margin: auto;
  height: 1px;
  margin-top: 5px;
  width: 0px;
  background: var(--white-color);
  transition: all 0.4s linear;
}

a:hover::after {
  width: 100%;
} */

.text-center {
  text-align: center !important;
}

.text-uppercase {
  text-transform: uppercase;
}

/* Typography Style */
h1,
h2,
h3,
.h1,
.h2,
.h3 {
  word-break: normal;
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
  margin: 0;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
}

h1,
.h1 {
  font-size: 32px;
}

h2,
.h2 {
  font-size: 28px;
  font-family: "Lato", sans-serif !important;
  /* font-weight: 300; */
}

h3,
.h3 {
  font-size: 24px;
  font-family: "Lato", sans-serif !important;
  /* font-weight: 300; */
}

h4,
.h4 {
  font-size: 20px;
  font-family: "Lato", sans-serif !important;
  /* font-weight: 300; */
}

h5,
.h5 {
  font-size: 16px;
  font-family: "Lato", sans-serif !important;
  /* font-weight: 300; */
}

h6,
.h6 {
  font-size: 14px;
  font-family: "Lato", sans-serif !important;
  /* font-weight: 300; */
}

.small-text {
  font-size: 12px;
  font-family: "Lato", sans-serif !important;
  /* font-weight: 300; */
}

.large-text {
  font-size: 38px;
  font-family: "Lato", sans-serif !important;
}

.x-large-text {
  font-size: 48px;
  font-family: "Lato", sans-serif !important;
}

/* MARGINS & PADDINGS */
.mx-1 {
  margin: 0rem 1rem !important;
}
.my-1 {
  margin: 1rem 0rem !important;
}
.m-1 {
  margin: 1rem !important;
}
.mr-1 {
  margin-right: 1rem !important;
}
.ml-1 {
  margin-left: 1rem !important;
}

.px-1 {
  padding: 0rem 1rem !important;
}
.py-1 {
  padding: 1rem 0rem !important;
}
.p-1 {
  padding: 1rem !important;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.landing-screen {
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: max-content;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.one-line-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* BUTTON */

.btn {
  background: var(--primary-color);
  box-sizing: border-box;
  border: 1px solid var(--primary-color) !important;
  cursor: pointer;
  letter-spacing: inherit;
  font-family: inherit;
  transition: 0.05s ease;
  /* font-weight: 900;
  text-transform: uppercase; */
}

.btn:hover {
  background: var(--white-color) !important;
  color: var(--primary-color) !important;
}

.section-heading {
  text-align: center;
  padding-top: 40px;
}

.lato-heading {
  color: var(--background-color);
}

.lato-desc {
  font-family: "Lato", sans-serif !important;
  font-weight: 300 !important;
}

input::placeholder {
  font-family: "Lato", sans-serif !important;
}
::-webkit-input-placeholder {
  font-family: "Lato", sans-serif !important;
}

:-ms-input-placeholder {
  font-family: "Lato", sans-serif !important;
}

:-moz-placeholder {
  font-family: "Lato", sans-serif !important;
}

::-moz-placeholder {
  font-family: "Lato", sans-serif !important;
}

/* Media queries */
@media screen and (max-width: 1200px) {
  h1,
  .h1 {
    font-size: 28px;
  }
  h2,
  .h2 {
    font-size: 24px;
  }
  h3,
  .h3 {
    font-size: 22px;
  }
  .large-text {
    font-size: 36px;
  }
  .x-large-text {
    font-size: 46px;
  }
}

@media screen and (max-width: 800px) {
  h4,
  .h4 {
    font-size: 18px;
  }
  h5,
  .h5 {
    font-size: 14px;
  }
  h6,
  .h6 {
    font-size: 12px;
  }
  .large-text {
    font-size: 32px;
  }
  .x-large-text {
    font-size: 42px;
  }
}

@media screen and (max-width: 640px) {
  h1,
  .h1 {
    font-size: 25px;
  }
  h2,
  .h2 {
    font-size: 21px;
  }
  h3,
  .h3 {
    font-size: 19px;
  }
  .large-text {
    font-size: 28px;
  }
  .x-large-text {
    font-size: 38px;
  }
}

@media screen and (max-width: 400px) {
  h1,
  .h1 {
    font-size: 24px;
  }
  h2,
  .h2 {
    font-size: 20px;
  }
  h3,
  .h3 {
    font-size: 18px;
  }
  .large-text {
    font-size: 24px;
  }
  .x-large-text {
    font-size: 34px;
  }
}
