.trending_products_container{
    margin:20px 0px;
}

.trending_products_container .trending_card_container,.trending_big_container{
    margin:auto;
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    padding-top: 15px;
    flex-wrap: wrap;
}

.trending_big_container .trending_big_card{
    width:25%
}

.trending_big_container .trending_big_card .tb_card{
    background-color: #EEEFFB;
    padding:20px;
    box-shadow: 0 8px 40px rgba(49,32,138,0.05);
    cursor: pointer;
    transition: 0.5s ease;
}

.trending_big_container .trending_big_card .tb_card:hover{
    box-shadow: 0 2px 20px #f2665467;
}

.trending_big_container .trending_big_card .tb_card .card_title{
    color: #151875;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 5px;
}

.trending_big_container .trending_big_card .tb_card .card_link{
    color:#F26654;
    font-family: 'LATO';
    font-size:12px;
    font-weight: bold;
    padding-bottom: 10px;
}

.trending_big_container .trending_big_card .tb_card .img_cont img{
    width: 100px;
    display: block;
    margin-left: auto;
}

.trending_big_card .small_card_container{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.trending_big_card .small_card_container .small_card{
    cursor: pointer;
    display:flex;
    align-items: center;
}

.trending_big_card .small_card_container .small_card .img_container{
    padding:2px;
    background-color: #F5F6F8;
    text-align: center;
}

.trending_big_card .small_card_container .small_card .text_container .product_title{
    color: #151875;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 1px;
}

.trending_big_card .small_card_container .small_card .text_container{
    margin-left: 5px;
}

.trending_big_card .small_card_container .small_card .text_container .canc_price{
    color: #151875;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 10px;
    text-decoration: line-through;
    font-weight: bold;
    padding-bottom: 1px;
}

.trending_big_card .small_card_container .small_card .img_container img{
    width: 50px;
}

.trending_products_container .trending_card_container .trending_card{
    width: 20%;
    padding:8px;
    box-shadow: 0 8px 40px rgba(49,32,138,0.05);
    cursor: pointer;
    transition: 0.5s ease;
}

.trending_products_container .trending_card_container .trending_card:hover{
    box-shadow: 0 2px 20px #f2665467;
}

.trending_products_container .trending_card_container .trending_card img{
    width: 100px;
}

.trending_products_container .trending_card_container .trending_card .img_container{
    padding:10px;
    background-color: #F5F6F8;
    text-align: center;
}

.trending_products_container .trending_card_container .trending_card .text_container{
    text-align: center;
    margin:10px 0px;
}

.trending_products_container .trending_card_container .trending_card .text_container .product_title{
    color: #151875;
    font-family: 'LATO';
    font-weight: bold;
    font-size: 17px;
}

.trending_products_container .trending_card_container .trending_card .text_container .price_container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.trending_products_container .trending_card_container .trending_card .text_container .price_container .up_price{
    color: #151875;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 12px;
    font-weight: bold;
}

.trending_products_container .trending_card_container .trending_card .text_container .price_container .canc_price{
    text-decoration: line-through;
    color:rgba(21,24,117,0.3);
    font-family: 'Josefin Sans', sans-serif;
    margin-left: 5px;
    font-size: 12px;
}

@media screen and (max-width: 750px) {
    .trending_products_container .trending_card_container .trending_card{
        width:40%;
        margin:10px 0px;
    }
    .trending_big_container .trending_big_card{
        width: 40%;
        margin:10px 0px;
    }
    .trending_big_card .small_card_container .small_card{
        margin:5px 0px;
    }
}

@media screen and (max-width: 550px) {
    .trending_products_container .trending_card_container .trending_card{
        width:100%;
        margin:10px 0px;
    }
    .trending_big_container .trending_big_card{
        width: 100%;
        margin:10px 0px;
    }

}
