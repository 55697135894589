.billing-container .title{
    text-align: left;
    font-size: 24px;
}

.billing-container .amount-container .amount-title{
    font-family: 'LATO';
    font-weight: bold;
    font-size: 18px;
}

.billing-container .amount-container .distribution{
    display: flex;
    justify-content: space-between;
    font-family: 'LATO';
    margin: 5px 0px;
    color: #506385;
    font-weight: bold;
}

.billing-container .billing-details{
    margin-top: 20px;
}

.lato-input::placeholder {
    font-family: 'LATO' !important;
}