.col-2 {
    display: flex;
    justify-content: space-between;
}

.col-2 .input_container {
    flex: 1/2  !important;
    justify-content: space-between !important;
    
}

.coupen_select .css-1ulg7wo-control {
    padding: 7.5px !important;
    margin-top: 5px !important;
}