.purchase_table_container {
  margin-top: 30px;
  overflow: auto !important;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin: auto;
}

th,
td {
  padding: 12px;
  border-bottom: 1px solid #f26654;
  text-align: center;
}
th {
  font-size: 20px;
}
td {
  font-size: 18px;
}
.minus {
  color: red;
  font-weight: bold;
  font-size: 20px;
}
.plus {
  color: green;
  font-weight: bold;
  font-size: 20px;
}

.blink-bg {
  animation: blinkingBackground 2s infinite;
}
@keyframes blinkingBackground {
  0% {
    background-color: rgba(244, 127, 114, 0.37);
  }
  50% {
    background-color: #fff;
  }
  100% {
    background-color: rgba(244, 127, 114, 0.37);
  }
}
