.admin-menu-container{
    margin-top:40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 700px) {
    .admin-menu-container{
        align-items: center !important;
    }    
}

.admin-menu-container .title{
    font-family: 'LATO';
    color:#506385;
    font-size:30px;
    margin-bottom: 20px;
    text-align: center;
}

.admin-menu-container .menu_container{
    margin-top: 10px;
}

.admin-menu-container .menu_container div a{
    margin:25px 0px;
    font-family: 'LATO';
    color:#000;
    position: relative;
    font-size:18px;
    text-decoration: none;
}

.admin-menu-container .menu_container div a::before{
    content: "";
    height: 2px;
    background: #F26654;
    width: 0%;
    position: absolute;
    bottom: 0%;
    transition: 0.5s ease;
}

.admin-menu-container .menu_container div a:hover::before{
    width: 100%;
}

.admin-menu-container .menu_container div a:hover{
    color: #F26654
}

.admin-menu-container .menu_container div{
    padding:10px 0px;
}

@media screen and (max-width: 900px) {
    .admin-menu-container .menu_container{
        display: flex;
        flex-wrap: wrap;
    }
    
    .admin-menu-container .menu_container div{
        margin:10px 10px 0px 0px;
    }

    .admin-menu-container{
        align-items:flex-start;
        margin-left:10px;
        margin-right: 10px;
    }
}

.input_container input, .input_container select{
    padding: 15px;
    font-size: 14px;
    outline: none;
    border: 1px solid rgba(0,0,0,0.5);
    box-shadow: 0px 1px 4px rgba(0,0,0,0.1);
    width: 100%;
}

.input_container select{
    padding-left: 20px;
    padding-right: 20px;
    width: 100% !important;
}

.input_container input:focus, .input_container select:focus{
    border-color:#F26654;
}
