.founder_container {
  width: 30%;
  text-align: center;
  margin-top: 3rem;
}

.founder_container img {
  width: 70%;
  display: inline-block;
  margin: auto;
}

.founder_container .quote,
.founder_container .founder_position {
  margin-top: 40px;
  margin-bottom: 10px;
  text-align: center;
  font-style: italic;
  font-size: "LATO";
}

.signup-video-con {
  margin-top: 24rem;
}

@media screen and (max-width: 750px) {
  .founder_container {
    width: 90%;
    margin-top: 1rem;
  }

  .signup-video-con {
    margin: 3rem auto;
  }
}
