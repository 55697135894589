.clean-closet-container{
    width: 90%;
    margin: auto;
    margin-top: 40px;
}

.clean-closet-container .clean-closet-intro{
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    align-items: center;
}

.clean-closet-container .clean-closet-intro .intro-text{
    font-size: 28px;
    color: #333;
    line-height: 40px;
    font-weight: bold;
    margin-right: 20px;
}

.clean-closet-container .clean-closet-intro .intro-img{
    width: 100%;
}

.clean-closet-container .clean-closet-intro .intro-img img{
    max-width: 100%;
}

.clean-closet-container .clean-closet-section{
    margin: 40px 0px;
}

.clean-closet-container .clean-closet-section div{
    color: #333;
    font-size: 18px;
    line-height: 30px;
}

.clean-closet-container .actn-btn{
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.clean-closet-container .actn-btn button{
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    margin: 0px 20px;
}

@media screen and (max-width: 750px) {
    .clean-closet-container{
        margin-top: 70px;
    }
    .clean-closet-container .clean-closet-intro{
        flex-direction:column-reverse;
        margin-top: 40px;
    }
    .clean-closet-container .clean-closet-intro .intro-text{
        margin-right: 0px;
        margin-top: 20px;
    }
    .clean-closet-container .clean-closet-intro .intro-img{
        text-align: center;
    }
    .clean-closet-container .actn-btn{
        flex-direction: column;
    }
    .clean-closet-container .actn-btn button{
        margin: 10px 0px;
    }
}