.adm-sidebar {
  position: fixed;
  top: 5.5rem;
  left: 0;
  bottom: 0;
  width: 200px;
  padding: 20px;
  background-color: var(--background-color);
  color: var(--white-color);
  transition: transform 0.5s ease;
  z-index: 10000;
  overflow-y: auto;
}

.adm-sidebar.collapsed {
  transform: translateX(-200px);
  width: 200px;
  /* padding: 20px 0; */
}

.adm-sidebar .logo {
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--peel-orange-color);
  padding: 10px;
  text-align: center;
}

.adm-sidebar .navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.adm-sidebar .navigation ul li {
  margin-bottom: 10px;
}

.adm-sidebar .navigation ul li a {
  color: #fff;
  display: block;
  padding: 10px;
  text-decoration: none;
  width: 100%;
}

.adm-sidebar .navigation ul li a:hover {
  color: #ddd;
}

.user-info {
  padding: 10px;
  text-align: center;
}

.active_admin {
  color: var(--peel-orange-color) !important;
  font-weight: 700 !important;
}

@media screen and (max-width: 1050px) {
  .adm-sidebar {
    top: 4rem;
  }
}
