.purchased_description_container{

}

.purchased_description_container .product_title{
    font-family: 'LATO';
    font-size:30px;
    font-weight: bold;
    color:#506385;
    margin-bottom: 5px;
}

.purchased_description_container .brand{
    margin-bottom: 20px;
}

.purchased_description_container .brand img{
    
}

.purchased_description_container .product_meta_container{
    display: flex;
}

.purchased_description_container .product_meta_container .product_meta_data{
    margin-right: 20px;
    width: 60%;
}

.purchased_description_container .product_meta_container .product_meta_data .product_price{
    font-family: 'LATO';
    font-size:28px;
    font-weight: bold;
    color:#506385;
    margin-bottom: 10px;
}

.purchased_description_container .product_meta_container .product_meta_data .product_description{
    font-family: 'LATO';
    font-size:18px;
    margin-bottom: 10px;
}

.purchased_description_container .product_meta_container .product_meta_data .product_info > div, .purchased_description_container .product_meta_container .product_actions .product_info > div{
    font-family: 'LATO';
    font-size:18px;
    margin-bottom: 10px;
}

.purchased_description_container .product_meta_container .product_meta_data .product_info .chat-button {
    background: var(--primary-color);
    padding: .5rem 1rem;
    text-align: center;
    color: var(--white-color);
    font-weight: 700;
    cursor: pointer;
    border-radius: 3px;
    margin: 1.5rem auto;
    width: 50%;
    margin-left: 0;
}


.purchased_description_container .product_meta_container .product_actions .btn{
    font-family: 'LATO';
    font-size:20px;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.purchased_description_container .product_meta_container .product_actions .report_btn{
    background-color:#FFD9D4;
    font-weight: bold;
    font-family: 'LATO';
    border: 1px solid block;
    outline: none;
    padding: 10px;
    cursor: pointer;
}


@media screen and (max-width: 600px) {
    .purchased_description_container .product_meta_container{
        flex-direction: column;
    }

    .purchased_description_container .product_meta_container .product_meta_data{
        width: 80%;
        margin-bottom: 30px;
    }

    

    .purchased_description_container .product_meta_container .product_actions .btn{
        width:80%;
    }
}