.info-card-container {
  /* border: 1px solid var(--peel-orange-color); */
  border-radius: 10px;
  /* box-shadow: 4px 4px 10px rgba(234, 100, 100, 0.15); */
  padding: 25px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info-card-container .closet-name {
  font-size: 28px;
  color: #506385;
  margin-bottom: 10px;
  overflow-wrap: anywhere;
  text-align: center;
}

.info-card-container .closet-icon {
  margin-bottom: 5px;
}

.info-card-container .closet-icon {
  text-align: center;
}

.info-card-container .closet-icon img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.info-card-container .user-info-container {
  /* margin-bottom: 15px; */
  text-align: center;
  color: #506385;
}

.info-card-container .user-info-container .user-name {
  font-size: 22px;
}

.info-card-container .closet-rating {
  color: #506385;
  text-align: center;
}

.info-card-container .closet-rating > div {
  margin: 5px;
}

.info-card-container .closet-rating .rating-container {
  display: flex;
  justify-content: space-evenly;
}

.info-card-container .closet-rating .rating-container .icon-container i {
  color: #f26654;
  display: inline-block;
  font-size: 28px;
  margin: 0px 2px;
}

.rating label {
  margin: 0;
  padding: 0;
}

/****** Style Star Rating Widget *****/

.rating {
  border: none;
  float: left;
}

.rating > input {
  display: none;
}
.rating > label:before {
  margin: 5px;
  font-size: 1.25em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}

.rating > .half:before {
  content: "\f089";
  position: absolute;
}

.rating > label {
  color: #ddd;
  float: left;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating > input:checked ~ label, /* show gold star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label {
  color: var(--primary-color);
} /* hover previous stars in list */

.rating > input:checked + label:hover, /* hover current star when changing rating */
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating > input:checked ~ label:hover ~ label {
  color: var(--primary-color);
}

@media screen and (max-width: 900px) {
  .info-card-container {
    margin: auto;
    width: 90%;
  }
}
