.category-width {
  width: 60%;
}

@media screen and(max-width: 767px) {
  .category-width {
    width: 100%;
  }
  .add-new-brand-btn-con {
    margin: 1rem auto;
  }
}
