.spinner_container1 {
  position: relative !important;
  width: 100%;
  height: 100%;
  z-index: 50;
  display: flex;
  top: 0px;
  background: #f1f1f170;
  min-height: 100vh;
}

.spinner_container1 img {
  /* position:absolute;
    bottom:10%;
    left:50%; */
  /* transform:translateX(-50%); */
  width: 300px;
  margin: auto;
  display: block;
  position: relative !important;
  bottom: auto !important;
  left: auto !important;
  transform: translateX(0%) !important;
}
