.search-main-con {
  display: flex;
  color: var(--background-color);
}
.sr-con {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 70%;
}

.sr-card {
  width: 300px;
  /* height: 300px; */
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem;
  text-align: center;
  border-bottom: 5px solid transparent;
}

.sr-card-img {
  /* width: 300px; */
  height: auto;
  border-radius: 5px;
}

.sr-card-desc {
  margin-top: 0.5rem;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;
}

.sr-card-img img {
  width: 100%;
  max-height: 300px;
  object-position: center;
  background-size: cover;
  border-radius: 5px;
}

.sr-card:hover {
  border-bottom: 5px solid #f17c6d;
  transition: all 0.5s ease;
}

.sr-card-user {
  background: #f17c6d;
  border-radius: 10px;
  color: var(--white-color) !important;
  cursor: pointer;
}

.sr-con2 {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 30%;
  height: fit-content;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
}

.sr-con2 .closet-wrapper {
  max-width: 160px;
  text-align: center;
  height: auto;
  margin: 0.5rem;
  padding: 0.5rem;
}

.sr-con2 .closet-wrapper .closet-img-wrapper {
  width: 150px;
  height: auto;
  border-radius: 50%;
  margin: auto;
}

.sr-con2 .closet-wrapper .closet-img-wrapper .closet-img {
  width: 100%;
  height: 150px;
  background-size: cover;
  border-radius: 50%;
}

@media screen and (max-width: 640px) {
  .sr-con {
    width: 60%;
  }
  .sr-con2 {
    width: 40%;
    text-align: center;
  }
  .sr-card {
    width: 150px;
  }

  .sr-card-img img {
    max-height: 150px;
  }

  .sr-con2 .closet-wrapper .closet-img-wrapper .closet-img {
    height: 100px;
  }

  .sr-con2 .closet-wrapper .closet-img-wrapper {
    width: 100px;
  }
}
