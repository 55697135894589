.landing-section {
  display: flex;
  height: 75vh;
}

.gradient-text {
  /* background: linear-gradient(to right, #f26654 0%, #28607c 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; */
  line-height: 1.3;
  color: var(--background-color);
}

.element {
  display: inline-block;
  padding-bottom: 3px;
}

.element:after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  animation: underline 4s infinite;
}
@keyframes underline {
  0% {
    width: 0%;
    background-color: var(--primary-color);
  }
  100% {
    width: 100%;
    background-color: transparent;
  }
}

.landing-section .landing-sec-right,
.landing-section .landing-sec-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 50%;
}

.landing-section .landing-sec-left {
  margin-top: 3rem;
}

.landing-section .landing-sec-right {
  /* width: 80%; */
  padding-right: 4rem;
  margin-top: 2rem;
  /* justify-content: flex-end; */
  /* padding-bottom: 5rem; */
}

.landing-sec-right .landing-sec-logo {
  width: 150px;
  margin-bottom: 2rem;
}

.landing-sec-right .landing-sec-btn-con {
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin-top: 1rem;
}

.landing-sec-right .heading {
  font-size: 48px;
  font-weight: 700;
  color: #f26654;
}

.landing-sec-right .desc {
  font-size: 24px;
  line-height: 1.2;
  margin: 0.5rem;
}

.landing-sec-right .btn {
  margin: auto 1rem;
  text-decoration: none !important;
  min-width: 160px;
  border-radius: 3px;
}

.landing-sec-left .img-con {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.landing-sec-left .img-con {
  /* box-shadow: rgba(242, 102, 84, 0.5) -5px 5px, rgba(242, 102, 84, 0.4) -10px 10px, rgba(242, 102, 84, 0.3) -15px 15px, rgba(242, 102, 84, 0.2) -20px 20px, rgba(242, 102, 84, 0.1) -25px 25px;

  -webkit-box-shadow:0px 0px 47px 5px rgba(130,130,130,0.62);
-moz-box-shadow: 0px 0px 47px 5px rgba(130,130,130,0.62);
box-shadow: 0px 0px 47px 5px rgba(130,130,130,0.62); */

  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
  /* box-shadow: 5.6px 11.2px 11.2px hsl(0deg 0% 0% / 0.33); */
  /* box-shadow: 6px 12px 12px -1px rgba(0, 0, 0, 0.25); */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 15px 10px -5px;
}

.landing-sec-left .img-con img {
  width: 100%;
  height: auto;
}

.video-play-btn {
  position: absolute;
  /* top: 50%; */
  /* left: 50%; */
  /* padding: 2rem; */
  background-color: var(--white-color);
  border-radius: 50%;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}

.video-play-btn .dot_animation {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  animation: pulse 3s infinite;
  transform: translate(-50%, -50%);
  animation-delay: 1.5s;
  will-change: transform;
}

.video-play-btn:after {
  opacity: 0.15;
  transform: scale(3);
  top: 0;
}

@media screen and (max-width: 1300px) {
  .landing-section .landing-sec-right {
    padding-right: 1rem;
  }
}

@media screen and (max-width: 1150px) {
  .landing-section .landing-sec-right {
    padding-right: 0rem;
  }
}

@media screen and (max-width: 950px) {
  .landing-section {
    padding: 2rem 1.5rem;
    flex-direction: column;
    height: auto;
  }
  .landing-section .landing-sec-left,
  .landing-section .landing-sec-right {
    width: 100%;
  }
  .landing-section .landing-sec-right {
    margin-top: 4rem;
  }
  .landing-sec-right .heading {
    font-size: 40px;
  }
  .landing-sec-right .landing-sec-btn-con {
    width: 100%;
  }
  .landing-sec-right .desc {
    font-size: 18px;
  }
  .landing-sec-left .img-con {
    width: 80%;
  }
  .landing-sec-right .landing-sec-logo {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .landing-section {
    min-height: 75vh;
  }
  .landing-sec-left .img-con {
    width: 100%;
  }
  .landing-sec-right .btn {
    min-width: min-content;
  }

  .landing-section .landing-sec-left {
    padding: 1rem;
    margin-top: 0;
  }
}
