.success-checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 2rem;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: auto;
  background: lightgreen;
}

.order-cart-con {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
    align-items: center; */
  margin: 0rem 1rem;
  margin-bottom: 2rem;
}
.closest_setup_container h2 {
  text-transform: uppercase;
}

.closest_setup_container .main-con {
  display: flex;
  flex-direction: column;
  /* border: solid 2px #212121; */
  border-radius: 5px;
  max-width: 1100px;
  margin: 2rem auto;
}

.order-cart-con .product-details-con {
  display: flex;
  /* align-items: flex-start; */
  justify-content: space-between;
  margin: 1rem;
  border-top: 1px solid #ddd;
  padding-top: 1rem;
}

.order-cart-con .product-details-con:first-child {
  border-top: none;
}

.order-cart-con .product-details-con .img-con {
  /* max-width: 200px; */
  border-radius: 5px;
  flex-basis: 30%;
}

.order-cart-con .product-details-con div {
  width: 25%;
  text-align: left;
}

.order-cart-con .product-details-con .product-desc {
  display: flex;
  flex-direction: column;
  line-height: 1.4;
  flex-basis: 40%;
}

.order-cart-con .product-details-con .product-price {
  line-height: 1.4;
  flex-basis: 30%;
}

.order-cart-con .product-details-con .product-desc h4,
h6 {
  margin: 0.5rem 0rem;
}
.order-cart-con .product-details-con .img-con img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
  object-fit: cover;
  background-size: cover;
  max-width: 250px;
  max-height: 250px;
}

.shipping-details-con {
  margin: 0 1rem 1.5rem !important;
}
.shipping-details-con h5 {
  text-align: left !important;
  color: #000;
  line-height: 1.5;
}

.closest_setup_container .section_title {
  padding: 0 1.5rem;
  width: fit-content;
  text-align: center;
}

.main-con .cartId {
  word-break: break-all;
  margin: 1rem 1rem;
}

.main-con .cart-prod-details-heading {
  margin: 0rem 1rem;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  line-height: 1.8;
  color: var(--background-color);
}

.order-chat-btn-con {
  margin: 2rem auto;
  padding: 0rem 1rem;
}

.order-product-group-con {
  /* border: 1px solid rgb(33, 33, 33); */
  margin-bottom: 1.5rem;
  border-radius: 5px;
  transition: all 0.3s ease;
  position: relative;
}

.order-product-group-con:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;
  background: #fff8f5;
}

.order-product-group-index {
  width: 30px;
  height: 30px;
  background: var(--primary-color);
  /* position: absolute; */
  top: 1rem;
  left: 1rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var;
  color: #fff;
  margin-left: 1.5rem;
  margin-top: 1.5rem;
}

.shipping-info-div1 {
  display: flex;
  flex-basis: 70%;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0;
}

.shipping-info-div2 {
  display: "flex";
  flex-basis: "30%";
}
.shipping-info-par-con {
  justify-content: flex-end;
}

@media screen and (max-width: 850px) {
  .order-cart-con .product-details-con {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .order-product-group-con {
    max-width: 500px;
    min-width: 80%;
    margin: 2rem auto;
  }
  .order-cart-con .product-details-con .img-con {
    flex-basis: 100%;
    width: 80%;
    margin: auto;
  }
  .order-cart-con .product-details-con .product-desc {
    flex-basis: 100%;
    width: 80%;
    margin: auto;
    text-align: center;
  }
  .order-cart-con .product-details-con .product-price {
    flex-basis: 100%;
    width: 80%;
    margin: auto;
    text-align: center;
  }
  .order-cart-con .product-details-con div {
    /* width: 100%; */
    text-align: center;
  }
  .order-product-header-sec {
    display: none !important;
  }
  .order-cart-con .product-details-con:first-child {
    margin-top: 1rem;
  }
  .shipping-info-div1 {
    flex-basis: 0;
    display: none;
  }
  .shipping-info-div2 {
    flex-basis: 100%;
    margin: 0 0.7rem;
  }
  .shipping-info-par-con {
    width: 80%;
    margin: auto !important;
    display: block !important;
  }
}

@media screen and (max-width: 640px) {
  .closest_setup_container .main-con {
    margin: 0.5rem;
  }
}

@media screen and (max-width: 550px) {
  .main-con .cartId {
    margin: 0 0.5rem !important;
  }
  .main-con .cart-prod-details-heading {
    margin: 0 0.5rem;
  }
  .main-con .order-cart-con {
    margin: 1rem 0.5rem;
  }
  .order-cart-con .product-details-con {
    margin: 1rem auto;
    margin-top: 3rem;
  }
  .order-cart-con .product-details-con:first-child {
    margin: 0;
  }
  .shipping-details-con {
    margin: 0rem 0.5rem 1.5rem !important;
  }
  .shipping-info-div2 {
    margin: 0;
  }
}
