/* .services {
        
        width: 100%;
        display: grid;
        place-items: center;
        background-color: var(--white-color);
        z-index: 1;
} */
.row {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem auto;
    z-index: 1;
  }
  .s-column {
    width: 100%;
    padding: 0 1em 1em 1em;
    text-align: center;
    margin: 1rem auto;
    z-index: 1;
    overflow: hidden;
  }
  .service-card {
    margin: auto;
    width: 80%;
    height: 100%;
    padding: 2em 1.5em;
    background: linear-gradient(#ffffff 50%, var(--peel-orange-color) 50%);
    background-size: 100% 200%;
    background-position: 0 2.5%;
    border-radius: 5px;
    /* box-shadow: 0 0 35px rgba(0, 0, 0, 0.12); */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    transition: 0.5s;
  }

  .service-card .h2 {
    color: var(--background-color);
  }
  
  .service-card button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 1.5rem;
    margin: auto;
    background: var(--primary-color);
    color: var(--white-color);
    border: 1px solid transparent;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    border-radius:3px;
    transition: 0.5s;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    color: #1f194c;
    margin: 1em 0;
  }
  p {
    color: var(--grey-color);
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.03em;
  }
  .icon-wrapper {
    background-color: var(--primary-color);
    position: relative;
    margin: auto;
    font-size: 28px;
    height: 3em;
    width: 3em;
    color: var(--white-color);
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: 0.5s;
    margin-bottom: .5rem;
  }
  .service-card:hover {
    background-position: 0 100%;
  }
  .service-card:hover .icon-wrapper {
    background-color: var(--white-color);
    color: var(--primary-color);
  }
  .service-card:hover .h2 {
    color: var(--white-color) !important;
  }
  .service-card:hover p {
    color: #f0f0f0;
  }
  .information-index {
    position: absolute;
    margin-left: -3.2rem;
    font-size: 80px;
    z-index: 0;
    color: #c9c9c9;
    font-weight: 100;
    overflow: hidden;
    transition: 0.5s;
  }
  .service-card:hover .information-index {
    color: #fffcfa;

  }
  .service-card:hover button {
    color: var(--primary-color);
    background: var(--white-color);
    cursor: pointer;
  }

  .earth{
    position: absolute;
    color: var(--primary-color);
    bottom: 19%;
  }

  .service-card:hover .icon-wrapper .earth{
    color: var(--white-color);
  }
  @media screen and (min-width: 768px) {
    section {
      padding: 0 2em;
    }
    .s-column {
      flex: 0 50%;
      max-width: 50%;
    }
  }
  @media screen and (min-width: 992px) {
    section {
      padding: 1em 3em;
    }
    .s-column {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
    .middle-heading {
      display: flex ;
      justify-content: center;
      align-items: center;
    }
    .top-heading {
      display: none;
    }
    
  }

  @media screen and (max-width: 992px) {
    .middle-heading {
      display: none;
    }
    .top-heading {
      display: flex ;
      justify-content: center;
      align-items: center;
    }
  }