.adm-prod-con {
  width: 100%;
  height: 100%;
  position: relative;
}

.adm-prod-wrapper {
  padding: 0px 20px;
  position: relative;
}

.adm-prod-con .adm-prod-wrapper .products-header-admin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  /* z-index: 111; */
  padding: 1.5rem 0;
  padding-bottom: 0;
  top: 5rem;
  background: var(--white-color);
}

/* .adm-prod-con .adm-prod-wrapper .products .products-table {
    margin: 4rem auto;
} */

.product-operations {
  opacity: 0;
  display: flex;
}

.product {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px auto;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;
  cursor: pointer;
}

.product:hover {
  background: #f1f1f1;
}

.product:hover .product-operations {
  opacity: 1;
  margin-right: -5px;
}

.product .product-img {
  display: flex;
  width: 200px;
  height: 100%;
  margin: 0 auto;
  background-size: cover;
  border-radius: 2px;
}

/* .product img {
    display: block;
    margin: 0 auto;
    width: auto;
    height: 200px;
    max-width: calc(100% - 20px);
    background-cover: fit;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.8);
    border-radius: 2px;
} */

.product-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 3;
}

.product-content .product-details-row {
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
}

.product-content .product-details-row .col {
  flex: 1 1;
}

.product-content .product-details-row .col:last-child {
  margin-top: 1rem;
}

.product-content .product-hr {
  width: 50px;
  border-width: 3px;
  border-style: solid;
  margin: 8px 0 14px 0;
  border-color: var(--primary-color);
}

.product h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0 0 0;
}

.product h3 small {
  display: block;
  font-weight: 400;
  font-style: italic;
  margin: 7px 0 0 0;
}

.product .product-text {
  margin: 7px 0 0 0;
  color: #777;
  text-align: left;
}

.adm-prod-wrapper .pagination {
  margin-left: 0;
}

.sortProducts {
  /* position: absolute; */
  /* top: 2rem; */
  /* right: 0rem; */
  /* padding-bottom: 3rem; */
}

.productsSorter:hover .products-drop-down {
  display: block;
  /* margin-top: 1rem; */
}

.sortProducts .productsSorter {
  padding-bottom: 1rem;
}

.sortProductsDropdown {
  font-family: "Lato", sans-serif !important;
  margin: 25px 0px;
  color: #000;
  position: relative;
  /* padding-bottom: 1rem; */
  text-decoration: none;
}

.sortProductsDropdown::before {
  content: "";
  height: 2px;
  background: #f26654;
  width: 0%;
  position: absolute;
  bottom: -1.5rem;
  transition: 0.5s ease;
  /* padding-top: rem; */
  border-bottom: 1px solid var(--primary-color) !important;
}

.sortProductsDropdown:hover::before {
  width: 100%;
  /* border-bottom: 1px solid var(--primary-color); */
}

.sortProductsDropdown:hover {
  color: #f26654;
}

.products-drop-down {
  /* display: none; */
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: fit-content;
  padding: 8px;
  margin-left: 2rem;
  position: absolute;
  right: 0%;
  background-color: #fff;
  z-index: 10;
  display: none;
}

.products-drop-down a {
  color: #506385;
  text-decoration: none;
  margin-bottom: 10px;
  display: block;
}

.products-drop-down a:hover {
  color: #f26654;
}

.archived_ribbon {
  --d: 3px; /* folded part */
  --c: var(--primary-color); /* color */
  --f: 12px; /* ribbon font-size */

  position: relative;
  /* display: inline-block; */
}

.archived_ribbon::before {
  z-index: 1;
  content: attr(data-ribbon);
  position: absolute;
  font-size: var(--f);
  top: 0;
  right: 0;
  transform: translate(29.29%, -100%) rotate(45deg);
  color: #fff;
  text-align: center;
  border: 1px solid transparent;
  border-bottom: 0;
  transform-origin: bottom left;
  padding: 5px 35px calc(var(--d) + 5px);
  background: linear-gradient(rgba(0, 0, 0, 0.5) 0 0) bottom/100% var(--d)
    no-repeat var(--c);
  background-clip: padding-box;
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    calc(100% - var(--d)) calc(100% - var(--d)),
    var(--d) calc(100% - var(--d)),
    0 100%
  );
  -webkit-mask: linear-gradient(
        135deg,
        transparent calc(50% - var(--d) * 0.707),
        #fff 0
      )
      bottom left,
    linear-gradient(-135deg, transparent calc(50% - var(--d) * 0.707), #fff 0)
      bottom right;
  -webkit-mask-size: 300vmax 300vmax;
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
}

.left_ribbon::before {
  left: 0;
  right: auto;
  transform: translate(-29.29%, -100%) rotate(-45deg);
  transform-origin: bottom right;
}

.products-header-admin .input_container.search_closet {
  margin-top: 0 !important;
}

.products-header-admin .mt-0 {
  margin-top: 0 !important;
  border-radius: 5px 0 0 5px;
  padding: 6px 12px;
}
.products-header-admin .input_container input:focus,
.products-header-admin .input_container select:focus {
  border-radius: 5px 0 0 5px !important;
}

@media screen and (max-width: 1050px) {
  .adm-prod-con .adm-prod-wrapper .products-header-admin-admin {
    position: static;
    padding-top: 0;
  }
  .products-drop-down {
    /* display: none; */
    right: 20%;
    transform: translateX(50%);
  }
}

@media screen and (max-width: 700px) {
  .products-header-admin {
    padding: 0;
    flex-direction: column;
  }
  .adm-prod-con .adm-prod-wrapper .products-header-admin {
    /* padding-top: 30px; */
  }
  .products-header-admin .input_container .search_closet {
    width: 100% !important;
  }
  .products-header-admin .input_container .search_closet div {
    width: 100% !important;
    margin: auto;
  }
  .products-header-admin .input_container_wrapper {
    margin: auto !important;
    width: 100%;
  }
  .product-content .product-details-row .col:last-child {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .adm-prod-wrapper {
    padding: 0;
  }
  .product {
    width: 100%;
    margin: 40px auto;
  }
  .product-con-wrapper {
    padding: 0.5rem !important;
  }
  .product_section .product_image_gallery {
    padding: 0 !important;
  }
}
