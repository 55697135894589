* {
  box-sizing: border-box;
}

:root {
  --maxWidth: 1650px;
}

.page-container {
  max-width: var(--maxWidth);
  margin: auto;
}

.site-width {
  max-width: var(--maxWidth);
}

body {
  margin: 0px;
  padding: 0px;
  font-family: "Lato", sans-serif;
  color: #212121;
}

.header {
  padding-left: 40px;
  padding-right: 40px;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .header_left {
  display: flex;
  align-items: center;
}

.header .header_right {
  display: flex;
  align-items: center;
}

input::placeholder {
  font-family: lato, sans-serif !important;
  font-size: 14px;
  /* color: #ccc; */
}

.input_search {
  padding: 8px;
  font-size: 14px;
  outline: none;
  border: 1px solid #e7e6ef;
  width: 370px;
}

.input_search:focus {
  border-color: #f26654;
}

.btn {
  padding: 9.5px 25px;
  background-color: #f17c6d;
  outline: none;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: 0.5s ease;
  border-radius: 3px;
}

.dis-btn {
  opacity: 0.5;
  cursor: not-allowed;
}

.dark-btn {
  background-color: #f26654;
  /* box-shadow: 1px 1px 2px rgba(0,0,0,0.5); */
}

.btn:hover {
  background-color: #f26654;
}

.dark-btn:hover {
  box-shadow: none;
}

.dark-btn:active {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.icon_container {
  color: #f26654;
  cursor: pointer;
  padding: 0px 15px;
  position: relative;
}

.icon_container i {
  font-size: 28px;
}

.badge {
  right: 10%;
  font-size: 8px;
  font-weight: bolder;
  display: inline-block;
  text-decoration: none;
  border: 1px solid;
  padding: 0px 2px;
  position: absolute;
  text-align: center;
}

.badge-bag {
  font-size: 8px;
  font-weight: bolder;
  display: inline-block;
  text-decoration: none;
  border: 1px solid;
  padding: 0px 2px;
  position: absolute;
}

.section_title {
  color: #506385;
  margin: 10px auto;
  text-align: center;
}

.paragraph_title {
  color: #506385;
  font-size: x-large;
  margin: 10px auto;
  padding-top: 40px;
  padding-bottom: 5px;
  text-align: center;
}

.view_btn {
  padding: 5px 15px;
  display: block;
  background-color: #08d15f;
  outline: none;
  border: none;
  color: #fff;
  cursor: pointer;
  margin: 5px auto 0px auto;
  transition: 0.5s ease;
}

.signup {
  display: flex;
  margin: 60px auto;
  width: 90%;
  justify-content: space-evenly;
}

.signup_right {
  width: 60%;
}

.small_header {
  display: none;
}

.small_header2 {
  display: block;
}

.badge_parent {
  background-color: var(--peel-orange-color);
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
  margin: 8px;
  width: fit-content;
}

#shadow-host-companion {
  padding: 0px;
}

@media screen and (max-width: 900px) {
  .input_search {
    width: 300px;
  }
}

@media screen and (max-width: 800px) {
  .input_search {
    width: 260px;
  }
}

@media screen and (max-width: 750px) {
  .badge {
    font-size: 7px;
    right: -20%;
    margin-left: 10px;
  }
  .badge-bag {
    color: #fff;
    font-size: 7px;
  }
  .icon_container i {
    font-size: 20px;
  }
  .icon_container {
    /* padding:0px 10px; */
    padding: 0px;
    margin: 12px;
  }

  .signup {
    flex-direction: column;
    align-items: center;
  }

  .signup_right {
    width: 90%;
  }

  .small_header {
    display: block;
  }

  .small_header2 {
    display: none;
  }
}
