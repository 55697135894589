.upload-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  height: 300px;
}
.circular-menu__button {
  display: none;
}
.circular-menu__button:checked + .circular-menu__icon {
  transform: scale(0.8, 0.8) translate3d(0, 0, 0);
  transition-duration: 0.2s;
  transition-timing-function: linear;
}
.circular-menu__button:checked
  + .circular-menu__icon
  > .hamburger-bar:nth-child(1) {
  transform: translate3d(0, 0, 0) rotate(45deg);
}
.circular-menu__button:checked
  + .circular-menu__icon
  > .hamburger-bar:nth-child(2) {
  transform: translate3d(0, 0, 0) scale(0.1, 1);
}
.circular-menu__button:checked
  + .circular-menu__icon
  > .hamburger-bar:nth-child(3) {
  transform: translate3d(0, 0, 0) rotate(-45deg);
}
.circular-menu__button:checked ~ .circular-menu__item {
  transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
}
.circular-menu__button:checked ~ .circular-menu__item:nth-of-type(1) {
  transition-duration: 0.18s;
  transform: translate3d(0px, -105px, 0);
}
.circular-menu__button:checked ~ .circular-menu__item:nth-of-type(2) {
  transition-duration: 0.28s;
  transform: translate3d(99.86093418px, -65.446784369999996px, 0);
}
.circular-menu__button:checked ~ .circular-menu__item:nth-of-type(3) {
  transition-duration: 0.38s;
  transform: translate3d(99.717451px, 75.946784px, 0);
}
.circular-menu__button:checked ~ .circular-menu__item:nth-of-type(4) {
  transition-duration: 0.48s;
  transform: translate3d(-61.71745146px, 84.94678437px, 0);
}
.circular-menu__button:checked ~ .circular-menu__item:nth-of-type(5) {
  transition-duration: 0.58s;
  transform: translate3d(-99.86093418px, -32.446784369999996px, 0);
}
.circular-menu__icon {
  background: #f26654;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  color: #fff;
  height: 60px;
  margin: 0;
  position: absolute;
  left: 40%;
  transition: transform ease-out 0.2s;
  width: 60px;
  cursor: pointer;
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  z-index: 2;
}
.circular-menu__icon:hover {
  transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}
.circular-menu__icon > .hamburger {
  background: #fff;
  display: block;
  width: 25px;
  height: 3px;
  left: 50%;
  margin-left: -12.5px;
  margin-top: -1.5px;
  position: absolute;
  top: 50%;
  transition: transform 0.2s;
}
.circular-menu__icon > .hamburger-bar:nth-child(1) {
  transform: translate3d(0, -8px, 0);
}
.circular-menu__icon > .hamburger-bar:nth-child(2) {
  transform: translate3d(0, 0, 0);
}
.circular-menu__icon > .hamburger-bar:nth-child(3) {
  transform: translate3d(0, 8px, 0);
}
.circular-menu__item {
  background: #f26654;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  color: #fff;
  height: 60px;
  line-height: 60px;
  /* margin-left: -40px; */
  position: absolute;
  left: 40%;
  text-align: center;
  /* top: 20px; */
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 0.2s;
  width: 60px;
  font-size: 32px;
}
.circular-menu__item:nth-of-type(1) {
  transition-duration: 0.18s;
}
.circular-menu__item:nth-of-type(2) {
  transition-duration: 0.18s;
}
.circular-menu__item:nth-of-type(3) {
  transition-duration: 0.18s;
}
.circular-menu__item:nth-of-type(4) {
  transition-duration: 0.18s;
}
.circular-menu__item:nth-of-type(5) {
  transition-duration: 0.18s;
}
.circular-menu__item:hover {
  color: #f26654;
  background: #f08080;
}
.circular-menu__item:active,
.circular-menu__item:focus {
  color: #f26654;
}

.hidden {
  display: none;
}

.preview-pane {
  height: 300px;
  width: 300px;
  border-radius: 5px;
  background: #eeeeee;
}

.preview-pane img {
  max-width: 250px;
  display: flex;
  margin: auto;
  border-radius: 5px;
}
.camera-close-btn {
  background: #f8361d;
  padding: 0.5rem;
  cursor: pointer;
  text-align: center;
}
camera-photo > video {
  width: 480px !important;
}

@media screen and (max-width: 640px) {
  .big-screen-upload {
    display: none;
  }
  .mobile-screen-upload {
    display: block;
  }
}

@media screen and (min-width: 640px) {
  .big-screen-upload {
    display: block;
  }
  .mobile-screen-upload {
    display: none;
  }
}
