/* .my-closet {
  font-family: inherit !important;
  margin-top: 40px;
  display: flex;
}

.my-closet .my-closet-left {
  margin: 0px 10px;
  width: 30%;
}

.my-closet .my-closet-right {
  margin-left: 20px;
  margin-right: 10px;
  width: 100%;
} */

.accordion-header {
  background: var(--peel-orange-color);
  color: var(--white-color);
  border: none !important;
}

.rc-collapse-header {
  background: var(--peel-orange-color) !important;
  color: var(--white-color) !important;
  font-size: 18px;
  font-family: "Lato" !important;
  text-transform: uppercase !important;
  border: none !important;
}

.rc-collapse-expand-icon .arrow {
  /* border-top: #fff !important; */
  /* border-color: #fff !important; */
}

.transfer_button[disabled] {
  background-color: #ddd !important;
}

@media screen and (max-width: 1020px) {
  /* .my-closet {
    flex-direction: column;
    padding: 2rem;
  }
  .my-closet .my-closet-left {
    margin: 10px 0px;
    width: 100% !important;
    margin-bottom: 40px;
  }
  .my-closet .my-closet-right {
    margin: 0px;
  }*/
}
