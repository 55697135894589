.shimmer-con {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: -1rem;
}

  /* ShimmerCard.css */
.shimmer-card {
    max-width: 250px;
    margin: 2rem 1rem;
    border-radius: 5px;
    position: relative;
    transition: all .5s ease;
  }

  .shimmer-thumbnail {
    width: 100%;
    height: 100%;
    min-height: 280px;
    max-height: 280px;
    object-fit: cover;
    margin: 0;
  }

  .shimmer-card .p-30 {
    padding: 0;
    text-align: center;
    transition: 0.5s ease;
    padding: 0rem 1rem;
  }

  .shimmer-card .m-30 {
    margin: 0 !important;
  }

  .shimmer-title {
    width: 25%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }

  .shimmer-subtitle {
    width: 15%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }