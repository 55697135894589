.confetti-container {
    transition: opacity 4s; /* 4s duration for the fade-out effect */
  }
  
  .confetti-container.hide {
    opacity: 0;
    pointer-events: none; /* Disable pointer events when hidden */
  }
  
  .confetti-container.show {
    opacity: 1;
  }