.adm-right-section {
    padding: 20px;
    margin-left: 200px;
    transition: margin 0.5s ease;
    z-index: 0;
    margin-top: 80px;
  }
  
  .adm-right-section.collapsed {
    margin-left: 0;
    /* transition: all .3s ease-in-out; */
  }

  @media screen and (max-width: 1050px) {
    .adm-right-section.collapsed{
      margin: 0;
      margin-top: 6.5rem;
    }
    .adm-right-section {
      margin-left: 0;
      padding: 10px;
    }
  }
  