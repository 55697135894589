.pricing-background {
  padding: 0 25px 25px;
  position: relative;
  width: 100%;
  background: #efefef;
}

.pricing-background::after {
  content: "";
  background: var(--peel-orange-color);
  background: -moz-linear-gradient(
    top,
    var(--peel-orange-color) 0%,
    var(--primary-color) 100%
  );
  background: -webkit-linear-gradient(
    top,
    var(--peel-orange-color) 0%,
    var(--primary-color) 100%
  );
  background: linear-gradient(
    to bottom,
    var(--peel-orange-color) 0%,
    var(--primary-color) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--peel-orange-color)', endColorstr='var(--primary-color)',GradientType=0 );
  height: 350px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.pricing-plan .header-1 {
  margin-top: 0;
}

@media (min-width: 900px) {
  .pricing-background {
    padding: 0 0 25px;
  }
}
@media screen and (max-width: 900px) {
  .pricing-table {
    max-width: 500px;
    margin: auto;
  }
  .pricing-features {
    min-height: auto;
  }
}

.container {
  margin: 0 auto;
  padding: 50px 0 0;
  max-width: 1300px;
  width: 100%;
  display: flex;
  z-index: 0;
  flex-direction: row;
  margin-bottom: 2rem;
}

.panel {
  background-color: var(--white-color);
  border-radius: 10px;
  padding: 15px 5px;
  position: relative;
  width: 100%;
  z-index: 10;
}

.pricing-table {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08),
    0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

@media (min-width: 900px) {
  .pricing-table {
    flex-direction: row;
  }
}

.pricing-table * {
  text-align: center;
  /* text-transform: uppercase; */
}

.pricing-plan {
  /* border-bottom: 1px solid var(--peel-orange-color); */
  padding: 25px;
  margin: 10px 20px;
  /* outline: 5px solid transparent; */
  background: #fff;
  z-index: 100;
  border-radius: 10px;
}

.featured {
  outline: 5px solid var(--peel-orange-color);
  border-radius: 10px;
  /* border: 3px solid #aaa; */
}

.featured small {
  background: var(--primary-color);
  padding: 0.5rem 1rem;
  color: var(--white-color);
  border-radius: 5px;
}

.pricing-plan:last-child {
  border-bottom: none;
}

@media (min-width: 900px) {
  .pricing-plan {
    border-bottom: none;
    flex-basis: 100%;
  }

  .pricing-plan:last-child {
    border-right: none;
  }

  .pricing-plan .header-1 {
    margin-top: 3.1rem !important;
  }
  .pricing-features-item {
  }
}

.pricing-img {
  margin-bottom: 25px;
  max-width: 100%;
}

.pricing-header {
  color: var(--grey-color);
  font-weight: 900;
  letter-spacing: 1px;
}

.pricing-features {
  color: var(--peel-orange-color);
  /* font-weight: 600; */
  letter-spacing: 1px;
  margin: 50px 0 25px;
  display: flex;
  flex-direction: column;
  min-height: 350px;
}

.pricing-features-item {
  border-top: 1px solid var(--peel-orange-color);
  font-size: 14px;
  line-height: 1.5;
  padding: 15px 20px;
  font-weight: 400 !important;
}

/* .pricing-features-item:last-child {
  border-bottom: 1px solid var(--peel-orange-color);
} */

.pricing-price {
  color: var(--peel-orange-color);
  display: block;
  font-size: 28px;
  font-weight: 900;
}

.pricing-price .small-text {
  margin: 1rem auto;
  text-transform: uppercase;
  color: var(--grey-color);
  font-size: 18px;
}

.pricing-button {
  font-family: inherit;
  border: 1px solid var(--peel-orange-color);
  border-radius: 10px;
  color: var(--peel-orange-color);
  display: inline-block;
  margin: 25px 0;
  padding: 15px 35px;
  text-decoration: none;
  transition: all 150ms ease-in-out;
  cursor: pointer;
  background: var(--white-color);
}

.pricing-button:hover,
.pricing-button:focus {
  background-color: var(--peel-orange-color);
  color: var(--white-color);
}

.pricing-button.is-featured {
  background-color: var(--peel-orange-color);
  color: var(--white-color);
  border: 1px solid var(--peel-orange-color);
}

.pricing-button.is-featured:hover,
.pricing-button.is-featured:active {
  background-color: var(--primary-color);
}

.pricing-features {
  list-style: none !important;
  padding: 0;
  /* min-height: 25vh; */
  margin: 2rem auto;
}

@media screen and (max-width: 900px) {
  .container {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
  .pricing-plan {
    width: 80%;
  }
}

@media screen and (max-width: 640px) {
  .pricing-plan {
    width: 100%;
  }
}
