.my-closet-item-container .title {
  font-family: "LATO";
  color: #506385;
  font-weight: bold;
  /* font-size: 36px; */
  margin-bottom: 10px;
}

.sortCategory {
  text-align: right;
  margin: 50px;
  margin-top: -50px !important;
  padding-right: 50px;
}

@media screen and (max-width: 700px) {
  .sortCategory {
    text-align: left;
    margin: 50px;
    margin-top: 50px !important;
    padding-right: 50px;
  }
}

.hiddenSeparation {
  visibility: hidden;
}

@media screen and (max-width: 700px) {
  .hiddenSeparation {
    visibility: visible;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

/* .flex-parent-element {
    display: flex;
    width: 50%;
}
  
.flex-child-element {
    flex: 1;
    margin: 10px;
    padding: 30px;
}
  
.flex-child-element:first-child {
    float: right !important;
    margin-right: -9px;
}

@media screen and (max-width: 700px) {
    .flex-parent-element {
        display: block;
        width: auto;
    }

    .flex-child-element {
        flex: 1;
        margin: 15px;
        padding: 15px;
    }
      
    .flex-child-element:first-child {
        margin-right: 90px;
    }    
} */

.my-closet-item-container .secondary-title {
  font-family: "LATO";
  color: #506385;
  font-weight: lighter;
  font-size: 16px;
  margin-bottom: 30px;
}

.my-closet-item-container .closet-card-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.my-closet-item-container .card {
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  width: 20%;
  box-shadow: 0px 0px 18px rgb(0 0 0 / 10%);
}

.card .card_image {
  background: #f6f7fb;
  padding-bottom: 5px;
}

.card .card_image img {
  height: 150px;
  display: block;
  padding-top: 10px;
  margin: auto;
}

.card .card_text {
  text-align: center;
  transition: 0.5s ease;
}

.card:hover .card_text,
.card:hover .card_text .product_title,
.card:hover .card_text .product_code,
.my-closet-item-container .card:hover .card_text .product_price {
  color: #fff;
}

.card:hover .card_text {
  background-color: #f26654;
}

.card .card_text .product_title {
  color: #f26654;
  font-family: "LATO";
  font-weight: bold;
  font-size: 18px;
  margin: 0px 0px 20px 0px;
  padding-top: 8px;
  transition: 0.5s ease;
}

.sortCategoryDropdown {
  margin: 25px 0px;
  font-family: "LATO";
  color: #000;
  position: relative;
  font-size: 18px;
  text-decoration: none;
}

.sortCategoryDropdown::before {
  content: "";
  height: 2px;
  background: #f26654;
  width: 0%;
  position: absolute;
  bottom: 0%;
  transition: 0.5s ease;
}

.sortCategoryDropdown:hover::before {
  width: 100%;
}

.sortCategoryDropdown:hover {
  color: #f26654;
}

.admin-menu-container .menu_container div {
  padding: 10px 0px;
}

.my-closet-item-container .card .card_text .product_code {
  color: #151875;
  font-family: "Josefin Sans", sans-serif;
  font-weight: bold;
  font-size: 12px;
  margin: 0px;
  transition: 0.5s ease;
}

.my-closet-item-container .card .card_text .product_price {
  color: #151875;
  font-family: "LATO";
  font-size: 18px;
  padding-bottom: 8px;
  transition: 0.5s ease;
  margin: 0px;
}

.card .card_text .product_price img {
  transition: 0.5s ease;
}

.card:hover .card_text .product_price img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(98%) saturate(2%)
    hue-rotate(63deg) brightness(109%) contrast(101%);
}

.card .card_text .product_colors {
  display: flex;
  justify-content: center;
  margin: 12px 0px;
}

.card .card_text .product_colors .product_color_box {
  height: 4px;
  width: 10px;
  margin: 0px 2px;
}

.my-closet-item-container .card .action_icon {
  display: flex;
  margin-left: 5px;
  justify-content: center;
  padding-top: 10px;
  margin-bottom: 10px;
  opacity: 0;
  transition: 0.5s ease;
}

.card .card_image button {
  opacity: 0;
  transition: 0.5s ease;
}

.card:hover .card_image .action_icon,
.card:hover .card_image button {
  opacity: 1;
}

.action_icon div {
  margin: 0px 5px;
  cursor: pointer;
  transition: 0.5s ease;
  padding: 5px 8px;
}

.action_icon div i {
  font-size: 14px;
}

@media screen and (max-width: 900px) {
  .my-closet-item-container .card {
    width: 32%;
  }
}

@media screen and (max-width: 700px) {
  .my-closet-item-container .card {
    width: 50%;
  }
}

@media screen and (max-width: 700px) {
  .my-closet-item-container .card {
    width: 90%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
  }
}
