.alert {
  padding: 10px 50px;
  background-color: var(--primary-color);
  color: white;
  opacity: 1;
  transition: opacity 0.6s;
  text-transform: capitalize;
  -webkit-text-stroke: #fff;
  -webkit-text-stroke-width: 1px;
  text-shadow: 1px 1px 5px var(--white-color);
  transition: all 0.5s ease-in-out;
  text-align: center;
  letter-spacing: 0.2em;
  /* -webkit-animation: flicker 1.5s ease-in-out infinite alternate;
            animation: flicker 1.5s ease-in-out infinite alternate; */
}

.alert.success {
  background-color: #04aa6d;
}
.alert.info {
  background-color: #2196f3;
}
.alert.warning {
  background-color: #ff9800;
}

.share-section{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 1rem;
}

.share-section button{
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}


.alertCloseBtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.alertCloseBtn:hover {
  color: #ddd;
}

@-webkit-keyframes flicker {
  0% {
    opacity: 0.5;
    text-shadow: 2px 2px 10px var(--peel-orange-color);
  }
  100% {
    opacity: 1;
    text-shadow: 2px 2px 20px var(--peel-orange-color);
  }
}

@keyframes flicker {
  0% {
    opacity: 0.5;
    text-shadow: 2px 2px 10px var(--peel-orange-color);
  }
  100% {
    opacity: 1;
    text-shadow: 2px 2px 20px var(--peel-orange-color);
  }
}

@media screen and (max-width: 650px) {
  .alert {
    padding: 10px;
  }
}

.wrapper {
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #f26654ba, 5%, #f47f72, 50%, #f26654);
  /* position: absolute; */
}
.wrapper h1 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-family: sans-serif;
  letter-spacing: 1px;
  word-spacing: 2px;
  color: #fff;
  font-size: 40px;
  font-weight: 888;
  text-transform: uppercase;
}
.wrapper div {
  height: 60px;
  width: 60px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 50px;
  position: absolute;
  top: 10%;
  left: 10%;
  animation: 4s linear infinite;
}
div .dot {
  height: 10px;
  width: 10px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 20%;
  right: 20%;
}
.wrapper div:nth-child(1) {
  top: 20%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.wrapper div:nth-child(2) {
  top: 60%;
  left: 80%;
  animation: animate 10s linear infinite;
}
.wrapper div:nth-child(3) {
  top: 40%;
  left: 40%;
  animation: animate 3s linear infinite;
}
.wrapper div:nth-child(4) {
  top: 66%;
  left: 30%;
  animation: animate 7s linear infinite;
}
.wrapper div:nth-child(5) {
  top: 90%;
  left: 10%;
  animation: animate 9s linear infinite;
}
.wrapper div:nth-child(6) {
  top: 30%;
  left: 60%;
  animation: animate 5s linear infinite;
}
.wrapper div:nth-child(7) {
  top: 70%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.wrapper div:nth-child(8) {
  top: 75%;
  left: 60%;
  animation: animate 10s linear infinite;
}
.wrapper div:nth-child(9) {
  top: 50%;
  left: 50%;
  animation: animate 6s linear infinite;
}
.wrapper div:nth-child(10) {
  top: 45%;
  left: 20%;
  animation: animate 10s linear infinite;
}
.wrapper div:nth-child(11) {
  top: 10%;
  left: 90%;
  animation: animate 9s linear infinite;
}
.wrapper div:nth-child(12) {
  top: 20%;
  left: 70%;
  animation: animate 7s linear infinite;
}
.wrapper div:nth-child(13) {
  top: 20%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.wrapper div:nth-child(14) {
  top: 60%;
  left: 5%;
  animation: animate 6s linear infinite;
}
.wrapper div:nth-child(15) {
  top: 90%;
  left: 80%;
  animation: animate 9s linear infinite;
}
@keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(70deg);
  }
  100% {
    transform: scale(1.3) translateY(-100px) rotate(360deg);
  }
}
