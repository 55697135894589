.users {
  padding: 20px;
  background-color: var(--white-color);
  transition: all 0.5s ease;
  overflow-x: auto;
}

.users h2 {
  font-size: 22px;
  margin-bottom: 20px;
  color: var(--background-color);
  padding: 0px 20px;
}

.users table {
  width: 100%;
  border-collapse: collapse;
  transition: all 0.5s ease;
  overflow-x: auto;
}

.users th,
.users td {
  padding: 10px;
  border: 1px solid var(--grey-color);
}

.users th {
  background-color: var(--white-color);
  color: var(--secondary-color);
}

.users td {
  text-align: center;
}

.users button {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
  border: none;
  background-color: var(--primary-color);
  color: var(--white-color);
  cursor: pointer;
}

.users button:last-child {
  margin-right: 0;
}

.users .pagination-con .pagination {
  margin: 2rem auto !important;
}

.users-table {
  transition: width 0.5s ease;
}

.users-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
}

.users-header .input_container .search_closet {
  width: 60% !important;
  display: flex;
  justify-content: flex-end;
}

.users-header .input_container_wrapper {
  display: flex;
  margin: auto;
  margin-right: 0;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
}

.users-table thead tr th {
  font-size: 14px !important;
}

.css-2613qy-menu {
  z-index: 1111 !important;
}

.users-header .input_container.search_closet {
  margin-top: 0 !important;
}

.users-header .input_container input {
  margin-top: 0 !important;
  border-radius: 5px 0 0 5px;
  padding: 12px 6px;
}
.users-header .input_container input:focus,
.users-header .input_container select:focus {
  border-radius: 5px 0 0 5px !important;
}

.users-header .other-users-header-flex {
  display: flex;
  align-items: center;
  width: 100%;
}

/* checkbox */
.checkbox-wrapper-4 * {
  box-sizing: border-box;
}
.checkbox-wrapper-4 .cbx {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
  display: inline-block;
}
.checkbox-wrapper-4 .cbx:not(:last-child) {
  margin-right: 6px;
}
.checkbox-wrapper-4 .cbx:hover {
  background: rgba(0, 119, 255, 0.06);
}
.checkbox-wrapper-4 .cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-4 .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  transform: scale(1);
  border: 1px solid #cccfdb;
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
}
.checkbox-wrapper-4 .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-4 .cbx span:last-child {
  padding-left: 8px;
  line-height: 18px;
}
.checkbox-wrapper-4 .cbx:hover span:first-child {
  border-color: var(--primary-color);
}
.checkbox-wrapper-4 .inp-cbx {
  position: absolute;
  visibility: hidden;
}
.checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child {
  background: var(--primary-color);
  border-color: var(--primary-color);
  animation: wave-4 0.4s ease;
}
.checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.checkbox-wrapper-4 .inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}


.checkbox-grp-selectBoxGroup {
  display: flex;
  /* flex-direction: column; */
  gap: 1rem;
}

.checkbox-grp-selectBox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

input[type="radio"],
input[type="checkbox"] {
  margin-right: 0.5rem;
  cursor: pointer;
}

.checkbox-grp-selectBox label {
  cursor: pointer;
  font-size: 1rem;
  border-radius: 3px;
}

/* Spacing utilities */
.checkbox-grp-mb-3 {
  margin-bottom: 1rem;
}

.checkbox-grp-mt-5 {
  margin-top: 2rem;
}

.checkbox-grp-selectBoxGroup input[type="radio"],
.checkbox-grp-selectBoxGroup input[type="checkbox"] {
  width: 16px;
  height: 16px;
}

.checkbox-grp-selectBoxGroup label {
  font-size: 1rem;
  line-height: 1.5;
}

.checkbox-grp-text-right {
  text-align: right;
}

/* Spacing utilities */
.checkbox-grp-mb-3 {
  margin-bottom: 1rem;
}

.checkbox-grp-mt-5 {
  margin-top: 2rem;
}

.checkbox-grp-selectBox input {
  display: none;
}

.checkbox-grp-selectBox input:checked + label {
  border-color: var(--primary-color);
  z-index: 0;
  /* box-shadow: 0 0 0 3px rgba(200, 57, 0, 0.1); */
}

.checkbox-grp-selectBox input:checked + label:before {
  background: var(--primary-color);
  box-shadow: 0 0 0 1px var(--primary-color);
}

.checkbox-grp-selectBox label {
  border: 1px solid #f1f1f1;
  position: relative;
  padding: 15px 15px 15px 40px;
  cursor: pointer;
  font-weight: bold;
}

.checkbox-grp-selectBox label:before {
  content: "";
  width: 15px;
  height: 15px;
  box-shadow: 0 0 0 1px #ccc;
  border: 3px solid #fff;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.checkbox-grp-selectBox label:hover {
  border-color: #999;
  z-index: 1;
}

.checkbox-grp-selectBox.checkbox-grp-radio label:before {
  border-radius: 100%;
}

.checkbox-grp-selectBoxGroup {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-grp-selectBoxGroup .checkbox-grp-selectBox {
  margin-left: -1px;
}

.checkbox-grp-selectBoxGroup .checkbox-grp-selectBox:first-child {
  margin-left: 0;
}

@media screen and (max-width: 568px) {
  .checkbox-grp-selectBoxGroup {
    flex-direction: column;
  }

  .checkbox-grp-selectBoxGroup .checkbox-grp-selectBox {
    margin-left: 0;
  }

  .checkbox-grp-selectBoxGroup .checkbox-grp-selectBox label {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .checkbox-wrapper-4 .cbx {
    width: 100%;
    display: inline-block;
  }
}
@-moz-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@-o-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}

@media only screen and (max-width: 1050px) {
  .users.table {
    width: min-content;
  }
}

@media only screen and (max-width: 767px) {
  .users-table {
    /* display: block; */
    overflow-x: auto;
    /* white-space: nowrap; */
  }

  .users table {
    display: block;
  }

  .users-table thead {
    display: none;
  }

  .users-table tbody {
    display: block;
    word-wrap: break-word;
  }

  .users-table tbody tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--grey-color);
  }

  .users-table tbody td {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border: none;
    font-size: 16px;
  }

  .users-table tbody td:before {
    content: attr(data-label);
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    text-align: center;
  }

  .users-table tbody td:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .users button {
    width: 50%;
  }
  .users button:last-child {
    margin: auto;
  }

  .users-table tbody td button {
    margin: auto;
    margin-bottom: 0.5rem;
    width: 100%;
    max-width: 150px;
  }

  .users h2 {
    margin: auto;
    text-align: center;
  }
  .users {
    padding: 0;
  }
  .users-header {
    padding: 0;
    flex-direction: column;
  }
  .users-header .input_container .search_closet {
    width: 100% !important;
  }
  .users-header .input_container .search_closet div {
    width: 100% !important;
    margin: auto;
  }
  .users-header .input_container_wrapper {
    margin: auto !important;
    width: 100%;
  }
  .users-header .heading-title {
    margin: 1rem auto;
    font-weight: 700;
  }

  .user-submit-details-btn {
    width: 100% !important;
  }
}
