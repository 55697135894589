.gen-home-container {
  margin: 0 auto;
  padding: 20px 40px;
}

.gen-home-container section {
  padding: 0;
}

.gen-home-container .sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
}

.gen-home-container .sec .text-content {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 750px;
}

.gen-home-container .sec .video-wrapper {
  width: 30%;
  /* height: 80%;
  max-height: 600px; */
}

.gen-home-container .sec .video-wrapper video {
  width: 100%;
  /* height: 80%; */
  /* aspect-ratio: 3/4; */
}

.gen-home-container .sec .right.video-wrapper {
  text-align: right;
}

.gen-home-container .sec .left.video-wrapper {
  text-align: left;
}

@media screen and (max-width: 767px) {
  .gen-home-container .sec:first-child {
    flex-direction: column-reverse;
  }

  .gen-home-container .sec:first-child .text-content {
    width: 100%;
  }

  .gen-home-container .sec:first-child .video-wrapper {
    width: 100%;
    text-align: center;
  }

  .gen-home-container .sec .text-content h2 {
    font-size: 18px;
  }

  .gen-home-container .sec .text-content h3 {
    font-size: 16px;
  }

  .gen-home-container .sec .text-content {
    width: 60%;
  }
  .gen-home-container .sec .video-wrapper {
    width: 40%;
  }
  .gen-home-container .sec .video-wrapper video {
    width: 90%;
  }

  .gen-home-container .sec:nth-child(9) {
    flex-direction: column;
  }

  .gen-home-container .sec:nth-child(9) .text-content,
  .gen-home-container .sec:nth-child(9) .video-wrapper {
    width: 100%;
    text-align: center;
  }
}
