.latest_product_container{
    margin:auto;
    margin-top:20px;
    width: 90%;
}

.latest_product_container .card{
    width: 30% !important;
    height: auto !important;
    position: unset !important;
    margin: 0px 5px 10px 5px;
    cursor: pointer;
    transition: 0.5s ease;
    transform: none !important;
    
}

.latest_product_container .card .img_container{
    background-color: #f7f7f7;
    padding:10px 0px;
    position:relative;
}

.latest_product_container .card .img_container .action_icon{
    position:absolute;
    bottom:20%;
    opacity:0;
    transition:0.5s ease;
}

.latest_product_container .card .img_container img{
    height: 150px;
    display:block;
    margin: auto;
}

.latest_product_container .card .text_container{
    padding:10px 0px 3px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.latest_product_container .card .text_container .product_title{
    color:#151875;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
    font-size:14px;
    border-bottom:2px solid #EEEFFB;
}

.latest_product_container .card .text_container .price_container{
    display:flex;
    align-items: baseline;
}

.latest_product_container .card .text_container .price_container .product_price{
    color:#151875;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
    font-size:12px;
    
}

.latest_product_container .card .text_container .price_container .canceled_price{
    color:#F26654;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
    font-size:10px;
    text-decoration: line-through;
    padding-left: 2px;
}

.latest_product_container .card:hover .img_container .action_icon{
    opacity:1;
}

.latest_product_container .card:hover{
    box-shadow: 0px 0px 25px rgb(0 0 0 / 10%);
}
.styles_isotope-container__3X0JH{
    text-align:center;
    display:flex;
    justify-content: center;
    flex-wrap:wrap;
}

.product_tabs{
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.product_tabs input{
    width: 0;
    height: 0;
}

.product_tabs label{
    display:inline-block;
    padding-bottom: 2px;
    margin:10px;
    font-family: 'LATO';
    border-bottom: 2px solid transparent;
}

.product_tabs input:checked + label{
    color: #F26654;
    border-color: #F26654;
}



.product_tabs label:hover{
    color: #F26654;
    cursor: pointer;
}

@media screen and (max-width: 750px) {
    .latest_product_container .card{
        width:40% !important;
    }
}

@media screen and (max-width: 550px) {
    .product_tabs label{
        font-size:13px
    }
    .latest_product_container .card{
        width:100% !important;
    }
}