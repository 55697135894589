.category-width {
    width: 50%;
  }

.action-width{
    width: 10%;
}
  
  @media screen and(max-width: 767px) {
    .category-width {
      width: 100%;
    }
    .add-new-brand-btn-con {
      margin: 1rem auto;
    }
    .action-width{
        width: 100%;
    }
  }