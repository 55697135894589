.closest_setup_container {
  /* margin-top: 40px; */
}

.closest_setup {
  width: 60%;
  margin: auto;
}

.closest_setup form {
  margin-top: 20px;
}

.closest_setup form .input_container,
.closest_setup form .select_container {
  margin-top: 8px !important;
}

.closest_setup form .input_container input,
.closest_setup form .select_container select {
  margin-top: 5px !important;
}

.closest_setup form .personal_info_container,
.closest_setup form .img_upload_container {
  display: flex;
  justify-content: space-between;
}

.closest_setup form .personal_info_container > div:not(:first-child),
.closest_setup form .img_upload_container > div:not(:first-child) {
  margin-left: 5px;
}

.closest_setup form .input_container,
.closest_setup form .select_container,
.closest_setup form .text_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
}

.closest_setup form .input_container label,
.closest_setup form .select_container label,
.closest_setup form .text_container label,
.closest_setup form .img_upload_container .file_icon_container div {
  font-family: "LATO";
  font-size: 14px;
  font-weight: 700;
  display: block;
  margin-bottom: 2px;
}

.closest_setup form .img_upload_container .file_icon_container {
  width: 100%;
}

.closest_setup form .img_upload_container .file_icon_container label {
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.5);
  height: 80px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  display: block;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closest_setup form .input_container input,
.closest_setup form .select_container select,
.closest_setup form .text_container textarea {
  padding: 8px;
  font-size: 14px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.closest_setup form .select_container select {
  padding: 7px;
}

.select_container .css-1s2u09g-control {
  border-radius: 5px;
  min-height: 35px;
  font-size: 14px !important;
  font-family: "LATO";
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.css-1pahdxg-control {
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
  font-size: 14px !important;
  font-family: "LATO";
}
.css-1pahdxg-control:hover {
  border-radius: 5px !important;
}
.closest_setup form .input_container input:focus,
.closest_setup form .select_container select:focus,
.closest_setup form .text_container textarea:focus {
  border-color: #f26654;
}

.closest_setup form button {
  width: 100%;
  margin-top: 20px;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
}

.text-danger {
  color: red;
}

.img_name_cont {
  text-align: center;
  color: #f26654;
}

.img_name_cont i {
  font-size: 20px;
}

@media screen and (max-width: 750px) {
  .closest_setup form .personal_info_container,
  .closest_setup form .img_upload_container {
    flex-direction: column;
    align-items: center;
  }
  .closest_setup form .personal_info_container > div:not(:first-child),
  .closest_setup form .img_upload_container > div:not(:first-child) {
    margin-left: 0px;
    margin-top: 10px;
  }
}

.setup-complete-button {
  padding: 20px;
  margin: 10px;
  width: 340px;
  text-transform: uppercase;
  font-weight: bold;
}

.similarClosets {
  padding-top: 0 !important;
  padding-bottom: 60px;
  margin: 0 auto;
  /* width: 100vw;
      padding-left: 20vw;
      padding-right: 20vw; */
  text-align: center !important;
  display: table;
}

.UsersContainer {
  /* position: relative; */
  display: table-cell;
  width: 110px;
  height: auto;
  text-align: center;
  padding-bottom: 30px;
  padding-top: 30px;
}

@media screen and (max-width: 750px) {
  /* .similarClosets{
          width: 80%;
          margin-left: 10vw;
          margin-right: 10vw;
      } */

  .UsersContainerHidden {
    visibility: hidden;
    display: none;
  }
}

.closetUser {
  width: 60px;
  height: 60px;
  border-radius: 100px;
}

.addFriend {
  width: 24px;
  height: 24px;
  background-color: #51a7e6;
  /* text-decoration: none !important; */
  /* box-shadow: none !important; */
  border-width: 0px !important;
  border-radius: 30px;
  /* border-color: #51a7e6; */
  color: aliceblue;
  cursor: pointer;
  /* position: relative; */
  /* padding: auto; */
}

.addFriendContainer {
  /* position: relative; */
  margin-left: 60px;
  margin-top: -60px;
}
