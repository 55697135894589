.slider_container {
  margin-top: 0px;
  height: 100%;
  background: #ffd5cf;
}

.slide_container {
  background-color: #ffd5cf;
  height: 450px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: auto;
}

.slide_img_container {
  position: relative;
  margin-right: 10px;
  /* height:100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slide_img_container .slider_img {
  position: absolute;
  right: 2%;
  bottom: 0%;
  width: 100% !important;
}

.slide_img_container .slider_overlay {
  width: 400px !important;
}

.slide_text_container {
  text-align: left;
  max-width: 600px;
  /* margin-left: 50px; */
  font-family: "Lato";
}

.slide_pre_text {
  color: #f26654;
}

.slide_main_text {
  color: #506385;
  font-size: 40px;
  margin: 5px 0px;
  text-align: left;
}

.slide_sub_text {
  color: #506385;
  text-transform: capitalize;
  font-size: 22px;
  margin-bottom: 5px;
}

.shop-btn {
  padding: 10px;
  padding-left: 0;
  padding-right: 1rem;
  width: fit-content;
  margin-top: 10px;
}

.shop-btn button {
  background-color: #f26654;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.shop-btn button:hover {
  box-shadow: none;
}

.shop-btn button:active {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.enlarge-img {
  width: 550px !important;
  right: -4rem;
}

.slide-btn-con {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
}

.single-btn {
  display: none;
}

@media screen and (max-width: 1000px) {
  .enlarge-img {
    width: 100% !important;
    right: 0;
  }
}

@media screen and (max-width: 880px) {
  .slide-btn-con {
    display: none;
  }
  .single-btn {
    display: flex;
    justify-content: flex-start;
  }
  .single-btn div {
    margin: 0;
    /* border: 2px solid var(--white-color); */
  }
  .single-btn .shop-btn {
    padding: 0 !important;
    font-size: 12px;
    border: 1px solid var(--white-color);
  }
  .single-btn .shop-btn .btn {
    padding: 0.8rem 0.4rem;
    font-size: 10px !important;
    border: 1px solid var(--white-color);
  }
}

@media screen and (max-width: 750px) {
  .slide_pre_text {
    font-size: 12px;
  }

  .slide_main_text {
    font-size: 16px;
  }

  .slide_sub_text {
    font-size: 12px;
  }
  .slide_container {
    height: 300px;
  }
  .slide_img_container .slider_img {
    width: 150px !important;
    right: -5%;
  }
  .slide_img_container .slider_overlay {
    width: 150px !important;
  }
  .slide_text_container {
    margin-left: 20px;
  }
}
