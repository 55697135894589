.latest_events_container{
    margin:60px 0px;
}

.latest_events_container .events_container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
    width:90%;
    margin: auto;
}

.latest_events_container .events_container .event_card{
    box-shadow: 0px 8px 40px rgb(49 32 138 / 5%);
    width: 25%;
}

.latest_events_container .events_container .event_card img{
    width: 100%;
    border-radius: 7px;
}

.latest_events_container .events_container .event_card .text_container{
    padding: 10px 10px 0px 10px;
}

.latest_events_container .events_container .event_card .text_container .meta_info{
    display: flex;
    align-items: center;
}

.latest_events_container .events_container .event_card .text_container .meta_info{
    color:#151875;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
    font-size:14px;
}

.latest_events_container .events_container .event_card .text_container .meta_info .date{
    margin-left: 15px;
}

.latest_events_container .events_container .event_card .text_container .meta_info i{
    color:#F26654;
}

.latest_events_container .events_container .event_card .text_container .title{
    color:#151875;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
    font-size:18px;
    padding:20px 0px 5px 0px;
    transition: 0.5s ease;
}

.latest_events_container .events_container .event_card:hover .text_container .title{
    color:#F26654;
}

.latest_events_container .events_container .event_card .text_container .content{
    color:#72718F;
    font-family: 'LATO', sans-serif;
    font-size:16px;
    margin:5px 0px;
}

.latest_events_container .events_container .event_card .text_container a{
    color:#151875;
    font-family: 'LATO', sans-serif;
    font-size:14px;
    padding:10px 0px;
    display: block;
    width:fit-content;
}

.latest_events_container .events_container .event_card .text_container a:hover{
    color:#F26654;
}

@media screen and (max-width: 750px) {
    
    .latest_events_container .events_container .event_card{
        width: 40%;
        margin: 10px 0px;
    }
}

@media screen and (max-width: 550px) {
    .latest_events_container .events_container .event_card{
        width: 100%;
        margin: 10px 0px;
    }
}
