.checkout{
    margin-top: 40px;
    padding:0px 30px;
}

.checkout .title{
    font-family: 'LATO';
    color:#506385;
    font-weight:bold;
    font-size: 36px;
    text-align: center;
    margin: 10px 0px;
}

.checkout .container{
    display: flex;
    justify-content: space-between;
    max-width: 100%;
}

.checkout .checkout-left{
    width: 65%;
}

.checkout .checkout-right{
    width: 30%;
}

.no_checkout{
    font-family: 'LATO';
    color:#a9a9a9;
    font-weight:bold;
    font-size: 36px;
    text-align: center;
}

.hanger-short-warning{
    max-width: 100%;
    width: 100%;
    margin: auto;
    margin-top: 40px;
    background-color: #F26654;
    padding: 8px;
    color: white;
    font-family: 'LATO';
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hanger-short-warning > div{
    margin: 5px 0px;
}

.hanger-short-warning ul li{
    list-style-type: disc;
}

.hanger-short-warning .hanger-short-warning-text{
    display: flex;
    justify-content: flex-start;
    gap:20px;
}




@media screen and (max-width: 750px) {
    .checkout .container{
        flex-direction: column;
    }
    
    .checkout .checkout-left{
        width: 100%;
    }
    
    .checkout .checkout-right{
        width: 100%;
        margin-top: 25px;
    }
    .checkout .title{
        margin-top: 30px;
    }
}