.featured_products_container{
    margin: 6rem auto;
    width: 90%;;
    /* max-height: 500px; */
}

.featured_products_container .featured_card{
    margin:30px 20px;
    cursor: pointer;
    box-shadow: 0px 0px 18px rgb(0 0 0 / 10%);
    min-height: 360px;
}

.featured_card .card_image{
    background: #F6F7FB;
    padding-bottom: 5px;
}

.featured_card .card_image img{
    height: 150px;
    display:block;
    padding-top:10px;
    margin:auto;
}

.featured_card .card_text{
    text-align: center;
    transition: 0.5s ease;
    max-height: fit-content;
    min-height: fit-content;
    height: 150px;
}

.featured_card:hover .card_text .product_title, .card:hover .card_text .product_code, .card:hover .card_text .product_price{
    color:#fff;
}

.featured_card:hover {
    /* background: #F26654; */
}

.featured_card:hover .card_text{
    background-color:#F26654;
    display: block;
}

.featured_card .card_text .product_title{
    color:#F26654;
    font-family: 'LATO';
    font-size:18px;
    margin:0px 0px 20px 0px;
    padding-top:8px;
    transition: 0.5s ease;
}

.featured_card .card_text .product_code{
    color:#151875;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
    margin:8px 0px;
    font-size:12px;
    transition: 0.5s ease;
}

.featured_card .card_text .product_price{
    color:#151875;
    font-family: 'LATO';
    margin:8px 0px;
    margin-bottom: 20px;
    font-size:18px;
    padding-bottom: 8px;
    transition: 0.5s ease;
}

.featured_card:hover .card_text .product_price{
    color: var(--white-color);
}

.featured_card .card_text .product_price img{
    transition: 0.5s ease;
}

.featured_card:hover .card_text .product_price img{
    filter: brightness(0) saturate(100%) invert(100%) sepia(98%) saturate(2%) hue-rotate(63deg) brightness(109%) contrast(101%);
}

.featured_card .card_text .product_colors{
    display: flex;
    justify-content: center;
    margin:12px 0px;
}

.card .card_text .product_colors .product_color_box{
    height:4px;
    width:10px;
    margin:0px 2px;
}

.featured_products_container .featured_card .action_icon{
    display:flex;
    margin-left:5px;
    justify-content: center;
    padding-top:10px;
    margin-bottom: 10px;
    opacity: 0;
    transition: 0.5s ease;
}

.featured_card .card_image button{
    opacity: 0;
    transition: 0.5s ease;
}

.featured_card:hover .card_image .action_icon, .featured_card:hover .card_image button{
    opacity: 1;
}

.action_icon div{
    margin:0px 5px;
    cursor: pointer;
    transition: 0.5s ease;
    padding:5px 8px;
}

.action_icon div i{
    font-size:14px;
}

.pagination_container{
    position: relative !important;
    margin-top: 20px !important;
}

.page_container{
    border-radius: 2px !important;
    width: 18px !important;
    height: 4px !important;
    background-color: #f26654a8 !important;
}

.page_container.is-active{
    background-color: #f26654 !important;
}

.featured_products_container ul{
    padding:0px;
}

@media screen and (max-width: 640px){
    .splide__pagination pagination_container{
        display: none;
    }
}