.ck-voice-label{
    display: none !important;
}

.ck-editor__main .ck-content .table table {
    border: none;
}

.ck-editor__main .ck-content .table table td, .ck-editor__main .ck-content .table table th {
    border: none;
}
