.add-new-brand-btn-con {
  text-align: right;
  margin-bottom: 1rem;
}

@media screen and (max-width: 700px) {
  .add-new-brand-btn-con {
    text-align: center;
    width: 100%;
    margin-top: 1rem;
  }
  .add-new-brand-btn-con .btn {
    width: 100%;
  }
}
