.general_slider_container {
    /* display: flex;
    justify-content: center;
    align-items: center; */
}
.gen_slide_container {
    display: flex;
    height: 80vh;
    margin: 1% 8%;
    background: var(--peel-orange-color);
    position: relative;
}
.gen_slide_text_container {
    position: absolute;
    width: 40%;
    text-align: left;
    top: 30%;
    left: -5%;
    color: var(--black-color);
    z-index: 1;
}
.gen_slide_text_container h2 {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.3;
    text-align: left;
}
.gen_slide_text_container .explore-btn button {
    background: transparent;
    border: none;
    border-bottom: 2px dashed var(--dark-grey-color);
    color: var(--dark-grey-color);
    font-size: 18px;
    text-align: left !important;
    padding: .5rem 0rem;
    margin: 0rem .5rem;
    font-weight: 900;
}
.gen_slide_img_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 60%;
    box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.2);
}

.gen_slide_img_container img {
    opacity: 0.65;
}

.gen_slide_container:hover::after {
    transition: all .3s ease-in-out;
}

.gen_slide_container:hover .gen_slide_img_container img {
    transition: all.3s ease-in-out;
    opacity: 0.8;
}

.gen_slide_container:hover .gen_slide_text_container {
    color: var(--dark-grey-color);
}
.gen_slide_text_container .explore-btn button:hover {
    color: var(--dark-grey-color);
    border-bottom-color: var(--dark-grey-color);
    transition: all .3s ease-in-out;
    cursor: pointer;
}
.control-dots {
    text-align: right !important;
    margin: 10px -30px !important;
}
.control-dots li {
    border: 1px solid var(--peel-orange-color);
}



@media screen and (max-width: 787px) {
    .gen_slide_container {
        height: 65vh;
    }
    .gen_slide_text_container h2 {
        font-size: 24px;
    }
    .gen_slide_img_container {
        width: 75%;
    }

}

@media screen and (max-width: 650px) {
    .gen_slide_text_container h2 {
        font-size: 20px;
    }
    .gen_slide_text_container {
        width: 50%;
    }
    .gen_slide_text_container .explore-btn button {
        font-size: 14px;
    }
    .gen_slide_img_container {
        width: 90%;
    }
}