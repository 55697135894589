.closest_offer_container{
    width:90%;
    margin:auto;
    padding: 20px 0px;
}

.closest_offer_container .offer_card_container{
    display:flex;
    margin-top: 20px;
    justify-content:space-evenly;
    flex-wrap: wrap;
}

.closest_offer_container .offer_card{
    box-shadow: 0px 8px 40px rgb(49 32 138 / 5%);
    width: 20%;
    display:flex;
    flex-direction: column;
    align-items: center;
    padding:20px 40px;
}

.closest_offer_container .offer_card div{
    padding:10px 0px;
}

.closest_offer_container .offer_card .offer_title{
    color:#151875;
    font-weight: bold;
    font-family: 'Josefin Sans', sans-serif;
    font-size:18px;
    text-align: center;
}

.closest_offer_container .offer_card .offer_text{
    color:rgba(26,11,91,0.3);
    font-family:"LATO";
    text-align: center;
}

.closest_offer_container .offer_card .icon_container i{
    font-size:32px;
}

@media screen and (max-width: 750px) {
    .closest_offer_container .offer_card{
        width:40%;
        margin:10px 0px;
    }
}

@media screen and (max-width: 550px) {
    .closest_offer_container .offer_card{
        width:100%;
        margin:10px 0px;
    }
}