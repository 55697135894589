.login-car-carousel-container {
  position: relative;
  height: auto;
  background: url("../../assets/images/login-car-bg.png") 0% 0% / cover;
  background-size: cover;
  background-position: center;
}

.login-car-carousel .carousel .slide {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login-car-carousel-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  margin: 2rem auto;
  position: relative;
}

.login-car-carousel-buttons {
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  gap: 10px;
  opacity: 1;
  z-index: 1;
  /* margin-top: 2rem; */
}

/* .login-car-carousel .carousel .slide.selected .login-car-carousel-content {
    opacity: 1;
    transform: translateY(0);
  } */

.login-car-carousel .carousel .slide.selected .login-car-carousel-content h5 {
  width: 100%;
}

.login-car-carousel
  .carousel
  .slide.selected
  .login-car-carousel-content
  .main-title {
  margin-top: 2rem;
  text-transform: uppercase !important;
  line-height: 1.5;
  width: 50%;
}
/*   
  .login-car-carousel .carousel .slide.selected .login-car-carousel-buttons {
    opacity: 1;
    transform: translateY(0);
  } */

/* .login-car-carousel-container .login-car-carousel-login-car-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 24px;
    cursor: pointer;
  } */

/* .login-car-carousel-left-login-car-arrow {
    left: 10px;
  }
  
  .login-car-carousel-right-login-car-arrow {
    right: 10px;
  } */

.login-car-carousel-image-container {
  max-width: 600px;
  max-height: auto;
  margin: auto;
  margin-top: 0.5rem;
  border-radius: 10px;
}

.login-car-carousel-image-container img {
  border-radius: 10px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.login-car-carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* opacity: 1; */
  /* transition: opacity 2s ease-in-out; */
}

/* .login-car-carousel .carousel .slide .login-car-carousel-image {
    opacity: 0;
  }
  
  .login-car-carousel .carousel .slide.selected .login-car-carousel-image {
    opacity: 1;
  } */

.login-car-wrapper {
  position: relative;
  /* height: 80vh; */
}

.login-car-btn {
  padding: 10px 20px;
  background: var(--white-color);
  border-radius: 20px;
  border: none;
  cursor: pointer;
  border: 1px solid transparent;
  color: var(--primary-color);
  transition: all.5s ease;
}

.login-car-btn:hover {
  background: var(--primary-color);
  color: var(--white-color);
  border: 1px solid #fff;
}

.login-car-arrow-right {
  cursor: pointer;
  right: 5%;
  position: absolute;
  top: 30%;
  transition: transform 0.3s;
  color: var(--white-color);
  z-index: 1;
}

.login-car-arrow-left {
  cursor: pointer;
  left: 5%;
  position: absolute;
  top: 30%;
  transition: transform 0.3s;
  color: var(--white-color);
  z-index: 1;
}

.login-car-arrow-right:hover {
  transform: scale(1.2);
  color: var(--white-color);
}

.login-car-arrow-left:hover {
  transform: scale(1.2);
  color: var(--white-color);
}

/* .animate-next {
    transform: translate(0, 100%);
    opacity: 0;
  }
  
.animate-previous {
    transform: translate(0, -100%);
    opacity: 0;
  }
   */

/* .login-car-carousel-content {
    transition: transform 0.5s;
  }
  
  .login-car-carousel-content.slide-in {
    transform: translateY(50px);
  }
  
  .login-car-carousel-content.slide-out {
    transform: translateY(-50px);
  }
   */
@media screen and (max-width: 640px) {
  .login-car-btn {
    padding: 6px 12px;
  }
  .login-car-carousel-container {
    background: url("../../assets/images/login-car-bg-2.png");
    height: auto;
  }

  .login-car-wrapper {
    height: auto;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .login-car-carousel .carousel .slide.selected .login-car-carousel-content h5 {
    width: 100%;
    padding: 10px;
  }
  .login-car-carousel
    .carousel
    .slide.selected
    .login-car-carousel-content
    .main-title {
    padding: 10px;
    width: 100%;
  }
  .login-car-carousel-image-container {
    max-width: 80%;
    max-height: auto;
    margin: auto;
    margin-top: 0.5rem;
    border-radius: 10px;
  }

  .login-car-arrow-left,
  .login-car-arrow-right {
    display: none;
  }
}

/* .login-car-carousel-content .slide {
    transition: opacity .5s;
  } */
.login-car-carousel-content .slide.current {
  opacity: 1;
  animation-name: fadeInUp;
  animation-duration: 0.5s;
}

.login-car-carousel-content .slide.upcoming {
  opacity: 0;
  animation-name: fadeOutUp;
  animation-duration: 0.5s;
}

.login-car-carousel-content .slide.previous {
  opacity: 0;
  animation-name: fadeOutDown;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
  }
}
