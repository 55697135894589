/* Basic container for the leaderboard */
.lb-title {
  padding: 2rem;
  color: var(--background-color);
  padding-bottom: 0;
}
.leaderboard-con {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 2rem;
  padding: 2rem;
  align-items: flex-start;
}

.impact-score {
  /* background: #f8f8f8; */
  border-radius: 8px;
  /* padding: 1rem; */
}

h2 {
  text-align: center;
  font-size: 1.5rem;
  /* margin-bottom: 1rem; */
}

.leaderboard {
  /* margin-top: .5rem; */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  border: 1px solid var(--grey-color);
  border-radius: 8px;
}

.leaderboard-left {
  /* margin-top: .5rem; */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
}

.leader-row {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 0.5rem 2rem;
  border-radius: 8px;
  position: relative;
}

.leader-row:nth-child(2n + 1) {
  background-color: #f1f1f1;
}

.leader-row.first {
  color: #eea052;
}

.leader-row.second {
  color: #a2a2a2;
}

.leader-row.third {
  color: #aa6c39;
}

.leader-row .member {
  width: 300px;
  text-align: center;
}

.leader-row .score {
  width: 60px;
  text-align: right;
}

.metrics {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.metric {
  /* background: #f8f8f8; */
  /* padding: 1rem; */
  border-radius: 8px;
}

.metric-details {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 8px;
  border: 1px solid var(--grey-color);
}

.metric-row {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 0.5rem;
  border-radius: 8px;
}

/* Responsiveness for smaller screens */
@media (max-width: 768px) {
  .leaderboard-con {
    grid-template-columns: 1fr;
  }

  .metrics {
    grid-template-columns: 1fr;
  }
  .leader-row .score {
    width: 100%;
    text-align: center;
  }
  .leaderboard-con {
    padding: 1rem;
  }
  .leader-row .user-icon {
    left: 3rem !important;
  }
}

@media (max-width: 480px) {
  .leader-row,
  .metric-row {
    flex-direction: column;
    text-align: center;
  }
}
