.toggle-sidebar {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
  }
  
  .toggle-sidebar button {
    background-color: transparent;
    border: none;
    color: #FFF;
    font-size: 16px;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
  }
  
  .toggle-sidebar button:hover {
    background-color: transparent;
  }

@media screen and (max-width: 1050px) {
   .toggle-sidebar {
    position: absolute;
   }
}
