.gridContainer section {
  font-size: 12px;
  min-width: 95px;
  /* min-height: 75px; */
  margin: auto;
  font-family: "LATO";
  /* padding: .5rem; */
}
input[type="checkbox"] {
  -ms-transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -o-transform: scale(2);
  transform: scale(2);
  padding: 10px;
}

.tc_input {
  margin: 10px 10px 0px 0px;
  font: 100px !important;
}

.textarea_input {
  font-family: "LATO";
  padding: 8px;
  font-size: 14px;
  outline: none;
  border-radius: 5px;
}

.textarea_input:focus {
  border-color: #f26654;
}

.add_icon_container {
  text-align: center;
  padding-top: 30px;
}

.item_title {
  color: #506385;
  padding-bottom: 10px;
  font-weight: 700;
}

.gridContainerContainer {
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.gridContainer {
  width: 99%;
  margin: 0 auto !important;
  text-align: center;
  display: grid;
  grid-template-columns: 80px 80px 80px 80px 80px 80px 80px 80px 80px;
  /* grid-auto-rows: 1.6em; */
  grid-gap: 24px;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

@media screen and (max-width: 1500px) {
  .gridContainer {
    margin: 0 auto !important;
    text-align: center;
    display: grid;
    grid-template-columns: 180px 180px 180px 180px;
    /* grid-auto-rows: 1.6em; */
    grid-gap: 15px;
  }
}

@media screen and (max-width: 1350px) {
  .gridContainer {
    margin: 0 auto !important;
    text-align: center;
    display: grid;
    grid-template-columns: 180px 180px 180px;
    /* grid-auto-rows: 1.6em; */
    grid-gap: 15px;
  }
}

@media screen and (max-width: 1000px) {
  .gridContainer {
    margin: 0 auto !important;
    text-align: center;
    display: grid;
    grid-template-columns: 180px 180px;
    /* grid-auto-rows: 1.6em; */
    grid-gap: 15px;
  }
}

@media screen and (max-width: 750px) {
  .gridContainer {
    margin: 0 auto !important;
    text-align: center;
    display: grid;
    grid-template-columns: 100%;
    /* grid-auto-rows: 1.6em; */
    grid-gap: 15px;
  }
}

.gridContainer section {
  padding: 15px;
  border-radius: 3px;
}

.gridContainer section:nth-child(1) {
  background-color: #000;
  color: #fff;
}

.gridContainer section:nth-child(2) {
  background-color: whitesmoke;
  color: #000;
}

.gridContainer section:nth-child(3) {
  background-color: #c30913;
  color: #fff;
}

.gridContainer section:nth-child(4) {
  background-color: #fabfce;
  color: #000;
}

.gridContainer section:nth-child(5) {
  background-color: #f89d1f;
  color: #000;
}

.gridContainer section:nth-child(6) {
  background-color: #4470ea;
  color: #fff;
}

.gridContainer section:nth-child(7) {
  background-color: #fad360;
  color: #000;
}

.gridContainer section:nth-child(8) {
  background-color: #0c2f77;
  color: #fff;
}

.gridContainer section:nth-child(9) {
  background-color: #5f331f;
  color: #fff;
}

.gridContainer section:nth-child(10) {
  background-color: #581876;
  color: #fff;
}

.gridContainer section:nth-child(11) {
  background-color: #b6b6b6;
  color: #000;
}

.gridContainer section:nth-child(12) {
  background-color: #fdcb02;
  color: #000;
}

.gridContainer section:nth-child(13) {
  background-color: #00693c;
  color: #fff;
}

.gridContainer section:nth-child(14) {
  background-color: #d9c5aa;
  color: #000;
}

.gridContainer section:nth-child(15) {
  background-color: #2bf5cc;
  color: #000;
}

.gridContainer section:nth-child(16) {
  background-color: #f2eed2;
  color: #000;
}

.gridContainer section:nth-child(17) {
  background-color: #d0d0d0;
  color: #000;
}

.gridContainer section:nth-child(18) {
  background: #feac5e; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #4bc0c8,
    #c779d0,
    #feac5e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #4bc0c8,
    #c779d0,
    #feac5e
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #fff;
}

.saveDraft {
  /* color: #F26654; */
  padding-top: 1px !important;
  padding-left: 0px !important;
  text-align: center;
}

.newBrand {
  /* color: #F26654; */
  padding-top: 25px;
  padding-left: 15px;
  text-align: left;
  margin-bottom: 0.5rem;
}

.newBrand a {
  margin: 25px 0px;
  font-family: "LATO";
  color: #f26654;
  position: relative;
  /* font-size:18px; */
  text-decoration: none;
}

.newBrand a::before {
  content: "";
  height: 2px;
  background: #f26654;
  width: 0%;
  position: absolute;
  bottom: 0%;
  transition: 0.5s ease;
}

.newBrand a:hover::before {
  width: 100%;
}

.newBrand a:hover {
  color: #f26654;
}

.closest_setup_container {
  /* margin-top: 40px; */
}

.closest_setup {
  width: 60%;
  margin: auto;
}

.closest_setup form {
  margin-top: 20px;
}

.closest_setup form .input_container,
.closest_setup form .select_container {
  margin-top: 8px !important;
}

.closest_setup form .input_container input,
.closest_setup form .select_container select {
  margin-top: 5px !important;
}

.closest_setup form .personal_info_container,
.closest_setup form .img_upload_container {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin: 1rem 0;
  color: var(--background-color);
}

.closest_setup form .personal_info_container > div:not(:first-child),
.closest_setup form .img_upload_container > div:not(:first-child) {
  margin-left: 5px;
}

.closest_setup form .input_container,
.closest_setup form .select_container,
.closest_setup form .text_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  color: var(--background-color);
}

.closest_setup form .input_container label,
.closest_setup form .select_container label,
.closest_setup form .text_container label,
.closest_setup
  form
  .img_upload_container
  .file_icon_container
  div.img_name_cont {
  font-family: "LATO";
  font-size: 14px;
  font-weight: 700;
  display: block;
  margin-bottom: 2px;
}

.img_name_cont .img_icon_cont {
  margin: 0px 5px;
  cursor: pointer;
}

.img_name_cont .img_icon_cont img {
  border: 4px solid transparent;
  aspect-ratio: 3/2;
  object-fit: contain;
}

.img_name_cont .img_icon_cont .img_name {
  position: relative;
}

.img_name_cont .img_icon_cont .cross {
  position: absolute;
  z-index: 0;
  top: -6%;
  background: #f26654;
  color: white;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  right: -4%;
}

.img_name_cont .img_icon_cont img.feature {
  border: 4px solid #f26654;
}

.preview_image_container {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.preview_image_container img {
  width: 300px;
}

.color_button {
  cursor: pointer;
  border-width: 10px;
  border-color: #f26654;
  border: 4px solid transparent;
  transition: 0.5s ease-in-out;
}

.color_button:focus {
  border-image-slice: 1;
  border-width: 4px;
  border-image-source: linear-gradient(to left, #ff2c11, #b8230f);
}

.color_select {
  opacity: 0.85;
  border-image-slice: 1;
  border-width: 4px;
  border-image-source: linear-gradient(to left, #ff2c11, #b8230f);
  /* border-radius: 100px; */
}

.show_color_slider {
  display: block;
}

.hide_color_slider {
  display: none;
}

.closest_setup form .img_upload_container .file_icon_container {
  width: 100%;
}

.closest_setup form .img_upload_container .file_icon_container label {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  display: block;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.closest_setup form .input_container input,
.closest_setup form .select_container select,
.closest_setup form .text_container textarea {
  padding: 8px;
  font-size: 14px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 5px;
}

.closest_setup form .select_container select {
  padding: 7px;
}

.closest_setup form .input_container input:focus,
.closest_setup form .select_container select:focus,
.closest_setup form .text_container textarea:focus {
  border-color: #f26654;
}

.closest_setup form button {
  width: 100%;
  margin-top: 20px;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
}

.text-danger {
  color: red;
}

.img_name_cont {
  width: 100%;
  text-align: center;
  color: #f26654;
  gap: 1rem;
}

.img_name_cont i {
  font-size: 20px;
}

.drag-active {
  background-color: #f1f1f1;
}

@media screen and (max-width: 750px) {
  .closest_setup {
    width: 90%;
  }
  .closest_setup form .personal_info_container,
  .closest_setup form .img_upload_container {
    flex-direction: column;
    align-items: center;
  }
  .closest_setup form .personal_info_container > div:not(:first-child),
  .closest_setup form .img_upload_container > div:not(:first-child) {
    margin-left: 0px;
    margin-top: 10px;
  }
}

.setup-complete-button {
  padding: 20px;
  margin: 10px;
  width: 340px;
  text-transform: uppercase;
  font-weight: bold;
}

.similarClosets {
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0 auto;
  /* width: 100vw;
    padding-left: 20vw;
    padding-right: 20vw; */
  text-align: center !important;
  display: table;
}

.UsersContainer {
  /* position: relative; */
  display: table-cell;
  width: 110px;
  height: 110px;
  text-align: center;
  padding-bottom: 100px;
  padding-top: 30px;
}

@media screen and (max-width: 750px) {
  /* .similarClosets{
        width: 80%;
        margin-left: 10vw;
        margin-right: 10vw;
    } */

  .UsersContainerHidden {
    visibility: hidden;
    display: none;
  }
}

.closetUser {
  width: 60px;
  height: 60px;
  border-radius: 100px;
}

.addFriend {
  width: 24px;
  height: 24px;
  background-color: #51a7e6;
  /* text-decoration: none !important; */
  /* box-shadow: none !important; */
  border-width: 0px !important;
  border-radius: 30px;
  /* border-color: #51a7e6; */
  color: aliceblue;
  cursor: pointer;
  /* position: relative; */
  /* padding: auto; */
}

.addFriendContainer {
  /* position: relative; */
  margin-left: 60px;
  margin-top: -60px;
}
