.video-con {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: .5rem auto;
    /* min-height: 80vh; */
}

.video-con iframe {
    width: 900px;
    height: 500px;
    margin: auto;
    position: absolute;
    opacity: 0;
    z-index: 0;
    transition: .5s ease;
    border-radius: 10px;
}

.sc_video_inner {
    margin: 0 auto;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 600px;
}

.video_bg {
    position: absolute;
    width: 900px;
    height: 500px;
    opacity: 1;
    z-index: 1;
    transition: .5s ease;
    border-radius: 10px;
}

.v_icon {
    width: 80px;
    height: 80px;
    text-align: center;
    border-radius: 50%;
    line-height: 80px;
    display: block;
    position: relative;
    opacity: 1;
    z-index: 1;
    transition: .5s ease;
}

.v_icon::before {
    opacity: 0.1;
    transform: scale(2);
}

.v_icon .dot_animation {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.15);
    animation: pulse 3s infinite;
    transform: translate(-50%, -50%);
    animation-delay: 1.5s;
    will-change: transform;
}

.v_icon:after {
    opacity: 0.15;
    transform: scale(3);
    top: 0;
}

.video_bg:hover .video-con iframe  {
    opacity: 1;
    transition: all.5s ease;
}

.ytp-large-play-button .ytp-button .ytp-large-play-button-red-bg {
    opacity: 1 !important;
}

.ytp-large-play-button {
    opacity: 1;
}
@-webkit-keyframes pulse {
    0% {
      opacity: 1;
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      opacity: 0;
      -webkit-transform: translate(-50%, -50%) scale(3.5);
      transform: translate(-50%, -50%) scale(3.5);
    }
  }
  @keyframes pulse {
    0% {
      opacity: 1;
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      opacity: 0;
      -webkit-transform: translate(-50%, -50%) scale(3.5);
      transform: translate(-50%, -50%) scale(3.5);
    }
  }

  @media screen and (max-width: 950px) {
    .video-con iframe {
        width: 700px;
        height: 450px;
    } 
    .video_bg {
        width: 700px;
        height: 450px;
    }
    .sc_video_inner {
        min-height: 500px;
    }
  }

  @media screen and (max-width: 750px) {
    .video-con iframe {
        width: 500px;
        height: 350px;
    } 
    .video_bg {
        width: 500px;
        height: 350px;
    }
    .sc_video_inner {
        min-height: 420px;
    }
  }

  @media screen and (max-width: 550px) {
    .video-con iframe {
        width: 450px;
        height: 300px;
    } 
    .video_bg {
        width: 450px;
        height: 300px;
    }
    
  }

  @media screen and (max-width: 450px) {
    .video-con iframe {
        width: 320px;
        height: 180px;
    } 
    .video_bg {
        width: 320px;
        height: 180px;
    }
    .sc_video_inner {
        min-height: 280px;
    }
  }
/* 
  @media screen and (min-width: 1600px){
    .video-con iframe {
        width: 1200px;
        height: 600px;
    } 
    .video_bg {
        width: 1200px;
        height: 600px;
    }
    .video-con {
        min-height: 90vh;
    }
  }

  @media screen and (min-width: 1800px){
    .video-con iframe {
        width: 1400px;
        height: 800px;
    } 
    .video_bg {
        width: 1400px;
        height: 800px;
    }
    .video-con {
        min-height: 100vh;
    }
  }

  @media screen and (min-width: 2000px){
    .video-con iframe {
        width: 1400px;
        height: 800px;
    } 
    .video_bg {
        width: 1400px;
        height: 800px;
    }
    .video-con {
        min-height: 100vh;
    }
  }

  @media screen and (min-width: 2200px){
    .video-con iframe {
        width: 1400px;
        height: 800px;
    } 
    .video_bg {
        width: 1400px;
        height: 800px;
    }
    .video-con {
        min-height: 80vh;
    }
  } */




  