.header2 {
  /* height:55px; */
  border-top: 1px solid #f26654;
  border-bottom: 1px solid #f26654;
  width: 100%;
  margin-top: 10px;
  padding: 10px !important;
}

.addItemText {
  color: black;
  text-decoration: none !important;
  margin-left: -10px;
}

.nav_link {
  color: black;
  font-size: 15px;
  margin-right: 70px;
  text-decoration: none;
  padding-bottom: 2px;
  position: relative;
  transition: 0.5s ease;
  text-transform: uppercase;
}

.nav_link::before {
  content: "";
  height: 2px;
  background: #f26654;
  width: 0%;
  position: absolute;
  bottom: 0%;
  transition: 0.5s ease;
}

.nav_link:hover::before {
  width: 93%;
}

.nav_link:hover {
  color: #f26654;
}

.shop-dropdown {
  position: relative;
  display: inline-block;
}

.shop-dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--white-color);
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* padding: 12px 16px; */
  padding-top: 1rem;
  z-index: 111;
  /* border-radius: 5px; */
}

.shop-dropdown-content .dropdown-menu {
  padding: 0.7rem 1rem;
  text-align: center;
  border-bottom: 1px solid var(--primary-color);
  /* border-radius: 5px; */
}

.shop-dropdown-content:last-child {
  border-bottom: none;
}

.shop-dropdown:hover .shop-dropdown-content {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.dropdown-menu:hover {
  background: rgba(242, 242, 242, 1);
}

.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: var(--white-color);
  color: var(--primary-color);
  text-align: center;
  border-radius: 3px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 130%;
  left: 50%;
  margin-left: -80px;
  border: 1px solid var(--primary-color);
  z-index: 999;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--primary-color) transparent;

}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.reference-section {
  display: flex;
  align-items: center;
  margin: 10px 0;
  margin-top: 12px;
}

.reference-section label {
  margin-right: 10px;
}

.reference-section input[type="text"] {
  padding: 9.2px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  background-color: #f7f7f7;
  font-size: 16px;
  flex-grow: 1;
}

.reference-section button {
  padding: 10px 10px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 16px;
}

.reference-section button:hover {
  background-color: var(--peel-orange-color);
}

@media screen and (max-width: 750px) {
  .header2 {
    display: none;
  }
}
