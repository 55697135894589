footer {
  background-color: var(--background-color);
  color: #ffffff;
  font-family: "Lato", "sans serif" !important;
}
footer * {
  box-sizing: border-box;
  border: none;
  outline: none;
}
footer .row {
  padding: 1em 6em;
}
footer .row.primary {
  display: grid;
  grid-template-columns: 1.5fr 2fr 1.5fr;
  align-items: stretch;
}
footer .row.primary .column {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 2em;
  min-height: 15em;
}
footer .row.primary .column h3 {
  width: 100%;
  text-align: left;
  color: var(--white-color);
  white-space: nowrap;
  font-family: "Lato", "sans serif" !important;
}
footer .row.primary .column.links ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  font-family: "Lato", "sans serif" !important;
}
footer .row.primary .column.links ul li:not(:first-child) {
  margin-top: 0.8em;
  font-family: "Lato", "sans serif" !important;
}
footer .row.primary .column.links ul li a {
  color: #ffffff;
  text-decoration: none;
  font-family: "Lato", "sans serif" !important;
}
footer .row.primary .column.links ul li a:hover {
  color: var(--primary-color);
}
footer .row.primary .column.subscribe div input,
footer .row.primary .column.subscribe div button {
  padding: 1em;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 5px;
}
footer .row.primary .column.subscribe div button {
  background-color: #F26B59;
  color: #000;
}
footer .row.primary .column.subscribe div.social {
  display: flex;
  justify-content: space-around;
  font-size: 30px;
  flex-direction: row;
  margin-top: 0.5em;
}
footer .row.primary .column.subscribe .social i:hover {
  color: var(--white-color);
}
footer .row.primary .column.row.secondary {
  display: flex;
  justify-content: space-evenly;
  /* align-items: center; */
  text-align: left;
}
footer .row.primary .column.row.secondary i {
  color: var(--white-color);
}
footer .row.primary .column.row.secondary span {
  margin-left: 1rem;
  color: var(--white-color);
}
footer .row.primary .column.row.secondary div {
  padding: 1em 0;
  color: #f1f1f1;
  /* width: 100%; */
}
footer .row.primary .column.row.secondary div:hover {
  /* background-color: #f1f1f1; */
  border-radius: 5px;
}
.copyright {
  padding: 0.3em 1em;
  background-color: var(--background-color);
  margin-bottom: 0;
  padding-bottom: 0;
}
.copyright p {
  text-align: center;
  color: var(--white-color);
  padding: 0 2rem;
}

.copyright p .hover-link:hover {
  color: var(--primary-color);
}

@media screen and (max-width: 1120px) {
  footer .row {
    padding: 1rem 4rem;
  }
}
@media screen and (max-width: 1060px) {
  footer .row {
    padding: 1rem 2rem;
  }
  footer .row.primary .column {
    padding: 0 1rem;
  }
  .copyright p {
    padding: 0;
  }
}
@media screen and (max-width: 800px) {
  footer .row {
    padding: 1rem 2rem;
  }
  footer .row.primary {
    grid-template-columns: 1fr;
  }
  footer .row.primary .column.row.secondary {
    flex-direction: column;
  }
  footer .row.primary .column {
    min-height: auto;
    margin: 1rem auto;
  }
}
