.not_found_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
}

.not_found_container .img_text{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-family: 'LATO';
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-shadow: 0px 0px 10px #000;
}

.not_found_container .img_text h1{
    font-size: 34px;
}

.not_found_container button{
    margin-top:20px;
    font-weight: bold;
    font-size:18px;
    text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .not_found_container {
    height: 100%;
  }
  .not_found_container .img_text{
    top: 70%;
  }
  .not_found_container .img_text h1{
    font-size: 20px;
  }
  .not_found_container button{
        margin-top:20px;
        font-weight: bold;
        font-size:12px;
        text-transform: uppercase;
    }
}