.membershipselect_container{
    margin-top: 25px;
}

.membership_card_container{
    margin-top: 30px;
    display: flex;
    justify-content: space-evenly;
}

.membership_card{
    display: flex;
    flex-direction: column;
    padding:10px;
    cursor: pointer;
    border: 2px solid rgba(0,0,0,0.4);
    /* box-shadow: 0px 1px 4px rgba(0,0,0,0.25); */
    margin:0px 10px;
    flex:1;
    border-radius: 5px;
}

.membership_card .membership_title{
    /* font-size: 30px; */
    font-weight: bold;
    color:#F26654;
    text-align: center;
    margin: 1rem auto;
}

.membership_card .membership_desc{
    /* font-size: 16px; */
    color:#506385;
    margin-top: 5px;
    text-align: center;
    margin-bottom: 1rem;
    min-height: 100px;
    /* margin: auto; */
}

.membership_card .membership_feature{
    margin: 5px;
    align-self: flex-start;
    /* margin-top: auto; */
}

.membership_card .membership_feature ul{
    padding:0px;
}

.membership_card .membership_feature ul li{
    color:#506385;
    list-style:none;
    display:flex;
    /* align-items: center; */
    /* justify-content: space-evenly; */
    margin:5px 0px;
    margin-top: 10px !important;
}

.membership_card .membership_feature ul li span.bullet{
    content: '';
    display: inline-block;
    height: 20px;
    width:20px;
    margin-right: 10px;
    padding-right: 30px;
    background-image: url("../../assets/images/hanger.svg");
    background-size:contain;
    background-repeat:no-repeat;
}

.membership_card button{
    margin: auto auto 0px auto;
    display: block;
    text-transform: uppercase;
    align-self: flex-start;
    margin: 1rem auto;
    margin-top: auto;
}

.membership_select{
    border: 2px solid #F26654;
    box-shadow: 0px 1px 4px #f26654;
    background-color: #ffd4ba;
}

.membership_select button {
    background: var(--white-color);
    color: var(--primary-color);
}

@media screen and (max-width: 750px) {
    .membership_card_container{
        flex-direction: column;
        align-items: center;
    }
    .membership_card{
        margin:10px 0px;
        padding: 20px;
    }
}