.my-closet-sidebar-con {
  /* position: sticky;
  top: 4rem; */
  width: 100%;
}

.my-closet-sidebar-wrapper {
  padding: 0.5rem 1rem;
  height: auto;
  background: #f7e4e1;
  border-top: 10px solid var(--primary-color);
  border-radius: 5px;
}

.my-closet-sidebar-wrapper ul {
  margin: 0.5rem 1rem;
  /* padding: 0 1rem; */
}

.my-closet-sidebar-wrapper ul li {
  margin: 1.75rem 0;
  font-size: 20px;
}

.my-closet-sidebar-wrapper ul li.active-link {
  font-weight: 700;
}
