.top_products_container{
    margin:40px 0px;
}

.top_products_container .tp_cards_container{
    width:90%;
    margin:auto;
}

.top_products_container .tp_cards_container .top_product_card{
    cursor: pointer;
    margin:0px 20px;
}

.top_products_container .tp_cards_container .top_product_card:hover .img_container button{
    opacity: 1;
} 
.top_products_container .tp_cards_container .top_product_card:hover .img_container{
    box-shadow: -2px 6px 2px #9877e7;
}

.top_products_container .tp_cards_container .top_product_card .img_container{
    padding:20px;
    position: relative;
    border-radius: 50%;
    background-color: #F6F7FB;
    display:flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    transition: 0.5s ease;
    box-shadow: 0 8px 40px rgba(49,32,138,0.05);
}

.top_products_container .tp_cards_container .top_product_card .img_container img{
    width:80%;
}

.top_products_container .tp_cards_container .top_product_card .img_container button{
    position: absolute;
    bottom: 10%;
    opacity: 0;
    transition: 0.5s ease;
}

.top_products_container .tp_cards_container .top_product_card .img_container:after{
    content: "";
    display: block;
    padding-bottom: 100%;
}

.top_products_container .tp_cards_container .top_product_card .text_container{
    margin-top:10px;
    text-align: center;
}

.top_products_container .tp_cards_container .top_product_card .text_container .product_title{
    color: #151875;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 5px;
}

.top_products_container .tp_cards_container .top_product_card .text_container .product_price{
    color: #151875;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 12px;
    font-weight: bold;
}

.tp_page_container{
    width: 10px !important;
    height: 10px !important;
    border-radius: 50% !important;
    border-color: #f26654 !important;
    border:1px solid;
    background-color: transparent !important;
}

.tp_page_container.is-active{
    background-color: #f26654 !important;
    transform: scale(1) !important;
}