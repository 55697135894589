.video-upload-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.upload-item {
  margin-bottom: 20px;
  width: 100%;
}

.video-upload-con {
  display: flex;
}

.empty-video-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
  text-align: center;
}

.video-upload-form {
  width: 60%;
  margin: 20px;
  margin-left: 0;
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  font-size: 14px;
  color: var(--background-color);
  font-weight: 700;
  /* border-bottom: 1px solid #ccc; */
}

.video-upload-container input,
textarea {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  transition: border-color 0.3s;
  border-radius: 5px;
}

input:focus,
textarea:focus {
  border-color: var(--primary-color);
}

.video-preview-con {
  width: 40%;
  margin: 20px;
  margin-right: 0;
  border-radius: 10px;
}

.static-cms-btn-label {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  transition: border-color 0.3s;
  border-radius: 5px;
}

.static-cms-btn-link {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  transition: border-color 0.3s;
  border-radius: 5px;
}

@media screen and (max-width: 800px) {
  .video-upload-con {
    flex-direction: column-reverse;
    margin: 2rem 0.5rem;
  }
  .video-upload-form {
    width: 100%;
    margin: 20px 0;
  }
  .video-preview-con {
    width: 100%;
    margin: 10px 0;
    border-radius: 10px;
  }
  .static-cms-wrapper {
    padding: 0;
  }
  .static-cms-btn-label {
    margin-bottom: 10px;
    padding: 2px !important;
    border: 1px solid #ccc;
    transition: border-color 0.3s;
    border-radius: 5px;
    margin-right: 2px !important;
    width: 80%;
  }

  .static-cms-btn-link {
    margin-bottom: 10px;
    padding: 2px !important;
    border: 1px solid #ccc;
    transition: border-color 0.3s;
    border-radius: 5px;
    margin-left: 2px !important;
    width: 80%;
  }
}
