.shop-items-con .title {
  color: #506385;
  margin-bottom: 10px;
}

.sortCategory {
  position: absolute;
  top: 4rem;
  right: 0rem;
  padding-bottom: 3rem;
}

.categorySorter:hover .drop-down {
  display: block;
  /* margin-top: 1rem; */
}

.drop-down {
  /* display: none; */
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 8px;
  margin-left: 2rem;
  position: absolute;
  right: 6%;
  background-color: #fff;
  z-index: 10;
  display: none;
  margin-top: 0.5rem;
  padding-top: 1rem;
  width: 100%;
}

.drop-down a {
  color: #506385;
  text-decoration: none;
  margin-bottom: 10px;
  display: block;
}

.drop-down a:hover {
  color: #f26654;
}

@media screen and (max-width: 900px) {
  .sortCategory {
    top: 81rem;
    right: 0;
    margin: 0 !important;
    padding: 0 !important;
    margin-right: 1rem !important;
    padding-right: 1rem !important;
  }
}

.hiddenSeparation {
  visibility: hidden;
}

@media screen and (max-width: 700px) {
  .hiddenSeparation {
    visibility: visible;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

/* .flex-parent-element {
    display: flex;
    width: 50%;
}
  
.flex-child-element {
    flex: 1;
    margin: 10px;
    padding: 30px;
}
  
.flex-child-element:first-child {
    float: right !important;
    margin-right: -9px;
}

@media screen and (max-width: 700px) {
    .flex-parent-element {
        display: block;
        width: auto;
    }

    .flex-child-element {
        flex: 1;
        margin: 15px;
        padding: 15px;
    }
      
    .flex-child-element:first-child {
        margin-right: 90px;
    }    
} */

.shop-items-con .secondary-title {
  color: #506385;
  margin-bottom: 30px;
}

.shop-items-con .shop-items-con-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.shop-items-con .shop-item-card {
  cursor: pointer;
  box-shadow: 0px 0px 18px rgb(0 0 0 / 10%);
  font-family: "Lato", sans-serif !important;
  /* width: 230px; */
  max-width: 250px;
  margin: 2rem 1rem;
  border-radius: 5px;
  position: relative;
  flex: 0 0 calc(25% - 2rem);
}

.shop-item-card .card_image {
  background: #f6f7fb;
  max-width: 250px;
  margin-top: -10px;
  padding-bottom: 0 !important;
  border-radius: 5px;
}

.card_image img {
  width: 100%;
  border-radius: 5px 5px 0 0;
}

/* .ribbon-pop {
  background: linear-gradient(270deg, #f26654 5.25%, #f26654 96.68%);
  display: inline-block;
  padding: 5px 25px 5px 15px;
  color: white;
  position: relative;
  margin-left: -14px;
  z-index: 1;
  
}
.ribbon-pop:after {
  content: "";
  width: 0;
  height: 0;
  top: 0;
  position: absolute;
  right: 0;
  border-right: 13px solid #e9a79e;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
}
.ribbon-pop:before {
  height: 0;
  width: 0;
  border-top: 15px solid #e9a79e;
  border-left: 15px solid transparent;
  bottom: -14px;
  position: absolute;
  content: "";
  left: 0;
}

.ribbon-cut {
    width: 50px;
    height: 50px;
    background: #000;
} */

.ribbon {
  font-size: 12px;
  position: absolute;
  display: inline-block;
  /* margin:5em; */
  text-align: center;
  z-index: 1;
  margin-left: -8px;
}
.text {
  display: inline-block;
  padding: 0.5em 1em;
  min-width: 10em;
  line-height: 1.2em;
  background: var(--primary-color);
  position: relative;
  text-align: left;
  color: var(--white-color);
}
.ribbon:after,
.ribbon:before,
.text:before,
.text:after,
.bold:before {
  content: "";
  position: absolute;
  border-style: solid;
}
.ribbon:before {
  top: 0.3em;
  left: 0.2em;
  width: 100%;
  height: 100%;
  border: none;
  background: #ebeced;
  background: transparent;
  z-index: -2;
}
.text:before {
  bottom: 100%;
  left: 0;
  border-width: 0.5em 0.7em 0 0;
  /* border-color: transparent #FC9544 transparent transparent; */
  border-color: transparent var(--peel-orange-color) transparent transparent;
}
.text:after {
  top: 100%;
  right: 0;
  border-width: 0.5em 2em 0 0;
  /* border-color: #FC9544 transparent transparent transparent; */
  border-color: var(--peel-orange-color) transparent transparent transparent;
}
.ribbon:after,
.bold:before {
  top: 0.5em;
  right: -2em;
  border-width: 1.1em 1em 1.1em 3em;
  /* border-color: #FECC30 transparent #FECC30 #FECC30; */
  border-color: var(--primary-color) transparent var(--primary-color)
    var(--primary-color);
  z-index: -1;
}
.bold:before {
  border-color: #ebeced transparent #ebeced #ebeced;
  top: 0.7em;
  right: -2.3em;
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  width: 100%;
}

.shop-item-card .card_image img {
  /* height: 150px; */
  display: block;
  /* padding-top:10px; */
  margin: auto;
  text-align: center;
  height: 100%;
  margin-top: -15px;
  transition: all 0.5s ease;
}

.shop-item-card .card_text {
  text-align: center;
  transition: 0.5s ease;
  margin-top: -4px;
  display: flex;
  padding: 0rem 1rem;
}

.shop-item-card:hover .card_text,
.shop-item-card:hover .card_text .product_title,
.shop-item-card:hover .card_text .product_code,
.shop-items-con .shop-item-card:hover .card_text .product_price {
  color: #fff;
  border-radius: 0 0 5px 5px;
}

.shop-item-card:hover .card_text {
  background-color: #f26654;
}

@keyframes fillHeart {
  0% {
    fill: transparent;
    transform: scaleY(0);
  }
  50% {
    fill: var(--primary-color);
    transform: scaleY(1);
  }
  100% {
    fill: transparent;
    transform: scaleY(0);
  }
}

.heart-icon-animation {
  width: 1.2em;
  height: 1.2em;
  transition: fill 2s;
  cursor: pointer;
}

.heart-fill {
  fill: transparent;
  animation: fillHeart 4s infinite;
}

.heart-outline {
  fill: none;
  stroke: var(--primary-color);
  stroke-width: 2;
}

.shop-item-card:hover::after .card_image img {
  transform: scale(1);
  transition: all 0.5s ease-out;
}

.shop-item-card .card_text .product_title {
  width: 80%;
  color: #f26654;
  font-weight: normal !important;
  transition: 0.5s ease;
  font-family: "Lato", sans-serif !important;
  min-height: 85px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 0 0.5rem;
}

.sortCategory .categorySorter {
  padding-bottom: 1rem;
}

.sortCategoryDropdown {
  font-family: "Lato", sans-serif !important;
  margin: 25px 0px;
  color: #000;
  position: relative;
  /* padding-bottom: 1rem; */
  text-decoration: none;
}

.sortCategoryDropdown::before {
  content: "";
  height: 2px;
  background: #f26654;
  width: 0%;
  position: absolute;
  bottom: -1.5rem;
  transition: 0.5s ease;
  /* padding-top: rem; */
  border-bottom: 1px solid var(--primary-color) !important;
}

/* .sortCategoryDropdown:hover::before {
  width: 100%;
} */

.sortCategoryDropdown:hover {
  color: #f26654;
}

.admin-menu-container .menu_container div {
  padding: 10px 0px;
}

.shop-items-con .shop-item-card .card_text .product_code {
  color: var(--primary-color);

  margin: 0px;
  transition: 0.5s ease;
}

.shop-items-con .shop-item-card .card_text .product_price {
  color: var(--primary-color);
  transition: 0.5s ease;
  margin: 0px;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
}

.shop-item-card .card_text .product_price img {
  transition: 0.5s ease;
}

.shop-item-card:hover .card_text .product_price img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(98%) saturate(2%)
    hue-rotate(63deg) brightness(109%) contrast(101%);
}

.shop-item-card .card_text .product_colors {
  display: flex;
  justify-content: center;
  margin: 12px 0px;
}

.shop-item-card .card_text .product_colors .product_color_box {
  height: 4px;
  width: 10px;
  margin: 0px 2px;
}

.shop-items-con .shop-item-card .action_icon {
  display: flex;
  margin-left: 5px;
  justify-content: center;
  padding-top: 10px;
  margin-bottom: 10px;
  opacity: 0;
  transition: 0.5s ease;
}

.shop-item-card .card_image button {
  opacity: 0;
  transition: 0.5s ease;
}

.shop-item-card:hover .card_image .action_icon,
.shop-item-card:hover .card_image button {
  opacity: 1;
}

.action_icon div {
  margin: 0px 5px;
  cursor: pointer;
  transition: 0.5s ease;
  padding: 5px 8px;
}

.no_item {
  width: 100% !important;
  margin: auto;
  text-align: center;
  margin-top: 8rem;
}

.no_item img {
  max-width: 100%;
  max-height: 100%;
}

.card_image .lazy-load-image-background.blur.lazy-load-image-loaded > img {
  width: 100%;
  height: 100%;
  min-height: 280px;
  max-height: 280px;
  object-fit: cover;
}

.hidden-card-icons {
  display: none;
  transition: opacity 2.5s ease-in;
  justify-content: space-between;
  position: absolute;
  bottom: 5.5rem;
  width: 100%;
  padding: 0px 10px;
  opacity: 1;
}

.hidden-card-icons .hidden-card-icon-wrapper {
  padding: 8px;
  background: var(--white-color);
  border-radius: 50%;
  display: flex;
  justify-self: center;
  align-self: center;
  margin-bottom: 0.75rem;
  transition: 1s ease-in-out;
  z-index: 999;
}

.shop-item-card:hover .hidden-card-icons {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 5.3rem;
  width: 100%;
  padding: 0px 15px;
  opacity: 1;
}

.hidden-card-icon-wrapper .heart-icon {
  transition: all 0.5s ease;
}

.shimmer-effect {
  background: linear-gradient(to right, #f0f0f0 4%, #e1e1e1 25%, #f0f0f0 36%);
  background-size: 800px 100%;
  animation: shimmer 1s infinite linear;
}

@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

.all-items-title {
  padding-left: 50px;
  text-align: left;
}

@media screen and (max-width: 1100px) {
  .shop-items-con .shop-item-card {
    flex: 0 0 calc(33.3% - 2rem);
  }
}

@media screen and (max-width: 900px) {
  .shop-items-con .shop-item-card {
    flex: 0 0 calc(33.3% - 2rem);
  }
  .no_item {
    max-width: 90% !important;
    margin-top: 4rem;
  }
  .sortCategory {
    top: 94rem;
  }
  .sortCategoryDropdown {
    font-size: inherit;
  }
  .all-items-title {
    padding-left: 0;
  }
}

@media screen and (max-width: 700px) {
  .shop-items-con .shop-item-card {
    flex: 0 0 calc(50% - 2rem);
  }
}

@media screen and (max-width: 700px) {
  .shop-items-con .shop-item-card {
    flex: 0 0 90%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
  }
}
