.star-rating {
    font-size: 32px;
  }
  
  .star {
    color: #bbb;
  }
  
  .filled {
    /* color: orange; */
    color: var(--peel-orange-color);
  }