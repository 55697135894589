
.marquee__con {
  --space: 3rem;
  display: grid;
  align-content: center;
  overflow: hidden;
  gap: var(--space);
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.5;
  margin: 2rem auto;
}

.marquee {
  --duration: 60s;
  --gap: var(--space);
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  /* background: var(--peel-orange-color); */
}

.marquee:hover .marquee__group {
  animation-play-state: paused;
}

.Marquee-tag:hover {
  transform: scale(1.1);
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll var(--duration) linear infinite;
}

.Marquee-tag {
    width: 150px;
    max-height: min-content;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
  }

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee__group img {
  object-fit: cover;
  padding: 1rem;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% - var(--gap)));
  }
}
