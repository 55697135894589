.static-carousel-con {
  /* background-color: #e0e3e8; */
  /* font-family: "Rubik", sans-serif; */
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #727e8c;
}

/* a, a:hover, a:focus {
    text-decoration: none;
    transition: 0.5s;
    outline: none;
} */

/* ------------ */

.static-carousel-con .card {
  /* box-shadow: 0px 4px 8px rgb(0 0 0 / 16%); */
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 30px;
  background-color: #fff;
  border: none;
}

.card-body .btn-primary {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: #fff;
}
.form-submit {
  padding: 13px 30px;
  font-size: 15px;
  letter-spacing: 0.3px;
  font-weight: 400;
}
.kb-data-box {
  width: 100%;
  flex: 1;
}

.kb-modal-data-title {
  margin-bottom: 10px;
}
.kb-data-title h6 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}
.kb-file-upload {
  margin-bottom: 20px;
}

.file-upload-box {
  border: 1px dashed #b6bed1;
  background-color: #f0f2f7;
  border-radius: 4px;
  min-height: 150px;
  position: relative;
  overflow: hidden;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  font-size: 15px;
}

.file-upload-box .file-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.file-link {
  color: #475f7b;
  text-decoration: underline;
  margin-left: 3px;
}

.file-upload-box .file-link:hover {
  text-decoration: none;
}

.file-atc-box {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}

.file-image {
  /* width: 130px;
    min-width: 130px;
    height: 85px;
    min-height: 85px; */
  width: 300px;
  height: 250px;
  background-size: cover;
  border-radius: 5px;
  margin-right: 15px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #475f7b;
  padding: 3px;
}

.file-image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}

.file-detail {
  flex: 1;
  width: calc(100% - 210px);
}

.file-detail h6 {
  word-break: break-all;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}

.file-detail p {
  font-size: 12px;
  color: #8194aa;
  line-height: initial;
  font-weight: 400;
  margin-bottom: 8px;
}

.file-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.file-action-btn {
  font-size: 12px;
  color: #8194aa;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 15px;
  cursor: pointer;
}

.file-action-btn:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

.file-atc-box:last-child {
  margin-bottom: 0;
}

.st-login-input {
  width: 300px;
  border: none;
  border-bottom: 1px solid #212121;
}

@media screen and (max-width: 767px) {
  .file-atc-box {
    flex-direction: column;
    margin: 0 0.5rem;
    margin-bottom: 4rem;
  }
  .file-atc-box .file-image {
    margin-right: 0;
    width: 100%;
  }
  .file-atc-box .file-detail {
    width: 100%;
    margin-top: 2rem;
  }
}
