#wrap {
  margin-top: 0.5rem;
  padding: 0;
  min-height: 80vh;
  font-size: 15px;
  opacity: 1;
  position: relative !important;
  width: 100%;
  height: 100%;
  z-index: 50;
  display: flex;
  background: rgb(250, 194, 166);
}

.searched-items {
  /* width: 300px; */
  margin: auto;
  /* position: relative !important; */
  bottom: auto !important;
  left: auto !important;
  /* transform: translateX(0%) !important; */
}

.close-search {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  cursor: pointer;
  font-size: 28px;
  color: rgb(209, 80, 0);
}

h1 {
  width: 100%;
  text-align: center;
}
.searchquery {
  text-align: center;
}
