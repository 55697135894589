.order-container .order-card-container {
    padding: 20px;
    border: 1px solid rgba(0,0,0,0.4);
    margin: 30px 0px;
    box-shadow: 0px 1px 4px rgba(0,0,0,0.25);
    position: relative
}

.order-container .order-card-container .order-card{
    display: flex;
    justify-content:space-between;
    padding: 20px;
    position: relative
}

.order-container .order-card-container .order-card .cross{
    position: absolute;
    z-index: 30;
    top: 5px;
    background: #f26654;
    color: white;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    right: 2%;
    cursor: pointer;
}

.order-container .title{
    text-align: left;
    font-size: 24px;
}

.order-container .order-card-container .order-card .product-img-container{
    flex-basis: 30%;
    border-radius: 5px;
    max-height: 250px;
    max-width: 250px;
}

.order-container .order-card-container .order-card .product-img-container img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 5px;
}

.order-container .order-card-container .closet-detail-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap:0.5rem;
    /* padding:20px; */
    /* width: 25%; */
    flex-basis: 30%;
}

.order-container .order-card-container .closet-detail-container .closet-title{
    font-size: 18px;
    font-weight: bold;
    font-family: 'LATO';
    color: #506385;
    text-align: center;
}

.order-container .order-card-container .closet-detail-container .closet-name{
    font-size: 16px;
    font-weight: bold;
    font-family: 'LATO';
    text-align: center;
    color: #F26654;
}

.order-container .order-card-container .closet-detail-container .closet-icon img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.order-container .order-card-container .order-card .order-desc-container{
    /* margin-left: 15px; */
    flex-basis: 65%;
}

.order-container .order-card-container .order-card .order-desc-container .order-meta-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    gap:2rem;
    padding-right: 2rem;
}

.order-container .order-card-container .order-card .order-desc-container .product-title-container, .order-container .order-card-container .order-card .order-desc-container .product-price-container .product-price-title{
    color: #506385;
    font-size: 18px;
    font-family: 'LATO';
}

.order-container .order-card-container .order-card .order-desc-container .product-title{
    color: #F26654;
    font-weight: bold;
    font-family: 'LATO';
    font-size: 20px;
    margin: 16px 0px;
}

.order-container .order-card-container .order-card .order-desc-container .product-price-container div.product-price{
    color: #506385;
    font-weight: bold;
    font-size: 18px;
    font-family: 'LATO';
    margin: 16px 0px;
}

.order-container .order-card-container .order-card .order-desc-container .product-price-container {
    /* text-align: end; */
}

.order-container .order-card-container .order-card .order-desc-container .product-price-container div span{
    display: inline-block;
    margin-left: 10px;
}

.order-container .order-card-container .delivery-container .delivery-title{
    font-family: 'LATO';
    color: #506385;
    font-weight: bold;
    /* margin-top: 15px; */
    font-size: 18px;
}

.order-container .order-card-container .delivery-container .delivery-options{
    margin-top: 15px;
}

.order-container .order-card-container .delivery-container .delivery-options .option{
    margin:10px 0px;
}

.order-container .order-card-container .delivery-container .delivery-options .option label{
    font-family: 'LATO';
}

.sharedby-delivery-con {
    display: flex;
    padding: 20px;
    align-items: flex-start;
    gap: 2rem;
}

.order-accordian-tab{
    background: var(--primary-color);
    color: var(--white-color);
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 8px;
    text-transform: uppercase;
    /* font-weight: bold; */
    font-size: 18px;
    margin-bottom: 8px;
    cursor: pointer;
}

.order-accordian-tab > div{
    
}

@media screen and (max-width: 750px) {
    .order-container .order-card-container .order-card .product-img-container{
        width: 50%;
    }
    .order-container .order-card-container .order-card .order-desc-container .order-meta-container{
        flex-direction: column;
        align-items: flex-start;
    }
    
    .order-container .order-card-container .order-card{
        padding: 0;
    }
    .sharedby-delivery-con {
        margin-top: 2rem;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
}