.product_description_container .product_title {
  font-size: 30px;
  font-weight: bold;
  color: #506385;
  margin-bottom: 5px;
}

.product_description_container .brand {
  margin-bottom: 20px;
}

.product_description_container .product_meta_container {
  display: flex;
  justify-content: space-between;
}

.product_description_container .product_meta_container .product_meta_data {
  margin-right: 20px;
  width: 60%;
}

.product_description_container
  .product_meta_container
  .product_meta_data
  .product_price {
  font-size: 24px;
  font-weight: bold;
  color: #506385;
  margin-bottom: 10px;
}

.product_description_container
  .product_meta_container
  .product_meta_data
  .product_description {
  font-size: 18px;
  margin-bottom: 10px;
}

.product_description_container
  .product_meta_container
  .product_meta_data
  .product_info
  > div {
  font-size: 18px;
  margin-bottom: 10px;
}

.product_description_container .product_meta_container .product_actions .btn {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  border-radius: 4px;
  background: var(--primary-color);
}

.product_description_container
  .product_meta_container
  .product_actions
  .report_btn {
  /* background-color:#cbcbcb; */
  border: none;
  outline: none;
  border-bottom: 1px solid grey;
  padding-bottom: 5px;
  cursor: pointer;
}

.product_description_container .product_meta_container .product_actions > div {
  margin-bottom: 20px;
  color: #506385;
  transition: 0.5s;
  opacity: 0.8;
  font-size: 18px;
}

.product_description_container
  .product_meta_container
  .product_actions
  > div:hover {
  opacity: 1;
  cursor: pointer;
}

.product_description_container
  .product_meta_container
  .product_actions
  > div
  i {
  color: #f26654;
  font-size: 20px;
  display: inline-block;
  margin-right: 5px;
}

.product-chat-btn {
  background: transparent !important;
  padding: 5px !important;
  color: var(--primary-color) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-chat-btn:hover {
  color: var(--peel-orange-color) !important;
}

.product-report-btn {
  background: transparent !important;
  border: 1px solid #d22730;
  padding: 8px;
  align-self: center;
  font-size: 10px;
  color: #d22730;
  box-sizing: border-box;
  cursor: pointer;
  letter-spacing: inherit;
  font-family: inherit;
  transition: 0.5s ease;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.product-report-btn:hover {
  background: #d22730 !important;
  color: var(--white-color) !important;
}

@media screen and (max-width: 600px) {
  .product_description_container .product_meta_container {
    flex-direction: column;
  }

  .product_description_container .product_meta_container .product_meta_data {
    width: 80%;
    margin-bottom: 30px;
  }

  .product_description_container .product_meta_container .product_actions {
    text-align: center;
  }

  .product_description_container .product_meta_container .product_actions .btn {
    width: 80%;
  }
  .product-report-btn {
    margin: auto;
  }
}
