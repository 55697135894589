.footer_container{
    margin-top:60px;
    
    background-color: #506385;

}

.footer_container .flex_cont{
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
}

.footer_container .company_name{
    color: #fff;
    font-size: 24px;
    font-weight: bold;
}

.footer_container .email_container{
    margin:10px 0px;
}

.footer_container .email_container input{
    padding: 8px;
    font-size: 14px;
    outline: none;
    margin-bottom: 5px;
    border: 1px solid #E7E6EF;
}

.footer_container .company_add{
    margin:10px 0px;
    color:#fff;
    font-size:16px;
}

.footer_container .col .title{
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}

.footer_container .col .menu_container{
    margin-top: 10px;
}

.footer_container .col .menu_container div a,.footer_container .credentials a{
    margin:15px 0px;
    color:#fff;
    position: relative;
    font-size:14px;
    text-decoration: none;
}

.footer_container .col .menu_container div a::before,.footer_container .credentials a::before{
    content: "";
    height: 2px;
    background: #F26654;
    width: 0%;
    position: absolute;
    bottom: -5px;
    transition: 0.5s ease;
}

.footer_container .col .menu_container div a:hover::before,.footer_container .credentials a:hover::before{
    width: 100%;
}

.footer_container .col .menu_container div a:hover,.footer_container .credentials a:hover{
    color: #F26654
}

.footer_container .col .menu_container div{
    padding:5px 0px;
}

.footer_subscribe{
    display: flex;
    justify-content: space-evenly;
}

.footer_subscribe .refer_container,.footer_subscribe .subscribe_container{
    width:100%;
}

.footer_subscribe .desc{
    color:#fff;
    margin-bottom: 10px;
    font-size:13px;
}

.footer_subscribe .searchbar_container .input_search{
    width:75%;
}

.footer_subscribe .searchbar_container button{
    vertical-align: bottom;
}

.footer_subscribe h1{
    color:#fff;
    margin-top: 0px;
    font-size: 20px;
}

.credentials{
    color:#fff;
    margin-top: 10px;
    text-align:center;
    width: 100%;
}

.footer_container .credentials a{
    font-size:16px;
}



.footer_subscribe .newsletter-con {
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.footer_subscribe .newsletter-con p {
    font-size: 20px;
    font-size: 700;
    color: #fff;
    margin: .5rem 0rem;
}
.footer_subscribe .newsletter-con input {
    padding: .5rem 1rem;
    width: 100%;
}
.footer_subscribe .newsletter-con button{
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
}
@media screen and (max-width: 800px) {
    .footer_container{
        padding-left: 50px;
    }
    .footer_container .flex_cont{
        flex-direction: column;
    }
    .footer_container .col{
        margin:10px 0px;
        width: 100% !important;
    }
    .footer_subscribe{
        flex-direction: column;
    }
    
    .footer_subscribe .refer_container,.footer_subscribe .subscribe_container{
        width: 80%;
        padding:20px 0px;
    }

    .footer_container .col .menu_container{
        display: flex;
        flex-wrap: wrap;
    }
    
    .footer_container .col .menu_container div{
        margin:10px 10px 0px 0px;
    }
}