.profile_dec_container{
    width: 100%;
}

.profile_dec_container h2{
    margin-top: 0px;
    font-size: 26px;
    color: #506385;
    font-family: 'LATO';
}

.profile_dec_container > div{
    margin-bottom: 30px;
}

.profile_dec_container > div > div{
    font-size: 18px;
    line-height: 40px;
    font-family: 'LATO';
}