.adm-prod-con {
  width: 100%;
  height: 100%;
  position: relative;
}

.adm-prod-wrapper {
  padding: 0px 20px;
  position: relative;
}

.adm-prod-con .adm-prod-wrapper .products-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  /* z-index: 111; */
  padding: 1.5rem 0;
  top: 5rem;
  background: var(--white-color);
}

/* .adm-prod-con .adm-prod-wrapper .products .products-table {
    margin: 4rem auto;
} */

.product-operations {
  opacity: 0;
  display: flex;
}

.product {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px auto;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;
  cursor: pointer;
}

.product:hover {
  background: #f1f1f1;
}

.product:hover .product-operations {
  opacity: 1;
  margin-right: -5px;
}

.product .product-img {
  display: flex;
  width: 200px;
  height: 100%;
  margin: 0 auto;
  background-size: cover;
  border-radius: 2px;
}

/* .product img {
    display: block;
    margin: 0 auto;
    width: auto;
    height: 200px;
    max-width: calc(100% - 20px);
    background-cover: fit;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.8);
    border-radius: 2px;
} */

.product-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 3;
}

.product-content .product-details-row {
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
}

.product-content .product-details-row .col {
  flex: 1 1;
}

.product-content .product-details-row .col:last-child {
  margin-top: 1rem;
}

.product-content .product-hr {
  width: 50px;
  border-width: 3px;
  border-style: solid;
  margin: 8px 0 14px 0;
  border-color: var(--primary-color);
}

.product h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0 0 0;
}

.product h3 small {
  display: block;
  font-weight: 400;
  font-style: italic;
  margin: 7px 0 0 0;
}

.product .product-text {
  margin: 7px 0 0 0;
  color: #777;
  text-align: left;
}

.adm-prod-wrapper .pagination {
  margin-left: 0;
}
.orders-admin .product .product-img img {
  width: 150px;
  height: 150px;
  max-height: 150px;
  border-radius: 5px;
}

.orders_search {
  margin-top: 0 !important;
}

@media screen and (max-width: 1050px) {
  .adm-prod-con .adm-prod-wrapper .products-header {
    position: static;
    padding-top: 0;
  }
}

@media screen and (max-width: 700px) {
  .orders_search {
    margin-top: 2rem !important;
  }
  .products-header {
    padding: 0;
    flex-direction: column;
  }
  .adm-prod-con .adm-prod-wrapper .products-header {
    padding-top: 30px;
  }
  .products-header .input_container .search_closet {
    width: 100% !important;
  }
  .products-header .input_container .search_closet div {
    width: 100% !important;
    margin: auto;
  }
  .products-header .input_container_wrapper {
    margin: auto !important;
    width: 100%;
  }
  .product-content .product-details-row .col:last-child {
    display: none;
  }
  .product .product-img {
    width: auto;
    margin-right: 1rem;
  }
  /* .orders-admin .product .product-img img {
    width: 100px !important;
    height: 100px !important;
    max-height: 130px !important;
  } */

  .product h3 {
    font-size: 16px;
  }
  .product .product-content .product-details-row em {
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .adm-prod-wrapper {
    padding: 0;
  }
  .product {
    width: 100%;
    margin: 40px auto;
  }
  .product h3 {
    font-size: 14px;
  }
  .product .product-content .product-details-row em {
    font-size: 12px;
  }
  .product .product-operations {
    font-size: 12px;
  }
  .product .product-operations .btn {
    margin-bottom: 0.2rem;
    border-radius: 2px;
  }
}
