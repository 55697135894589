.static-cms-wrapper {
    padding: 2rem;
    min-height: 100vh;
}

.static-cms-wrapper .footer-section {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    padding: 1rem 0;

}

.static-cms-wrapper .footer-section .row {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.static-cms-wrapper .footer-section .row div {
    display: flex;
    flex-direction: column;
}