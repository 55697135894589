.feature_container{
    background-color: #F1F0FF;
    margin:40px 0px;
    padding:20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.feature_container .feature_text_container{
    margin-left: 10px;
    width: 40%;
}

.feature_container .feature_img_container img{
    width: 350px;
}

.feature_container .feature_text_container h1{
    color:#151875;
    font-family: 'Josefin Sans', sans-serif;
}

.feature_container .feature_text_container ul li{
    font-family: "LATO";
    color: #ACABC3;
    padding:5px 0px;
}

.feature_container .feature_text_container ul {
    list-style: none; /* Remove default bullets */
}
  
.feature_container .feature_text_container  ul li::before {
    content: "\2022"; 
    color: red; 
    font-weight: bold; 
    display: inline-block; 
    width: 1em; 
    margin-left: -1em;
}

.feature_container .feature_text_container  ul li:nth-child(2):before {
    color: green; 
}

.feature_container .feature_text_container  ul li:last-child:before {
    color: blue; 
}

.feature_container .feature_text_container > div{
    display: flex;
    align-items: center;
}

.feature_container .feature_text_container > div div{
    font-family: "LATO";
    color:#151875;
    margin-left: 10px;
    font-size: 14px;
}

@media screen and (max-width: 750px) {
    .feature_container .feature_img_container img{
        width: 200px;
    }
    .feature_container .feature_text_container h1{
        font-size: 22px;
    }
}

@media screen and (max-width: 550px) {
    .feature_container{
        flex-direction: column;
    }
    .feature_container .feature_text_container{
        width: 100%;
        padding:0px 5px;
    }
}

@media screen and (max-width: 640px){
    .splide__pagination pagination_container{
        display: none;
    }
}