.profile_container {
  width: 100%;
  margin: auto;
}

.profile_comp_container {
  margin-top: 40px;
  display: flex;
  justify-content: start;
  gap: 3rem;
}

.profile_comp_container .profile_img_container img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-fit: cover;
}

.profile_comp_container a {
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  align-self: baseline;
}

.acc-quick-card {
  width: 100%;
  border-radius: 10px;
  background-color: #f7e4e1;
  position: relative;
  padding: 2rem;
}

.acc-quick-card .row-1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}

.acc-quick-card .row-1 .row-1-1 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.acc-quick-card .row-1 .col-2 {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.acc-quick-card .acc-img {
  /* position: relative; */
  top: -4rem;
  left: 2rem;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #f1f1f1;
}

.acc-quick-card .acc-img img,
.acc-quick-card .acc-img svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.acc-quick-info {
  display: flex;
  /* margin-top: 2rem; */
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.acc-quick-info .stats {
  /* background: var(--background-color); */
  padding: 1rem;
  border-radius: 10px;
  margin: 0 0.5rem;
  min-width: 200px;
  display: flex;
  color: var(--background-color);
  align-items: center;
  justify-content: space-between;
}
.acc-quick-info .stats div {
  margin: 0 0.5rem;
}
.acc-quick-info .stats .icon {
  width: 50px;
  height: 50px;
  margin-right: 1rem;
}
.acc-quick-info .stats h2 {
  margin-bottom: 0;
}
.acc-quick-info .ver-divider {
  height: 130px;
  width: 2px;
  border-radius: 10px;
  /* margin: 0 1rem; */
  border: 2px solid var(--background-color);
}
.acc-quick-card .personal-info-stat {
  margin: 1.25rem 0;
  padding: 0 1rem;
  text-overflow: unset;
}
.acc-quick-card .personal-info-stat b {
  color: var(--background-color);
}

.acc-quick-card .personal-info,
.acc-quick-card .closet-info {
  /* width: 50%; */
  margin: auto;
  background: #fff;
  border-radius: 10px;
  padding: 1rem;
  min-height: 385px;
  /* max-width: 350px; */
  margin: 0 1rem;
  flex: 1;
}

.edit-profile-btn {
  margin-top: 2rem;
}

@media screen and (max-width: 1400px) {
  .acc-quick-info .ver-divider {
    display: none;
  }
}

@media screen and (max-width: 1208px) {
  .acc-quick-card .personal-info,
  .acc-quick-card .closet-info {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .acc-quick-card .row-1-1 {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 750px) {
  .profile_comp_container {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  .profile_comp_container a {
    align-self: flex-end;
  }
}

@media screen and (max-width: 576px) {
  .acc-quick-card {
    padding: 1rem;
  }

  .acc-quick-card .personal-info,
  .acc-quick-card .closet-info {
    margin: 0;
    width: 80%;
  }
  .acc-quick-card .personal-info {
    margin-bottom: 1rem;
  }

  .acc-quick-card .row-1 {
    flex-direction: column;
  }
  .acc-quick-card .row-1 .row-1-1 {
    justify-content: center !important;
  }
  .acc-quick-card .row-1 .col-2 {
    margin-left: 0;
    text-align: center;
  }
  .row-1-1 {
    justify-content: center !important;
  }
  .edit-profile-btn {
    width: 100%;
    margin-right: 0 !important;
    text-align: center;
    margin-top: 0;
  }
}

@media screen and (max-width: 500px) {
  .profile_comp_container .profile_img_container img {
    width: 200px;
    height: 200px;
  }
}
