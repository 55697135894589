.product_section{
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
}

.product-con-wrapper {
    position: relative;
    margin: 30px 40px 0px 40px;
}

.product_section .product_image_gallery{
    width: 50%;
    max-width: 500px;

    /* height: 500px; */
    margin-top: 1rem;
    /* margin-right: 30px; */
}

.product_section .product_image_gallery .iiz {
    border-radius: 5px;
}


.product_section .product_image_gallery .iiz div {
    /* max-width:80%; */
    /* max-height: 500px; */
    /* min-width: 500px; */
    /* max-width: 500px; */
    /* max-height: 500px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 500px;
    height: 500px;
}
.product_section .product_image_gallery .iiz div img {
    max-width: 100% !important;
    max-height: 100% !important;
}

.slick-slider {
    text-align: center;
}
.product_section .product_stats{
    width: 100%;
    margin-top: 1rem;
    margin-left: 2.5rem;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus{
    border-color: #F26654 !important;
}
.slick-next, .slick-prev {
    background: #F26654 !important;
    border-radius: 50%;
    padding: .5rem;
}
  
.slick-arrow {
    width: 50px;
    height: 50px;
    z-index: 1;
}
  
.slick-arrow:before {
    font-size: 50px;
}


.members-only-tag {
    background: linear-gradient(270deg, #f26654 5.25%, #f26654 96.68%);
    display: inline-block;
    padding: 10px 40px 10px 20px;
    color: white;
    position: relative;
    margin-left: -15px;
    border-radius: 3px 0 0 3px;
    margin: 1rem 5px;
  }
  .members-only-tag:after {
    content: "";
    width: 0;
    height: 0;
    top: 0;
    position: absolute;
    right: 0;
    border-right: 20px solid #fff;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
  /* .members-only-tag:before {
    height: 0;
    width: 0;
    border-top: 15px solid #e9a79e;
    border-left: 15px solid transparent;
    bottom: -15px;
    position: absolute;
    content: "";
    left: 0;
  } */
  
@media screen and (max-width: 850px) {
    .product_section{
        flex-direction: column;
    }  
    .product_section .product_image_gallery{
        width:80%;
        margin:auto;
        margin-bottom: 20px;
    }
    .product_section .product_stats{
        width: 100%;
    }
}

@media screen and (max-width: 640px){
    .product_section .product_stats{
        margin: 1rem 0rem ;
    }
    .product_section .product_image_gallery {
        width: 100% !important;
        padding: 1rem;
    }

    .product-con-wrapper {
        margin: 40px 10px;
        padding: 1rem;
    }
    .product_section .product_image_gallery .iiz div {
        width: 100%;
    }

    
}