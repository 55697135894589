.admin-menu-container {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* height: 100vh; */
  /* overflow-y: scroll; */
  /* padding-right: 5px; */
}

@media screen and (max-width: 700px) {
  .admin-menu-container {
    align-items: center !important;
    margin-top: 0px;
  }
}

.admin-menu-container .title {
  color: var(--background-color) !important;
  font-size: 30px;
  margin-bottom: 20px;
  text-align: center;
}

.admin-menu-container .menu_container {
  margin-top: 10px;
}

#productName {
  margin-top: 0px !important;
}

.admin-menu-container .menu_container div a {
  margin: 25px 0px;
  color: #000;
  position: relative;
  font-size: 18px;
  text-decoration: none;
}

.admin-menu-container .menu_container div a::before {
  content: "";
  height: 2px;
  background: #f26654;
  width: 0%;
  position: absolute;
  bottom: 0%;
  transition: 0.5s ease;
}

.admin-menu-container .menu_container div a:hover::before {
  width: 100%;
}

.admin-menu-container .menu_container div a:hover {
  color: #f26654;
  border-radius: 0px;
}

.admin-menu-container .menu_container div {
  padding: 10px 0px;
}

@media screen and (max-width: 900px) {
  .admin-menu-container .menu_container {
    display: flex;
    flex-wrap: wrap;
  }

  .admin-menu-container .menu_container div {
    margin: 10px 10px 0px 0px;
  }

  .admin-menu-container {
    align-items: flex-start;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.input_container {
  width: 100%;
}

.input_container input,
.input_container select {
  padding: 15px 10px;
  font-size: 15px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  /* box-shadow: 0px 1px 4px rgba(0,0,0,0.1); */
  width: 100%;
}

.input_container select {
  padding-left: 20px;
  padding-right: 20px;
  /* width: 100% !important; */
}

.input_container input:focus,
.input_container select:focus {
  border-color: #f26654;
  border-radius: 3px !important;
}

.css-1ozui5a-control {
  border-radius: 3px !important;
  border: 2px solid var(--grey-color);
}

.css-1ozui5a-control:focus {
  border-radius: 0px !important;
}

.input_container input[type="checkbox"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 1em;
  height: 1em;
  border-radius: 0.1em;
  /* margin-right: 0.5em; */
  border: 0.1em solid var(--primary-color);
  outline: none;
  cursor: pointer;
}

.input_container input:checked {
  background-color: var(--primary-color);
  position: relative;
}

.input_container input:checked::before {
  content: "";
  font-size: 1em;
  color: #fff;
  position: absolute;
  right: 1px;
  top: -5px;
}
