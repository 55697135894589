.my-closet{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.my-closet .shop-left{
    margin:0px 10px;
    width: 25%;
}

.my-closet .shop-right{
    margin-left: 20px;
    margin-right: 10px;
    width: 100%;
}

@media screen and (max-width: 900px) {
    .my-closet{
        flex-direction: column;
    }
    .my-closet .shop-left{
        margin:10px 0px;
        width: 100%;
        margin-bottom: 40px;
    }
    .my-closet .shop-right{
        margin:0px;
    }
}