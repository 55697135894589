.admin-menu-container{
    margin-top:40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-menu-container .title{
    color:#506385;
    font-size:30px;
    margin-bottom: 20px;
    text-align: center;
}

.admin-menu-container .menu_container{
    margin-top: 10px;
}

.admin-menu-container .menu_container div a{
    margin:25px 0px;
    color:#000;
    position: relative;
    font-size:18px;
    text-decoration: none;
}

.admin-menu-container .menu_container div a::before{
    content: "";
    height: 2px;
    background: #F26654;
    width: 0%;
    position: absolute;
    bottom: 0%;
    transition: 0.5s ease;
}

.admin-menu-container .menu_container div a:hover::before{
    width: 100%;
}

.admin-menu-container .menu_container div a:hover{
    color: #F26654
}

.admin-menu-container .menu_container div{
    padding:10px 0px;
}

@media screen and (max-width: 900px) {
    .admin-menu-container .menu_container{
        display: flex;
        flex-wrap: wrap;
    }
    
    .admin-menu-container .menu_container div{
        margin:10px 10px 0px 0px;
    }

    .admin-menu-container{
        align-items:flex-start;
        margin-left:10px;
        margin-right: 10px;
    }
}