.small_header{
    display:none;
    height:60px;
    border-bottom: 1px solid #F26654;
    width:100%;
    margin-top:10px;
}

.small_header .menu_container{
    display:flex;
    align-items: center;
    justify-content: space-between;
}

.small_header .left_container{
    display: flex;
    align-items: center;
}

.small_header .left_container span{
    font-size:25px;
    color:#F26654;
    cursor: pointer;
}

.small_header .left_container img{
    margin-left: 15px;
}

.small_icon_container{
    padding-left: 25px;
    padding-right: 25px;
    text-align: center;
}

.sidebar{
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 10px;
}

.sidebar a {
    padding: 8px 8px 12px 32px;
    text-decoration: none;
    font-size: 16px;
    color:#ABABAB;
    display: block;
    transition: 0.3s;
}
  
.sidebar a:hover {
    color: #F26654;
}

.sidebar .searchbar_container{
    display: flex;
    position: absolute;
    padding: 8px 8px 12px 32px;
}

.sidebar .searchbar_container .input_search{
    border: none;
    width: auto;
    border-bottom: 1px solid #fff;
    background: transparent;
    color: #fff;
}

.sidebar .searchbar_container .input_search:focus{
    border-bottom-color: #F26654;
}

.sidebar .searchbar_container button{
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    transition: 0.5s ease;
    color: #fff;
}

.sidebar .searchbar_container button:hover{
    color:#F26654;
}

.sidebar .searchbar_container button i{
    position: absolute;
    right: 8%;
}

.sidebar .closebtn {
    font-size: 36px;
    height: 60px;
    margin: 0;
    text-align: right;
    margin-right: 2rem;
    color: #fff;
    cursor: pointer;
}

@media screen and (max-width: 750px) {
    .small_header{
        display:block;
    }
}