.checkout-container {
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkout-heading {
  color: #f26654;
  margin-bottom: 20px;
  text-align: left !important;
  border-bottom: 2px solid var(--grey-color);
}

.product-list {
  width: 100%;
}

.product-item {
  margin: 10px;
  text-align: center;
}

.product-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: 2px solid #506385;
}

.product-name {
  color: #506385;
  margin-top: 10px;
}

.product-description {
  margin: 10px 0;
}

.product-info {
  margin-bottom: 5px;
  color: #506385;
}

.checkout-container-2 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 70vh;
}

.chkout-col-35 {
  flex: 0 0 35%;
  padding: 0.5rem;
  color: var(--background-color);
}

.chkout-col-65 {
  flex: 1;
  padding: 0.5rem;
  color: var(--background-color);
}

.chkout-col-35 h3,
.chkout-col-65 h3 {
  color: var(--background-color);
  text-align: left;
}

.chkout-col-25 .content-con,
.chkout-col-50 .content-con {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* color: var(--background-color); */
  padding: 0.5rem;
  color: #212121;
}

.chkout-col-25 .content-con p,
.chkout-col-50 .content-con p {
  margin: 0.5rem 0;
}

.product-details-row-2 {
  margin-top: 1rem !important;
  padding-bottom: 1rem;
}

.checkout-details-product-image {
  /* padding: 0.5rem;
  border-radius: 5px; */
  min-height: 175px !important;
}

@media screen and (max-width: 700px) {
  .checkout-details-product {
    flex-wrap: wrap;
  }
  .chkout-col-35 {
    flex: 100%;
  }
  .product-content.checkout-details-product-content
    .product-details-row
    .col:last-child {
    display: flex;
    flex-wrap: wrap;
  }
  .checkout-details-product-image img {
    width: 100% !important;
    height: 100%;
    max-height: 330px !important;
    margin: 0 !important;
    margin-right: 0 !important;
  }

  .product .product-img.checkout-details-product-image {
    margin: 0;
  }
}
