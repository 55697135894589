.small_header {
  display: none;
  height: auto;
  /* border-bottom: 1px solid #F26654; */
  width: 100%;
  margin-top: 0px !important;
  /* margin-bottom:0px; */
}

.small_header .menu_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0.5rem;
}

.small_header .left_container {
  display: flex;
  align-items: center;
  text-align: center;
  width: 55px;
  justify-content: center;
}

.small_header .left_container span {
  color: #f26654;
  cursor: pointer;
  margin-top: 5px;
}

.small_header .left_container img {
  margin-left: 15px;
}

.small_icon_container {
  width: 100%;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  color: #fff !important;
  background-color: #f26654;
  /* margin: auto !important; */
  margin-top: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.small_icon_container i {
  color: #fff !important;
}

.badge {
  color: #f26654;
}

.profile_icon_container {
  /* padding-left: 5px; */
  /* padding-right: 5px; */
  text-align: center;
  /* margin-bottom: -15px; */
  /* position: relative; */
  /* padding: 1rem; */
  display: flex;
  align-items: center;
  width: 55px;
  justify-content: center;
  padding-right: 0;
}

.profile_icon_container .dropdown {
  display: none;
  text-align: center;
  position: absolute;
  background-color: #fff;
  margin: auto;
  top: 100%;
  right: 30%;
  border-radius: 8px;
  z-index: 50;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
}

.profile_icon_container .dropdown .dropdown_menu {
  padding: 10px 25px;
  border-bottom: 1px solid #f26654;
  transition: 0.5s ease;
}

.profile_icon_container .dropdown .dropdown_menu:hover {
  background-color: #f26654;
  cursor: pointer;
  color: #fff;
}

.small_header .dropdown_icon:hover .dropdown {
  display: block;
}

.small_header .dropdown_icon .user_logo_header {
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  object-fit: cover;
  width: 28px;
}

.profile_icon_container i {
  font-size: 25px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
}

.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 5100;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
}

.sidebar a {
  padding: 8px 8px 12px 32px;
  text-decoration: none;
  font-size: 16px;
  color: #ABABAB;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f26654;
}

.sidebar .searchbar_container {
  display: flex;
  position: absolute;
  padding: 8px 8px 12px 32px;
}

.sidebar .searchbar_container .input_search {
  border: none;
  width: auto;
  border-bottom: 1px solid #fff;
  background: transparent;
  color: #fff;
}

.sidebar .searchbar_container .input_search:focus {
  border-bottom-color: #f26654;
}

.sidebar .searchbar_container button {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.5s ease;
  color: #fff;
}

.sidebar .searchbar_container button:hover {
  color: #f26654;
}

.sidebar .searchbar_container button i {
  position: absolute;
  right: 8%;
}

.sidebar .closebtn {
  font-size: 34px;
  height: 60px;
  margin: 0;
  text-align: right;
  margin-right: 2rem;
}

@media screen and (max-width: 750px) {
  .small_header {
    display: block;
  }

  .badge {
    color: #fff;
  }
}
