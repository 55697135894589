.news_letter_container{
    /* margin:40px 0px; */
    background-color: var(--background-color);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.news_letter_container h1{
    color:var(--white-color);
    font-size: 20px;
}

.news_letter_container .refer_container,.news_letter_container .subscribe_container{
    max-width:300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:40px 0px;
}

.news_letter_container .desc{
    color:var(--white-color);
    margin-bottom: 10px;
    font-size:13px;
}

.news_letter_container .searchbar_container {
    width: 100%;
}

.news_letter_container .searchbar_container .input_search{
    width:75%;
}

.news_letter_container .searchbar_container button{
    vertical-align: bottom;
}

@media screen and (max-width: 700px) {
    .news_letter_container{
        flex-direction: column;
    }
    .news_letter_container .refer_container,.news_letter_container .subscribe_container{
        width:80%;
        padding:20px 0px;
        text-align:center;
    }
}