.image-container {
  overflow: hidden;
  border: 1px solid #000;
  margin: 0 auto;
  max-width: auto;
  cursor: move;
  user-select: none; /* Prevent text selection during drag */
  position: relative;
}

.resize-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #007bff;
  cursor: pointer;
  user-select: none;
}

.top-left {
  top: 0;
  left: 0;
}

.top-right {
  top: 0;
  right: 0;
}

.bottom-left {
  bottom: 0;
  left: 0;
}

.bottom-right {
  bottom: 0;
  right: 0;
}
