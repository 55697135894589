.purchased-page-con {
    position: "relative";
     margin: 40px 60px 0px 60px;
     max-width: 1560px;
}

@media screen and (min-width: 1860px){
    .purchased-page-con {
        margin: 2rem auto;
    }
}