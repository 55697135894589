.product_closet_container{
    margin-top:30px;
    background-color: #FFF9F9;
    display: flex;
    box-shadow: 0px 1px 4px rgba(0,0,0,0.25);
    border: 1px solid #B6B6B6;
    padding: 15px;
    align-items: center;
}

.product_closet_container .closet_icon .icon_container i{
    font-size:38px !important;
}

.product_closet_container .closet_info{
    display: flex;
    width: 100%;
    flex-direction: column;
}

.product_closet_container .closet_info .closet_meta,.product_closet_container .closet_info .closet_stats{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.product_closet_container .closet_info .closet_meta .left,.product_closet_container .closet_info .closet_stats .left{
    display: flex;
}

.product_closet_container .closet_info .closet_meta{
    margin-bottom: 15px;
}

.product_closet_container .closet_info .closet_meta div{
    font-weight: bold;
    font-size: 16px;
}

.product_closet_container .closet_info .closet_stats div{
    font-size: 14px;
}

.product_closet_container .closet_info .closet_stats .closet_rating{
    display: flex;
}

.product_closet_container .closet_info .closet_stats .closet_rating .rating_container{
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
}

.product_closet_container .closet_info .closet_stats .closet_rating .rating_container .icon-container i{
    color:#F26654;
    display:inline-block;
    font-size:18px;
    margin:0px 2px;
}

.product_closet_container .closet_info .closet_meta .left .closet_author,.product_closet_container .closet_info .closet_stats .left .closet_reviews{
    margin-left: 15px;
}

@media screen and (max-width: 900px) {
    .product_closet_container .closet_info{
        flex-direction: row;
    }

    .product_closet_container .closet_info .closet_meta,.product_closet_container .closet_info .closet_stats{
        flex-direction: column;
        justify-content: flex-start;
    }

    .product_closet_container .closet_info .closet_meta .left,.product_closet_container .closet_info .closet_stats .left{
        flex-direction: column;
        margin-bottom: 10px;
    }

    .product_closet_container .closet_info .closet_meta .left .closet_author,.product_closet_container .closet_info .closet_stats .left .closet_reviews{
        margin-left: 0px;
    }

    .product_closet_container .closet_info .closet_meta .left > div,.product_closet_container .closet_info .closet_stats .left > div{
        margin-bottom: 10px;
    }

    .product_closet_container .closet_info .closet_meta{
        margin:0px;
        margin-right: 20px;
    }
}

@media screen and (max-width: 600px) {
    .product_closet_container .closet_info{
        flex-direction: column;
        margin-top: 10px;
    }

    .product_closet_container{
        flex-direction: column;
    }

    .product_closet_container .closet_info .closet_meta, .product_closet_container .closet_info .closet_stats{
        margin: auto;
        margin-bottom: 20px;
    }

    .product_closet_container .closet_info .closet_meta div, .product_closet_container .closet_info .closet_stats div{
        text-align: center;
    }

    .product_closet_container .closet_info .closet_stats .closet_rating{
        justify-content: center;
    }

    .product_closet_container .closet_info .closet_meta .left, .product_closet_container .closet_info .closet_stats .left{
        margin-bottom: 0px;
    }
}

