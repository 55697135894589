/* table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  .order-row:hover {
    cursor: pointer;
    background-color: #f9f9f9;
  }
  
  .product-row {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
  .product-row table {
    margin: 0;
    width: 100%;
  }
  
  .product-row th,
  .product-row td {
    border: none;
    padding: 0;
  }
  
  .product-row th {
    background-color: transparent;
  }
  
  .product-row th:first-child,
  .product-row td:first-child {
    padding-left: 8px;
  }
  
  .product-row th:last-child,
  .product-row td:last-child {
    padding-right: 8px;
  }

  .table-accordion .collapsed {
    display: block !important;
  }

  .table-accordion .no-collapsed {
    display: none  !important;
  }
   */

/* Add this CSS to your OrderTableAccordion.css or a separate CSS file */

/* Style for mobile screens */
/* Add this CSS to your OrderTableAccordion.css or a separate CSS file */

/* Style for mobile screens */
/* @media screen and (max-width: 767px) {
  .order-table-accordion table {
    width: 100%;
  }

  .order-row,
  .product-row {
    display: block;
  }

  .product-row.collapsed {
    display: none;
  }

  .order-row td,
  .product-row td {
    width: 100%;
    display: block;
    text-align: left;
    margin: 0;
    padding: 8px 0;
    border: none;
  }

  .order-row td:nth-child(4) {
    text-align: left;
  }

  .dimensions {
    text-align: left;
    padding-left: 8px;
  }
} */

/* @media screen and (max-width: 767px) {
  .my-closet-right.orders {
    overflow: auto;
  }

  .order-row,
  .product-row {
    display: block;
  }

  .product-row.collapsed {
    display: none;
  }

  .order-row td,
  .product-row td {
    width: 100%;
    display: block;
    text-align: left;
    margin: 0;
    padding: 8px 0;
    border: none;
  }

  .order-row td:nth-child(4) {
    text-align: left;
  }

  .dimensions {
    text-align: left;
    padding-left: 8px;
  }
} */

/* ///////////////////// */
.responsive-table {
  width: 100%;
  margin-bottom: 1.5em;
  border-spacing: 0;
}
@media (min-width: 48em) {
  .responsive-table {
    font-size: 0.9em;
  }
}
@media (min-width: 62em) {
  .responsive-table {
    font-size: 1em;
  }
}
.responsive-table thead {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
@media (min-width: 48em) {
  .responsive-table thead {
    position: relative;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto;
  }
}
.responsive-table thead th {
  /* background-color:var(--primary-color);  */
  /* border: 1px solid var(--peel-orange-color); */
  font-weight: 700;
  text-align: center;
  color: var(--background-color);
}
.responsive-table thead th:first-of-type {
  /* text-align:  ; */
}
.responsive-table tbody,
.responsive-table tr,
.responsive-table th,
.responsive-table td {
  display: block;
  padding: 0;
  text-align: left;
  white-space: normal;
}
@media (min-width: 48em) {
  .responsive-table tr {
    display: table-row;
  }
}
.responsive-table th,
.responsive-table td {
  padding: 0.5em;
  vertical-align: middle;
}
@media (min-width: 30em) {
  .responsive-table th,
  .responsive-table td {
    padding: 0.75em 0.5em;
  }
}
@media (min-width: 48em) {
  .responsive-table th,
  .responsive-table td {
    display: table-cell;
    padding: 0.5em;
  }
}
@media (min-width: 62em) {
  .responsive-table th,
  .responsive-table td {
    padding: 0.75em 0.5em;
  }
}
@media (min-width: 75em) {
  .responsive-table th,
  .responsive-table td {
    padding: 0.75em;
  }
}
.responsive-table caption {
  margin-bottom: 1em;
  font-size: 1em;
  font-weight: bold;
  text-align: left;
}
@media (min-width: 48em) {
  .responsive-table caption {
    font-size: 1.5em;
  }
}
.responsive-table tfoot {
  font-size: 0.8em;
  font-style: italic;
}
@media (min-width: 62em) {
  .responsive-table tfoot {
    font-size: 0.9em;
  }
}
@media (min-width: 48em) {
  .responsive-table tbody {
    display: table-row-group;
  }
}
.responsive-table tbody tr {
  margin-bottom: 1em;
}
@media (min-width: 48em) {
  .responsive-table tbody tr {
    display: table-row;
    border-width: 1px;
  }
}
.responsive-table tbody tr:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 48em) {
  .responsive-table tbody tr:nth-of-type(even) {
    background-color: transparent;
  }
}
.responsive-table tbody th[scope="row"] {
  /* background-color: #26890d; */
  color: black;
}
@media (min-width: 30em) {
  .responsive-table tbody th[scope="row"] {
    /* border-left: 1px solid #86bc25; */
    /* border-bottom: 1px solid #86bc25; */
  }
}
@media (min-width: 48em) {
  .responsive-table tbody th[scope="row"] {
    background-color: transparent;
    color: #000001;
    text-align: left;
  }
}
.responsive-table tbody td {
  text-align: right;
}
@media (min-width: 48em) {
  .responsive-table tbody td {
    /* border-left: 1px solid #86bc25; */
    /* border-bottom: 1px solid #86bc25; */
    text-align: center;
  }
}
@media (min-width: 48em) {
  .responsive-table tbody td:last-of-type {
    /* border-right: 1px solid #86bc25; */
  }
}
.responsive-table tbody td[data-type="currency"] {
  text-align: right;
}
.responsive-table tbody td[data-title]:before {
  content: attr(data-title);
  float: left;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.54);
}
@media (min-width: 30em) {
  .responsive-table tbody td[data-title]:before {
    font-size: 0.9em;
  }
}
@media (min-width: 48em) {
  .responsive-table tbody td[data-title]:before {
    content: none;
  }
}

/* Add this CSS to your OrderTableAccordion.css or a separate CSS file */

/* Style for mobile screens */
/* Add this CSS to your OrderTableAccordion.css or a separate CSS file */

/* Style for mobile screens */
@media screen and (max-width: 767px) {
  .responsive-table {
    font-size: 0.8em; /* Adjust font size */
  }

  .responsive-table th,
  .responsive-table td {
    padding: 0.25em; /* Reduce padding */
  }

  .responsive-table caption {
    font-size: 0.9em; /* Adjust caption font size */
  }

  .responsive-table tbody {
    width: 100%; /* Constrain tbody to 100% width */
    display: block;
    overflow-x: auto;
  }

  .responsive-table tbody tr {
    margin-bottom: 1em; /* Reduce margin between rows */
  }

  .responsive-table tbody td[data-title]:before {
    font-size: 0.6em; /* Reduce data-title label font size */
  }

  .table-img {
    border: none;
    padding: 0 !important;
  }
  .table-img img {
    height: 101px;
  }
}

@media screen and (max-width: 455px) {
  .responsive-table th,
  .responsive-table td {
    max-width: 350px;
  }
}
