.tabbed-container {
  display: flex;
  flex-direction: column;
  margin: 2rem;
}

.tab-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
}

.tab-list li {
  flex: 1;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background-color: #f2f2f2;
  border-bottom: none;
  margin: 0rem 2rem;
  border-radius: 5px;
}

.tab-list li:hover {
  background-color: #ddd;
}

.tab-list li.active {
  background-color: var(--background-color);
  border-color: #ddd;
  transform: none;
}

.tab-content {
  padding: 10px;
  background-color: #fff;
}

@media screen and (max-width: 640px) {
  .tabbed-container {
    margin: 1.5rem 0rem;
  }
  .tab-list li {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    margin: 0.2rem;
    font-size: 14px;
  }
}
