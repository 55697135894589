.analytics-con {
    min-height: 100vh;
    width: 100%;
  }
  
  .analytics-wrapper {
    padding: 1rem;
    margin: 0.5rem;
  }
  
  .analytics-table-con {
    height: calc(100vh - 150px);
    position: relative;
    padding: 0 !important;
  }
  
  .analytics-table-con.users {
    margin: 20px 0;
    padding: -1;
  }
  
  .analytics-table-con table thead tr th.sticky {
    position: sticky;
    top: 0;
    background-color: #f2f2f2;
    z-index: 2;
    text-transform: capitalize;
  }
  
  .analytics-table-con table tbody {
    z-index: 0;
  }
  
  /* AG CUSTOM THEMING */
  .ag-theme-quartz {
    /* --ag-header-height: 30px; */
    --ag-header-foreground-color: var(--background-color) !important;
    /* --ag-header-background-color: black;
    --ag-header-cell-hover-background-color: rgb(80, 40, 140);
    --ag-header-cell-moving-background-color: rgb(80, 40, 140); */
  }
  
  .analytics-con .pagination {
    margin-left: 0 !important;
  }
  