.header1{
    height:60px;
    /* border-bottom: 1px solid #F26654; */
    width:100%;
    margin-top:17px;
}

.header1 .searchbar_container{
    margin-left: 40px;
}

.header1 .user_logo_header{
    width:28px;
    height: 28px;
    border-radius: 50%;
    cursor:pointer;
    object-fit: cover;
}



.profile_menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    min-width: 66px;
    /* min-height: 50px; */
    align-items: center
}

.profile_menu .dropdown{
    width: 130px;
    display: none;
    text-align: center;
    position: absolute;
    background-color: #fff;
    margin: auto;
    top: 100%;
    right:30%;
    border-radius: 10px;
    z-index: 50;
    box-shadow: 0px 1px 10px rgba(0,0,0,0.15);
}

.profile_menu .dropdown .dropdown_menu{
    padding: 10px 25px;
    border-bottom: 1px solid #F26654;
    transition: 0.5s ease;
    border-radius: 5px;
}

.profile_menu .dropdown .dropdown_menu:hover{
    background-color: #F26654;
    cursor: pointer;
    color:#fff;
    /* border-radius: 10px; */
}

.header1 .dropdown_icon:hover .dropdown{
    display:block;
    /* border-radius: 10px; */
}

.notif-item {
    display: flex;
    justify-content: space-between;
}

.notif-item .notif-dot {
    background: var(--primary-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    color: var(--white-color);
}

.header-icon-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    color: #333333;
    background: transparent;
    border: none;
    outline: none;
    border-radius: 50%;
  }

  .header-icon-button-small {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    color: var(--white-color);
    background: transparent;
    border: none;
    outline: none;
    border-radius: 50%;
  }
  
  .header-icon-button:active, .header-icon-button-small:active {
    background: #f1f1f1;
  }
  
  .icon-button__badge {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 25px;
    height: 25px;
    background: var(--peel-orange-color);
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 10px;
  }

  .icon_con_2 {
    padding: 0 8px;
  }
  

.ringing-bell{
    animation: ringingBell 1s infinite;
}
@keyframes ringingBell{
    0%		{ transform: rotate(30deg); }
    50%		{ transform: rotate(-30deg); }
    100%	    { transform: rotate(30deg); }
}

@media screen and (max-width: 750px) {
    .header1{
        /* margin-left: -40px;
        margin-right: -40px; */
        display:none;
    }
    .icon_con_2 {
        padding: 0 ;
    }

    .icon_container {
      margin: 8px !important;
    }

}