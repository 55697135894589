.ce-my-message-bubble {
  background-color: var(--primary-color) !important;
}

.ce-chat-title-text {
  color: var(--primary-color) !important;
  text-align: center;
  background: var(--white-color) !important;
  padding: 1rem;
  word-wrap: break-word;
  border-bottom: 2px solid var(--peel-orange-color);
  border-radius: 2px 2px 0 0 !important;
  font-family: "Lato", sans-serif !important;
  font-weight: 700 !important;
}

.ce-chat-subtitle-text {
  color: var(--dark-grey-color) !important;
  font-size: 12px !important;
}

#ce-send-message-button {
  position: relative !important;
  bottom: 2px !important;
  left: 1px !important;
  background-color: var(--primary-color) !important;
  padding: 14px 20px !important;
}

.ce-my-message-timestamp {
  color: var(--peel-orange-color) !important;
}

.ql-editor p {
  text-align: left !important;
}

.transition-3 {
  transition: all 0.33s ease;
  -webkit-transition: all 0.33s ease;
  -moz-transition: all 0.33s ease;
}

.transition-5 {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
}

.blink {
  animation: blink 1.5s infinite;
}

.ce-quill-container {
  border-top: 2px solid var(--primary-color) !important;
}

.ql-container {
  border: none !important;
  font-family: inherit !important;
  border-top: 2px solid var(--peel-orange-color);
}

.ql-editor {
  border: 1px solid var(--peel-orange-color);
  border-radius: 10px;
  margin: 0.5rem;
  width: 80%;
}
.ce-send-button-container {
  right: 10px !important;
  bottom: 95px !important;
}

#toolbar {
  border: none !important;
}

#ce-send-message-button:hover {
  padding: 14px 20px !important;
  background: var(--peel-orange-color) !important;
}

#ce-send-message-button span svg {
  transform: rotate(90deg);
}

.ce-chat-engine .ce-wrapper {
  /* min-height: 50vh; */
  font-family: "Lato", sans-serif !important;
  margin-bottom: -60px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.ce-chat-list-column {
  background: #fff7f7 !important;
  /* min-height: 100vh !important; */
  /* border-right: 2px solid var(--primary-color) !important; */
  /* border-radius: 0px 10px 10px 0px; */
  overflow: auto;
}

.ce-chat-form-container {
  background: var(--primary-color) !important;
  font-family: "Lato", sans-serif !important;
  color: var(--white-color) !important;
  min-height: 65px;
  border-bottom: 2px solid var(--primary-color) !important;
  /* margin-bottom: 1rem; */
}

.ce-chat-form-container div button {
  display: none;
}

.ce-chats-container {
  background: #fff7f7 !important;
}

.ce-chat-subtitle {
  margin-top: 0.5rem;
  font-family: "Lato", sans-serif !important;
}

.ce-chat-card .ce-chat-title-text {
  text-align: left;
  padding-left: 0;
}

.ce-chat-title-container {
  padding: 0 !important;
}

.ce-chat-title-container .ce-chat-subtitle-text {
  display: none !important;
  margin-top: 0.2rem;
}

.ce-active-chat-card {
  background-color: #e8e8e8 !important;
  border-radius: 5px !important;
  /* color: var(--white-color) !important; */
  /* border-bottom: 2px solid var(--white-color) !important; */
}

.ce-active-chat-card .ce-chat-title-text {
  background: #e8e8e8 !important;
}

.ce-chat-settings-container {
  font-family: "Lato", sans-serif !important;
  color: var(--dark-grey-color) !important;
  margin-bottom: 1rem !important;
}

.ce-photo-title-container,
#ce-options-drop-down {
  background: var(--primary-color) !important;
  border-radius: 20px !important;
  color: var(--white-color) !important;
  font-family: "Lato", sans-serif !important;
  font-weight: 100 !important;
}

.ce-their-message-sender {
  display: none !important;
}

#ce-options-drop-down {
  background-color: var(--primary-color) !important;
}

#ce-ice-breaker-gif {
  display: none !important;
}

#ce-login-fail-gif {
  display: none;
}

.ce-chat-settings-mobile-option {
  display: none !important;
}

/* .uploader {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
} */

@media screen and (max-width: 640px) {
  .ce-chat-title-text {
    font-size: 16px !important;
    background: #fff !important;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink:hover {
  animation: none;
}
