.my-closet {
  font-family: inherit !important;
  margin-top: 40px;
  display: flex;
  width: 100%;
  padding: 0 30px;
}

.my-closet .my-closet-left {
  margin: 0px 10px;
  width: 20%;
  overflow-y: auto;
  position: sticky;
  top: 3rem;
}

.my-closet .my-closet-right {
  margin-left: 20px;
  margin-right: 10px;
  width: 80%;
  overflow-y: auto;
}

.accordion-header {
  background: var(--peel-orange-color);
  color: var(--white-color);
  border: none !important;
}

.rc-collapse-header {
  background: var(--peel-orange-color) !important;
  color: var(--white-color) !important;
  font-size: 18px;
  font-family: "Lato" !important;
  text-transform: uppercase !important;
  border: none !important;
}

.rc-collapse-expand-icon .arrow {
  /* border-top: #fff !important; */
  /* border-color: #fff !important; */
}

@media screen and (max-width: 1250px) {
  .my-closet .my-closet-left {
    width: 25%;
  }
  .my-closet .my-closet-right {
    width: 75%;
  }
}

@media screen and (max-width: 1020px) {
  .my-closet {
    flex-direction: column;
  }
  .my-closet .my-closet-left {
    margin: 10px 0px;
    width: 100%;
    margin-bottom: 40px;
    position: static;
  }
  .my-closet .my-closet-right {
    margin: 0px;
    width: 100%;
  }
}
