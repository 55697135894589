.chat-container-sep {
  position: relative;
  max-width: 550px;
  margin: auto;
  min-height: 75vh;
  margin-bottom: -60px;
  border-radius: 10px;
  border: 2px solid var(--primary-color);
  padding: 1rem auto;
  background: #fff;
}

.ind-chat-con {
  background: #f1f1f1;
  padding: 1rem;
  margin-bottom: -60px;
  padding-bottom: 5rem;
}

.ce-chat-feed {
  border-radius: 10px;
}

.ce-message-form-container {
  border-radius: 10px;
}

.ce-chat-title-text {
  border-radius: 10px 10px 0 0 !important;
}

.ce-chat-feed-container {
  max-height: 60vh !important;
}

.ce-their-message-row div div {
  padding-left: 4px !important;
}

@media screen and (max-width: 850px) {
  .chat-container-sep {
    width: 100%;
    /* padding: .5rem; */
  }
}

@media screen and (max-width: 450px) {
  #ce-send-message-button {
    padding: 14px !important;
  }
}
