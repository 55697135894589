.social-btn-width {
  width: 200px !important;
  margin-bottom: -20px;
}

.signupform_container {
  /* margin:40px 0px; */
  margin-top: 60px !important;
  padding: 30px;
}

.social_signup,
.email_signup {
  width: 90%;
  margin: auto;
}

.email_signup {
  margin-top: 20px;
}

.social_btn_container {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.input_container {
  margin-top: 8px !important;
  color: var(--background-color);
}

.input_container input {
  margin-top: 5px;
  border-radius: 3px;
}

.email_signup_form .personal_info_container {
  display: flex;
  justify-content: space-between;
}

.email_signup_form .personal_info_container .input_container:nth-child(2) {
  margin-left: 5px;
}

.email_signup_form .input_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}

.google-btn {
  background-color: #4385f5;
}

.google-btn:hover {
  background-color: #2c77f7;
}

.microsoft-btn {
  background-color: #fff;
  color: #000;
}

.social_btn_container div {
  min-width: none;
}

.social_btn_container button {
  padding-right: 10px;
  padding-left: 10px;
}

.social_btn_container button i {
  display: inline-block;
  margin-right: 5px;
}

.microsoft-btn:hover {
  background-color: rgb(249, 243, 243);
}

.facebook-btn {
  background-color: #4367b3;
}

.facebook-btn:hover {
  background-color: #365db1;
}

.email_signup_form .input_container label {
  font-family: "LATO";
  font-size: 14px;
  font-weight: 700;
  display: block;
  margin-bottom: 2px;
}

.email_signup_form .input_container input,
.StripeElement {
  padding: 8px;
  font-size: 14px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  /* box-shadow: 0px 1px 4px rgba(0,0,0,0.25); */
  width: 100%;
  margin-top: 5px;
}

.email_signup_form .input_container input:focus {
  border-color: #f26654;
  border-radius: 3px !important;
}

.email_signup_form button {
  width: 100%;
  margin-top: 20px;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
}

.tc {
  color: #f26654;
}

.tc_input {
  margin: 10px 10px 0px 0px;
  /* color:#F26654; */
}

.social_btn_container div {
  font-size: 16px;
}

@media screen and (max-width: 1050px) {
  .social_btn_container div {
    font-size: 14px;
  }
}

@media screen and (max-width: 780px) {
  .social_btn_container div {
    font-size: 12px;
  }
}
@media screen and (max-width: 750px) {
  .social_btn_container {
    flex-direction: column;
    align-items: center;
  }
  .social_btn_container div {
    min-width: 110px;
  }
  .social_btn_container button {
    margin: 10px 0px;
  }
  .signupform_container {
    margin-top: 120px !important;
    padding: 0px;
  }
}

.loginOptionsContainer {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.loginOptions {
  color: #f26654;
  font-weight: bolder;
}
