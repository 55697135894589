.membership_details {
  font-family: "LATO";
}

.upgrade_membership a {
  margin: 25px 0px;
  font-family: "LATO";
  color: #f26654;
  position: relative;
  /* font-size:18px; */
  text-decoration: none;
}

.upgrade_membership a::before {
  content: "";
  height: 2px;
  background: #f26654;
  width: 0%;
  position: absolute;
  bottom: 0%;
  transition: 0.5s ease;
}

.upgrade_membership a:hover::before {
  width: 100%;
}

.upgrade_membership a:hover {
  color: #f26654;
}

.coupen_container {
  align-items: flex-end;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.coupen_container input {
  padding: 8px;
}

.swal2-html-container ul {
  list-style-type: disc !important;
  padding-left: 20px;
}
.swal2-html-container li {
  margin-left: 10px;
}
.swal2-confirm.swal2-styled {
  background-color: var(--primary-color) !important;
}
