.spinner_container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: #f1f1f1;
  border-radius: 5px;
}

.spinner_container img {
  width: 300px;
  margin: auto;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
